import { RoomConfigEnums, WEBINAR_CONNECTION_STATE } from '@/constants/enums';
import { calculateViewerCount } from '@/helpers/calculate';

export function getLocalUser(state) {
  const user = state.users.find((u) => u.type === 'local');

  if (!user) return false;

  return {
    ...user,
    videoMuted: !user.hasCamera || user.videoMuted,
    isModerator: user.role === 'moderator',
  };
}

export function getUserById(_, getters) {
  return (id) => {
    return getters.getUsers.find((user) => user.id === id);
  };
}

export function getUsers(state) {
  return state.users
    .map((user) => {
      return {
        ...user,
        hasAudioTrack: user.hasMicrophone,
        hasVideoTrack: user.hasCamera,
        isModerator: user.role === 'moderator',
      };
    })
    .slice(0)
    .sort((a, b) => {
      return b.isModerator - a.isModerator;
    });
}

export function getMessagesByType(state) {
  return (type) => {
    return state.messages.filter((message) => message.type === type);
  };
}

export function remoteRecordStartedBy(state) {
  const userId = state.users.find((u) => u.KeycloakId === state.roomConfig[RoomConfigEnums.REMOTE_RECORD_STARTED_BY]);
  return userId?.id || null;
}

export function getIsMultiStaged(_, getters) {
  return getters.getStagedSize > 1;
}

export function getStagedSize(_, getters) {
  return getters.getStagedUsers.length;
}

export function getStagedUsers(state, getters) {
  return (state.roomConfig?.stagedUsers || []).filter((u) => getters.getUserById(u.id));
}

export function getActiveLogoList(state, getters) {
  const logoList = getters.getLocalUser.isModerator ? state.design.logoList : state.roomConfig?.design.logoList;
  return (logoList || []).filter((l) => l.active);
}

export function findStagedUserById(_, getters) {
  return (id) => {
    return getters.getStagedUsers.find((su) => su.id === id);
  };
}

export function getStagedUserIds(_, getters) {
  return getters.getStagedUsers.map((u) => u.id);
}

export function getgetIsScreenUserExists(_, getters) {
  return getters.getStagedUsers.some((u) => u.isScreen === 'true');
}

export function getLayout(state) {
  return state.roomConfig.layout || {};
}

export function getLayoutName(_, getters) {
  return getters?.getLayout.name;
}

export function getUserIds(_, getters) {
  return getters.getUsers.map((user) => user.id);
}

export function getRemoteUsers(_, getters) {
  return getters.getUsers.filter((u) => u.type !== 'local');
}

export function getRemoteUserIds(_, getters) {
  return getters.getRemoteUsers.map((u) => u.id);
}

export function isWebinarRequestPending(state) {
  return state.connectionState === WEBINAR_CONNECTION_STATE.PENDING;
}

export function isWebinarStarted(state) {
  return state.connectionState === WEBINAR_CONNECTION_STATE.WATCHING;
}

export function isWebinarEnded(state) {
  return state.connectionState === WEBINAR_CONNECTION_STATE.ENDED;
}

export function findStreamMessageByKeys(state) {
  return (message, openAuthId, messageFrom) => {
    return state.streamMessages.find((streamMessage) => {
      return streamMessage.body === message && streamMessage?.messageFrom === messageFrom && !streamMessage?.openAuthId.includes(openAuthId);
    });
  };
}

export function getStreamMessageByUuid(state) {
  return (uuid) => {
    return state.streamMessages.find((streamMessage) => {
      return streamMessage.uuid === uuid && streamMessage?.statusType !== 'error';
    });
  };
}

export function getStreamErrorMessageByUuid(state) {
  return (uuid) => {
    return state.streamMessages.find((streamMessage) => {
      return streamMessage.uuid === uuid && streamMessage?.statusType === 'error';
    });
  };
}

export function getCurrentBroadcastTitle(state) {
  const [firstSession] = state.currentSession?.items || [];
  return firstSession?.title || state.currentSession?.sessionName;
}

export function getViewerCount(state) {
  const viewerObj = {};
  let viewers = '';
  let total = 0;
  state.viewers.forEach((viewer) => {
    if (!viewerObj[viewer.providerInfo]) {
      viewerObj[viewer.providerInfo] = viewer.viewerCount;
    } else {
      viewerObj[viewer.providerInfo] += viewer.viewerCount;
    }
  });
  Object.keys(viewerObj).forEach((viewer, index) => {
    total += viewerObj[viewer];
    if (index === 0) {
      viewers += `(${calculateViewerCount(viewerObj[viewer])} ${viewer}`;
    } else {
      viewers += `, ${calculateViewerCount(viewerObj[viewer])} ${viewer}`;
    }
    if (index === Object.keys(viewerObj).length - 1) {
      viewers += ')';
    }
  });
  return `${calculateViewerCount(total)} ${viewers}`;
}

export function getUnreadMessageCount(state) {
  return state.messages.filter((m) => m.unread).length;
}

export function getUnreadStreamMessageCount(state) {
  return state.streamMessages.filter((m) => m.unread).length;
}

export function getDesign(state, getters) {
  return getters.getLocalUser.isModerator ? state.design : state.roomConfig?.design;
}

export function getActiveBackground(_, getters) {
  return getters.getDesign['backgroundList'] ? getters.getDesign['backgroundList'].find((background) => background.active) : null;
}

export function getActiveOverlay(_, getters) {
  return getters.getDesign['overlayList'] ? getters.getDesign['overlayList'].find((overlay) => overlay.active) : null;
}

export function getDesignProperties(_, getters) {
  return (type) => {
    let designObject = {};
    let stateObject = getters.getDesign;
    switch (type) {
      case 'studioBackground':
        if (getters.getActiveBackground?.id) {
          designObject['background-image'] = `url(${getters.getActiveBackground.url})`;
          designObject['background-size'] = 'cover';
        } else {
          designObject['background'] = stateObject['studioBackgroundColor'];
        }
        break;
      case 'userName':
        if (stateObject['hideUserName']) {
          designObject['display'] = 'none!important';
        }
        if (stateObject['background']) {
          designObject['background'] = stateObject['background'];
        }
        break;
      case 'banner':
        if (stateObject['background']) {
          designObject['background'] = stateObject['background'];
        }
        break;
      case 'studioOverlay':
        if (getters.getActiveOverlay?.id) {
          designObject['background-image'] = `url(${getters.getActiveOverlay.url})`;
        }
        break;
    }

    return designObject;
  };
}

export function stagedScreenShareIndex(_, getters) {
  return getters.getStagedUsers.findIndex((user) => user.isScreen);
}

export function getIsScreenUserExists(_, getters) {
  return getters.getStagedUsers.some((user) => user.isScreen);
}

export function animatedSubtitle(_, getters) {
  return getters.getDesign?.animatedElements?.animatedSubtitle;
}

export function isAnimatedSubtitleActive(_, getters) {
  return getters.animatedSubtitle && getters.animatedSubtitle?.text !== '' && !getters.animatedSubtitle?.hidden;
}

