import * as ebml from "ts-ebml";
import { consoleLog, consoleError } from "../utils/logger";

const status = {
  PAUSED: "paused",
  RECORDING: "recording",
};

export default class LocalRecordingManager {
  constructor(conference) {
    this.recorder = null;
    this.recordingData = [];
    this.recorderStream = null;
    this.ctx = null;
    this.dest = null;
    this.conference = conference;
  }

  getSeekableBlob(inputBlob, callback) {
    const reader = new ebml.Reader();
    const decoder = new ebml.Decoder();
    const tools = ebml.tools;

    const fileReader = new FileReader();
    fileReader.onload = function () {
      const ebmlElms = decoder.decode(this.result);
      ebmlElms.forEach(function (element) {
        reader.read(element);
      });
      reader.stop();
      const refinedMetadataBuf = tools.makeMetadataSeekable(reader.metadatas, reader.duration, reader.cues);
      const body = this.result.slice(reader.metadataSize);
      const newBlob = new Blob([refinedMetadataBuf, body], {
        type: "video/webm",
      });

      callback(newBlob);
    };

    fileReader.onerror = function () {
      callback(inputBlob);
    };

    fileReader.readAsArrayBuffer(inputBlob);
  }

  mixer(stream1, stream2) {
    this.ctx = new AudioContext();
    this.dest = this.ctx.createMediaStreamDestination();

    if (stream1.getAudioTracks().length > 0) this.ctx.createMediaStreamSource(stream1).connect(this.dest);

    if (stream2.getAudioTracks().length > 0) this.ctx.createMediaStreamSource(stream2).connect(this.dest);

    let tracks = this.dest.stream.getTracks();
    tracks = tracks.concat(stream1.getVideoTracks()).concat(stream2.getVideoTracks());

    return new MediaStream(tracks);
  }

  addParticipantTracks() {
    const participants = this.conference.room.getParticipants() || [];

    participants.forEach((participant) => {
      const audioTracks = participant.getTracksByMediaType("audio");
      if (audioTracks.length) {
        this.ctx.createMediaStreamSource(audioTracks[0].stream).connect(this.dest);
      }
    });

    this.conference.eventEmitter.on("RemoteTrackAdded", this.onRemoteTrack.bind(this));
  }

  onRemoteTrack({ track }) {
    if (track.getType() === "audio") {
      this.ctx.createMediaStreamSource(track.stream).connect(this.dest);
    }
  }

  getFilename() {
    const now = new Date();
    const timestamp = now.toISOString();
    const room = new RegExp(/(^.+)\s\|/).exec(document.title);

    if (room && room[1] !== "") {
      return `${room[1]}_${timestamp}`;
    }

    return `recording_${timestamp}`;
  }

  async start() {
    let gumStream;
    let gdmStream;
    this.recordingData = [];

    try {
      gumStream = await navigator.mediaDevices.getUserMedia({ video: false, audio: true });
      gdmStream = await navigator.mediaDevices.getDisplayMedia({
        video: { displaySurface: "browser" },
        audio: false,
      });

      gdmStream.getVideoTracks()[0].addEventListener("ended", () => {
        this.emitStop();
      });
    } catch (e) {
      consoleError("capture failure", e);
      return false;
    }

    this.recorderStream = gumStream ? this.mixer(gumStream, gdmStream) : gdmStream;

    this.addParticipantTracks();

    const mimeType = navigator.userAgent.includes("Firefox") ? "video/webm" : "video/webm; codecs=vp9";
    this.recorder = new MediaRecorder(this.recorderStream, { mimeType });

    this.recorder.ondataavailable = (e) => {
      if (e.data && e.data.size > 0) {
        this.recordingData.push(e.data);
      }
    };

    this.recorder.onStop = () => {
      this.recorderStream.getTracks().forEach((track) => track.stop());
      gumStream.getTracks().forEach((track) => track.stop());
      gdmStream.getTracks().forEach((track) => track.stop());
      this.emitStop();
    };

    this.recorderStream.addEventListener("inactive", () => {
      consoleLog("Capture stream inactive");
    });

    consoleLog("started recording");
    this.recorder.start();
    this.conference.localUser.setLocalParticipantProperty("localRecording", "true");
    const date = new Date();
    this.conference.localUser.setLocalParticipantProperty("localRecordingAt", date.toISOString());
    return true;
  }

  emitStop() {
    this.conference.localUser.setLocalParticipantProperty("localRecording", "false");
    this.conference.localUser.setLocalParticipantProperty("localRecordingAt", "false");
    this.conference.eventEmitter.off("RemoteTrackAdded", this.onRemoteTrack);
    // TODO: bu yamayı duzeltiriz
    setTimeout(() => {
      this.conference.localUser.setLocalParticipantProperty("localRecording", "null");
      this.conference.localUser.setLocalParticipantProperty("localRecordingAt", "null");
    }, 100);
  }

  stop() {
    if (!this.recorder) {
      return consoleError("There is no any recorder.");
    }

    this.recorder.stop();
    this.recorder.onStop();
    consoleLog("Stopping recording");
  }

  pause() {
    switch (this.recorder.state) {
      case status.PAUSED:
        this.recorder.resume();
        break;

      case status.RECORDING:
        this.recorder.pause();
        break;

      default:
        consoleError(`recorder in unhandled state: ${this.recorder.state}`);
        break;
    }

    consoleLog(`recorder ${this.recorder.state === status.PAUSED ? status.PAUSED : status.RECORDING}`);
  }

  saveConvertedRecord(callback) {
    return (blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = `${this.getFilename()}.webm`;
      document.body.appendChild(a);
      a.click();
      setTimeout(() => {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
        consoleLog(`${a.download} save option shown`);
        if (callback && typeof callback === "function") {
          callback();
        }
      }, 100);
    };
  }

  save(onCompleted) {
    const blob = new Blob(this.recordingData, { type: "video/webm" });
    this.getSeekableBlob(blob, this.saveConvertedRecord(onCompleted));
  }
}
