import store from '@/store';
import { ADD_USER } from '@/constants/mutation-types';
import { STUDIO } from '@/constants/modules';
import { NOTIFICATION } from '@/constants/modules';
import { NOTIFICATION_SOUND_TYPES } from '@/constants/notification';

const { USER_JOINED } = NOTIFICATION_SOUND_TYPES;
export const REMOTE_USER_JONIED = 'RemoteUserJoined';

export default function onRemoteUserJoined(remoteUser) {
  store.commitWithModule(STUDIO, ADD_USER, {
    user: {
      id: remoteUser.id,
      type: 'remote',
      displayName: remoteUser.displayName,
      role: remoteUser.role,
      handsUp: remoteUser.handsUp === 'true',
    },
  });

  store.dispatchWithModule(NOTIFICATION, 'playSound', USER_JOINED);
}

