import ENUMS, { MEDIA_DEVICE_TYPE } from "../constants/enums";
import axios from "axios";
import { parseJwt } from "../helpers/jwt";

import { consoleError, consoleLog, consoleWarning } from "../utils/logger"; //eslint-disable-line
import { ErrorWithCodes } from "../errors/errors";
import PresenterEffect from "./stream-effects/presenter/PresenterEffect";
import request from "../services/Request";
import { xperMeetBuilder } from "../main";

const {
  START_MUTED_POLICY,
  START_VIDEO_MUTED_POLICY,
  MODERATOR_VIDEO_MUTED,
  MODERATOR_AUDIO_MUTED,
  USER_VIDEO_MUTED,
  USER_AUDIO_MUTED,
} = ENUMS.RoomConfigEnums;

class LocalUser {
  constructor(conference) {
    this.tracks = [];
    this.conferenceInstance = conference;
    this.eventEmitter = conference.eventEmitter;
    this.libJitsi = conference.libJitsi;
    this.mediaDevices = conference.mediaDevices;
    this.user = { id: null, displayName: "", role: "participant", isJibri: false };
    this.audioMutedByModerator = false;
    this.videoMutedByModerator = false;
    this.screenSharing = false;
    this.openCameraAfterScreenShare = false;
    this.audioContainerId = null;
    this.videoContainerId = null;
    this.lobbyAutoJoinRequest = false;
    this.activeEffect = null;
    this.isScreen = false;
    this.prejoinConfig = {
      startWithAudioMuted: false,
      startWithVideoMuted: false,
    };

    this.checkIsJibri();
  }

  get getUser() {
    return this.user;
  }

  get videoElementId() {
    return `local-video-${this.getUser.id}`;
  }
  get audioElementId() {
    return `local-audio-${this.getUser.id}`;
  }

  setIsScreen(isScreen) {
    this.isScreen = isScreen;
  }

  async initWithRoomConfig() {
    try {
      if (this.isScreen) return;

      const roomConfigObject = await this.conferenceInstance.roomConfig.fetchDataSource();
      const startMutedPolicy = roomConfigObject[START_MUTED_POLICY];
      const startVideoMutedPolicy = roomConfigObject[START_VIDEO_MUTED_POLICY];

      this.onAudioStartMutedPolicyChange(startMutedPolicy, this.prejoinConfig.startWithAudioMuted);
      this.onVideoStartMutedPolicyChange(startVideoMutedPolicy, this.prejoinConfig.startWithVideoMuted);
    } catch (err) {
      consoleError("InitWithRoomConfigError: ", err);
    }
  }

  setUserId(id) {
    this.user.id = id;
  }

  setUserRole(role) {
    this.user.role = role;
  }

  setVideoContainerId(id) {
    this.videoContainerId = id;
  }

  setAudioContainerId(id) {
    this.audioContainerId = id;
  }

  setAudioMutedByModerator(state) {
    this.audioMutedByModerator = state;
    this.eventEmitter.emit("UserAudioMutedByModeratorChanged", { userId: this.getUser.id, audioMutedByModerator: state });
  }

  setVideoMutedByModerator(state) {
    this.videoMutedByModerator = state;
    this.eventEmitter.emit("UserVideoMutedByModeratorChanged", { userId: this.getUser.id, videoMutedByModerator: state });
  }

  setLobbyAutoJoinRequest(state) {
    this.lobbyAutoJoinRequest = state;
  }

  setDisplayName(name) {
    this.user.displayName = name;
    const room = this.conferenceInstance?.room;
    if (room) {
      room.setDisplayName(name);
    }
  }

  setSenderVideoConstraint(resolution = 720) {
    const room = this.conferenceInstance?.room;
    if (room) {
      room.setSenderVideoConstraint(resolution);
    }
  }

  setReceiverVideoConstraint(resolution = 720) {
    const room = this.conferenceInstance?.room;
    if (room) {
      room.setReceiverVideoConstraint(resolution);
    }
  }

  setPrejoinConfig(config) {
    this.prejoinConfig = {
      ...this.prejoinConfig,
      ...config,
    };
  }

  checkIsJibri() {
    const usernameOverride = localStorage.getItem("xmpp_username_override");
    const passwordOverride = localStorage.getItem("xmpp_password_override");

    if (usernameOverride && passwordOverride) {
      this.user.isJibri = true;
    }
  }

  setAvatar(avatar) {
    this.setLocalParticipantProperty("avatar", avatar);
  }

  setSpeakerMuted(isMuted) {
    this.setLocalParticipantProperty("speakerMuted", isMuted.toString());
  }

  setLocalParticipantProperty(prop, value) {
    this.conferenceInstance.room.setLocalParticipantProperty(prop, value);
    this.eventEmitter.emit("UserPropertyChange", { userId: this.getUser.id, property: prop, value });
  }

  async setEffect(deviceId, attachElement = true) {
    const tracks = await this.libJitsi.createLocalTracks({
      devices: [MEDIA_DEVICE_TYPE.VIDEO],
      ...this.conferenceInstance.confOptions,
      cameraDeviceId: deviceId || this.mediaDevices.videoInputDeviceId,
      micDeviceId: this.mediaDevices.audioInputDeviceId,
    });

    const desktopTrack = this.tracks[1];
    const cameraTrack = tracks[0];
    const videoStream = cameraTrack.stream;
    const presenterEffect = new PresenterEffect(videoStream);
    presenterEffect.startEffect(desktopTrack.stream);
    await desktopTrack.setEffect(presenterEffect);
    this.activeEffect = presenterEffect;

    if (attachElement) {
      this.tracks[1].attach(document.getElementById(this.videoElementId));
    }
  }

  async removeEffect(attachElement = true) {
    if (this.activeEffect) {
      this.activeEffect.stopEffect();
    }
    if (this.tracks.length && this.tracks.length > 1) {
      await this.tracks[1].setEffect();
      if (attachElement) {
        this.tracks[1].attach(document.getElementById(this.videoElementId));
      }
    }
    this.activeEffect = null;
  }

  createLocalTracksF({ deviceType, firePermissionPromptIsShownEvent, muted, options = {} }) {
    if (deviceType === MEDIA_DEVICE_TYPE.VIDEO) {
      options.cameraDeviceId = options.cameraDeviceId || this.mediaDevices.videoInputDeviceId || null;
    } else if (deviceType === MEDIA_DEVICE_TYPE.AUDIO) {
      options.micDeviceId = options.micDeviceId || this.mediaDevices.audioInputDeviceId || null;
    }

    return new Promise((resolve, reject) => {
      this.libJitsi
        .createLocalTracks(
          {
            devices: [deviceType],
            ...options,
          },
          firePermissionPromptIsShownEvent,
        )
        .then(async (tracks) => {
          // Start muted
          if (muted) {
            await tracks[0].mute();
            this.onMuteChanged(tracks[0]);
          }
          if (deviceType === MEDIA_DEVICE_TYPE.AUDIO) {
            this.tracks[0] = tracks[0];
          } else if (deviceType === MEDIA_DEVICE_TYPE.VIDEO) {
            this.tracks[1] = tracks[0];
          }
          return resolve(tracks);
        })
        .catch((err) => {
          consoleError("Failed to create local tracks", deviceType, err);

          return reject(err);
        });
    });
  }

  createLocalTracks(deviceType, room, firePermissionPromptIsShownEvent = true, muted = false) {
    return new Promise((resolve, reject) => {
      this.createLocalTracksF({ deviceType, firePermissionPromptIsShownEvent, muted })
        .then((tracks) => {
          this.onLocalTrack(room, tracks);
          resolve(tracks);
        })
        .catch((err) => {
          consoleError(err);
          reject(err);
        });
    });
  }

  replaceLocalTrack(track, attach = true) {
    return new Promise(async (resolve) => {
      const room = this.conferenceInstance.room;

      let oldTrack = track.getType() === MEDIA_DEVICE_TYPE.AUDIO ? room.getLocalAudioTrack() : room.getLocalVideoTrack();
      const elementId = track.getType() === MEDIA_DEVICE_TYPE.AUDIO ? this.audioElementId : this.videoElementId;
      const broadcastEl = document.getElementById(`${elementId}-broadcast`);

      // If old track is exist, dispose it
      consoleLog("replaceLocalTrack", oldTrack);
      if (oldTrack) {
        try {
          await room.removeTrack(oldTrack);
          if (attach) {
            track.attach(document.getElementById(elementId));
            if (broadcastEl) {
              track.attach(broadcastEl);
            }
          }
          await room.replaceTrack(oldTrack, track);
          resolve();
        } catch (e) {
          consoleError("Xpermeet - Room remove track throw err: ", e);
        }
      } else {
        if (attach) {
          track.attach(document.getElementById(elementId));
          if (broadcastEl) {
            track.attach(broadcastEl);
          }
        }
        await room.addTrack(track);
        resolve();
      }
    });
  }

  /**
   * get keycloak token get sub property. set it to localParticipantProperty
   * @returns {boolean}
   */
  setKeycloakId() {
    const token = localStorage.getItem("keycloak-token") || null;
    if (!token) return false;
    const keycloakJwtObject = parseJwt(token);
    this.setLocalParticipantProperty("KeycloakId", keycloakJwtObject.sub);
  }

  switchLocalTrack(deviceType, deviceId) {
    return new Promise(async (resolve, reject) => {
      const room = this.conferenceInstance.room;

      let oldDeviceId;
      if (deviceType === MEDIA_DEVICE_TYPE.AUDIO) {
        oldDeviceId = room.getLocalAudioTrack()?.deviceId;
      } else if (deviceType === MEDIA_DEVICE_TYPE.VIDEO) {
        oldDeviceId = room.getLocalVideoTrack()?.deviceId;
      }
      if (oldDeviceId === deviceId) {
        resolve();
        consoleLog("Didn't change local track with same deviceId");
        return;
      }

      if (this.screenSharing && !this.activeEffect) {
        resolve();
        consoleLog("Didn't change local track");
        return;
      }

      if (deviceType === MEDIA_DEVICE_TYPE.VIDEO && this.videoMutedByModerator) {
        reject(ErrorWithCodes.MODERATOR_MUTED);
      }

      if (deviceType === MEDIA_DEVICE_TYPE.AUDIO && this.audioMutedByModerator) {
        reject(ErrorWithCodes.MODERATOR_MUTED);
      }

      if (deviceType === MEDIA_DEVICE_TYPE.VIDEO && this.screenSharing) {
        await this.removeEffect();
        await this.setEffect(deviceId);
        resolve();
      } else {
        const oldTrack = deviceType === MEDIA_DEVICE_TYPE.AUDIO ? room.getLocalAudioTrack() : room.getLocalVideoTrack();
        const isMuted = oldTrack ? oldTrack.isMuted() : true;
        const options = {};

        if (deviceType === MEDIA_DEVICE_TYPE.VIDEO) {
          options.cameraDeviceId = deviceId;
        } else if (deviceType === MEDIA_DEVICE_TYPE.AUDIO) {
          options.micDeviceId = deviceId;
        }

        this.createLocalTracksF({ deviceType, options })
          .then((tracks) => {
            // Set previous mute state
            if (isMuted) {
              tracks[0].mute();
            }

            if (deviceType === MEDIA_DEVICE_TYPE.AUDIO) {
              room.replaceTrack(oldTrack, tracks[0]);

              setTimeout(() => {
                oldTrack.dispose();
              });
              resolve();
            } else if (deviceType === MEDIA_DEVICE_TYPE.VIDEO) {
              let newTrack = tracks[0];

              try {
                room.replaceTrack(oldTrack, newTrack).then(() => {
                  const el = document.getElementById(this.videoElementId);
                  const broadcastEl = document.getElementById(`${this.videoElementId}-broadcast`);

                  if (oldTrack) {
                    oldTrack.dispose().then(() => {
                      newTrack.attach(el);
                      if (broadcastEl) {
                        newTrack.attach(broadcastEl);
                      }
                      resolve();
                    });
                  } else {
                    newTrack.attach(el);
                    if (broadcastEl) {
                      newTrack.attach(broadcastEl);
                    }
                    resolve();
                  }
                });
              } catch (e) {
                consoleError("Xpermeet - Room remove track throw err: ", e);
                reject(e);
              }
            }
          })
          .catch((e) => {
            consoleError(e);
            reject(e);
          });
      }
    });
  }

  setAudioOutputDevice(deviceId) {
    this.libJitsi.mediaDevices.setAudioOutputDevice(deviceId);
  }

  startScreenShare(roomName) {
    if (!this.conferenceInstance.isScreenShareAllowed()) {
      return Promise.reject(ErrorWithCodes.NOT_ALLOWED);
    }

    this.screenInstance = xperMeetBuilder.createScreenShareInstance();

    return new Promise((resolve, reject) => {
      this.screenInstance.lib
        .createLocalTracks({
          devices: [MEDIA_DEVICE_TYPE.DESKTOP],
          ...this.screenInstance.conference.confOptions,
        })
        .then(async (tracks) => {
          const desktopVideoTrack = tracks.find((track) => track.type === MEDIA_DEVICE_TYPE.VIDEO);
          this.desktopAudioStream = tracks.find((track) => track.type === MEDIA_DEVICE_TYPE.AUDIO);

          // Screen user is represent instance of localUser like other users

          const screenUser = this.screenInstance.conference.localUser;
          this.screenInstance.on("LocalUserJoined", () => {
            // set screen user display name
            screenUser.setIsScreen(true);
            screenUser.setDisplayName(this.getUser.displayName);

            screenUser.replaceLocalTrack(desktopVideoTrack, false);
            if (this.desktopAudioStream) {
              screenUser.replaceLocalTrack(this.desktopAudioStream, false);
            }

            // isScreen prop help us to separate screen user from others
            screenUser.setLocalParticipantProperty("isScreen", "true");
          });

          this.screenInstance.on("ConferenceJoined", () => {
            desktopVideoTrack.addEventListener(this.screenInstance.conference.libJitsi.events.track.LOCAL_TRACK_STOPPED, this.onScreenShareStopped.bind(this));

            // screen user must e start as video muted until replacing tracks with Desktop screen
            // this.eventEmitter.emit("UserVideoMuteChanged", { userId: screenUser.getUser.id, muteState: true });
            this.eventEmitter.emit("ScreenShareStarted", { userId: this.getUser.id });

            resolve();
          });

          this.screenInstance.on("ConferenceFailed", (e) => {
            consoleError("startScreenShare: ", e);
            this.stopScreenShare();
            reject();
          });

          await this.screenInstance.init({
            config: XPER_CONFIG.serverConfig,
            roomName: roomName,
          });
        })
        .catch((e) => {
          consoleError("startScreenShare: ", e);
          this.stopScreenShare();
          reject(e);
        });
    });
  }

  onScreenShareStopped() {
    consoleLog("onScreenShareStopped");
    this.stopScreenShare();
  }

  onAudioStartMutedPolicyChange(policy, forceMuted) {
    const room = this?.conferenceInstance?.room;
    const isModerator = room?.getRole() === "moderator";
    const audioTrack = room.getLocalAudioTrack();
    const participantCount = room.getParticipantCount();
    const moderatorAudioMuted = policy === MODERATOR_AUDIO_MUTED;
    // if participant count more than nine, all participants must be start muted due to performance reasons
    const muteAudio = participantCount > 9 || policy === USER_AUDIO_MUTED || moderatorAudioMuted || forceMuted;

    if (audioTrack) {
      if (muteAudio) {
        this.muteAudio(true);
      }
    } else {
      this.createLocalTracks(MEDIA_DEVICE_TYPE.AUDIO, room, true, muteAudio).then((tracks) => {
        this.replaceLocalTrack(tracks[0]);
      });
    }

    if (!isModerator) {
      this.setAudioMutedByModerator(moderatorAudioMuted);
    }
  }

  onVideoStartMutedPolicyChange(policy, forceMuted) {
    const room = this?.conferenceInstance?.room;
    const participantCount = room.getParticipantCount();
    const isModerator = room?.getRole() === "moderator";
    const videoTrack = room.getLocalVideoTrack();
    const moderatorVideoMuted = policy === MODERATOR_VIDEO_MUTED;
    // if participant count more than nine, all participants must be start muted due to performance reasons
    const muteVideo = participantCount > 9 || policy === USER_VIDEO_MUTED || moderatorVideoMuted || forceMuted;

    if (videoTrack) {
      if (muteVideo) {
        this.muteVideo(true);
      }
    } else {
      if (!muteVideo) {
        this.createLocalTracks(MEDIA_DEVICE_TYPE.VIDEO, room, true, muteVideo).then((tracks) => {
          this.replaceLocalTrack(tracks[0]);
        });
      } else {
        // this.eventEmitter.emit("UserVideoMuteChanged", { userId: this.getUser.id, muteState: true });
      }
    }

    if (!isModerator) {
      this.setVideoMutedByModerator(moderatorVideoMuted);
    }
  }

  async stopScreenShare() {
    if (!this.screenInstance) {
      return;
    }

    if (this.desktopAudioStream) {
      await this.desktopAudioStream.dispose();
      this.desktopAudioStream = null;
    }

    const videoTracks = this.screenInstance.conference.room.getLocalTracks(MEDIA_DEVICE_TYPE.VIDEO);

    if (videoTracks.length) {
      await this.screenInstance.conference.room.removeTrack(videoTracks[0]);
      await videoTracks[0].dispose();
    }
    this.setLocalParticipantProperty("ScreenSharing", "false");
    this.eventEmitter.emit("ScreenShareStopped", { userId: this.getUser.id });

    const callback = () => {
      this.screenInstance = null;
      consoleLog("Screen Share Stopped");
    };

    this.screenInstance.leaveCall({
      callback: callback,
    });
  }

  handsUp(state) {
    this.setLocalParticipantProperty("handsUp", state);
  }

  toggleE2ee(state) {
    this.setLocalParticipantProperty("localUserE2ee", state.toString());
  }

  fileShare(data) {
    this.eventEmitter.emit("UserPropertyChange", { userId: this.getUser.id, property: "fileShare", value: data });
  }

  muteAudio(mute) {
    if (this.conferenceInstance.room.getLocalAudioTrack()) {
      if (mute) {
        return this.conferenceInstance.room.getLocalAudioTrack().mute();
      } else {
        return this.conferenceInstance.room.getLocalAudioTrack().unmute();
      }
    } else {
      consoleWarning("Local user audio track could not find...");
    }
  }

  async muteVideo(mute) {
    const videoTrack = this.tracks.findIndex((track) => {
      if (track) {
        return track.type === MEDIA_DEVICE_TYPE.VIDEO;
      }
    });
    if (videoTrack !== -1) {
      if (this.screenSharing) {
        if (!mute) {
          this.setEffect();
          this.eventEmitter.emit("UserVideoMuteChanged", { userId: this.getUser.id, muteState: false });
        } else {
          await this.removeEffect();
          this.eventEmitter.emit("UserVideoMuteChanged", { userId: this.getUser.id, muteState: true });
        }
      } else {
        if (mute) {
          return this.conferenceInstance.room.getLocalVideoTrack().mute();
        } else {
          return this.conferenceInstance.room.getLocalVideoTrack().unmute();
        }
      }
    } else {
      // ekran paylaşımı kapatıp ve kamera unmute edilirken bu state'e düşer.
      const localTrack = this.conferenceInstance.room.getLocalVideoTrack();
      if (localTrack) {
        await localTrack.dispose();
      }

      this.createLocalTracksF({ deviceType: MEDIA_DEVICE_TYPE.VIDEO })
        .then(async (tracks) => {
          await this.replaceLocalTrack(tracks[0]);

          this.eventEmitter.emit("UserVideoMuteChanged", { userId: this.getUser.id, muteState: false });

          // Bug fix: Kamera track oluşturulduğunda diğer kullanıcılara track type desktop olarak gidiyor.
          // mute olarak kalıyor. Bu sebepten mute/unmute yapıldı
          tracks[0].mute().then(() => {
            if (!mute) {
              tracks[0].unmute();
            }
          });
        })
        .catch((error) => {
          consoleError(error);
        });
    }
  }

  onLocalTrack(room, tracks = []) {
    const userId = room.myUserId();

    tracks.forEach((track) => {
      track.addEventListener(this.libJitsi.events.track.TRACK_AUDIO_LEVEL_CHANGED, this.onAudioLevelChanged.bind(this));
      track.addEventListener(this.libJitsi.events.track.TRACK_MUTE_CHANGED, this.onMuteChanged.bind(this));
      track.addEventListener(this.libJitsi.events.track.LOCAL_TRACK_STOPPED, this.onTrackStopped.bind(this));
      track.addEventListener(this.libJitsi.events.track.TRACK_AUDIO_OUTPUT_CHANGED, this.onAudioOutputChanged.bind(this));

      if (!this.getUser.isJibri) {
        this.eventEmitter.emit("LocalTrackAdded", { userId, track });
      }
    });
  }

  onMuteChanged(track) {
    consoleLog("onMuteChanged", track);
    if (track.getType() === MEDIA_DEVICE_TYPE.VIDEO) {
      this.eventEmitter.emit("UserVideoMuteChanged", { userId: this.getUser.id, muteState: track.isMuted() });
    } else {
      this.eventEmitter.emit("UserAudioMuteChanged", { userId: this.getUser.id, muteState: track.isMuted() });
    }
  }

  onTrackStopped() {
    consoleLog("local track stopped");
  }

  onAudioOutputChanged(deviceId) {
    consoleLog(`track audio output device was changed to ${deviceId}`);
  }

  onAudioLevelChanged(audioLevel) {
    consoleLog("onAudioLevelChanged", audioLevel);
  }

  sendMeetingRequest(payload) {
    const mailServer = window.XPER_CONFIG.mailServer || "https://meetorc-api-dev-be.bizbize.live/api/Communication/SendEmail";
    let header = request.getHeaderByType("json");
    const tenant = window.XPER_CONFIG.mailServerTenant || "dev.bizbize.live";
    header.headers.tenant = tenant;
    return axios.post(mailServer, payload, header);
  }
}

export default LocalUser;
