import {
  SET_USE_DEFAULT_HOTKEYS,
  SET_HOTKEYS,
  SET_BANNERS,
  SET_GENERAL_SETTINGS,
  SET_AVATAR,
  SET_SHOW_LOGO_UPLOAD_MODAL,
  SET_SHOW_BACKGROUND_UPLOAD_MODAL,
  SET_SHOW_OVERLAY_UPLOAD_MODAL,
  SET_LOGO_LIST,
  SET_BACKGROUND_LIST,
  SET_OVERLAY_LIST,
} from '@/constants/mutation-types';
import { HOTKEYS } from '@/constants/settings';

const { USE_DEFAULT_HOTKEYS } = HOTKEYS;

export default {
  [SET_USE_DEFAULT_HOTKEYS](state, payload) {
    state[USE_DEFAULT_HOTKEYS] = payload;
  },
  [SET_HOTKEYS](state, payload) {
    state.hotkeys = [...payload];
  },
  [SET_BANNERS](state, payload) {
    state.banners = [...payload];
  },
  [SET_GENERAL_SETTINGS](state, payload) {
    state.generalSettings = payload;
  },
  [SET_AVATAR](state, payload) {
    state.avatar = payload;
  },
  [SET_SHOW_LOGO_UPLOAD_MODAL](state, payload) {
    state.showLogoUploadModal = payload;
  },
  [SET_SHOW_BACKGROUND_UPLOAD_MODAL](state, payload) {
    state.showBackgroundUploadModal = payload;
  },
  [SET_SHOW_OVERLAY_UPLOAD_MODAL](state, payload) {
    state.showOverlayUploadModal = payload;
  },
  [SET_LOGO_LIST](state, payload) {
    state.logoList = payload;
  },
  [SET_BACKGROUND_LIST](state, payload) {
    state.backgroundList = payload;
  },
  [SET_OVERLAY_LIST](state, payload) {
    state.overlayList = payload;
  },
};

