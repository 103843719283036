import { ERROR_WITH_CODES } from 'xpermeet-lib';
import router from '@/router';
import store from '@/store';
import i18n from '@/plugins/i18n';
import { TOAST_TYPE } from '@/constants/toast';
import { NOTIFICATION } from '@/constants/modules';

/**
 * event name
 */
export const CONNECTION_FAILED = 'ConnectionFailed';

import { getInstance } from '@/xpermeet';

const xperMeetLib = getInstance();

/**
 * Conenction Failed Event Handler
 * @param {*} error
 */
export default function onConnectionFailed(error) {
  if (error === ERROR_WITH_CODES.CONNECTION_PASSWORD_REQUIRED) {
    xperMeetLib.keycloakManager.redirectLoginPage();
  } else {
    store.dispatchWithModule(NOTIFICATION, 'showToastNotification', { body: i18n.t('connectionFailed'), config: { type: TOAST_TYPE.ERROR } });
    router.push({
      name: 'Broadcasts',
    });
  }
}

