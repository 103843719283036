export function getDestinationByUserProviderId(state) {
  return (userProviderId, oauthId = null) => {
    if (!oauthId) {
      return state.destinations.find((destination) => destination.raw.providerEntity.id === userProviderId);
    } else {
      return state.destinations.find((destination) => destination.raw.providerEntity.id === userProviderId && destination.raw.openAuthId === oauthId);
    }
  };
}

export function getDestinationById(state) {
  return (id) => {
    return state.destinations.find((destination) => destination.raw.id === id);
  };
}

export function myDestinationProviderEntityId(state) {
  return (userId, channel, isChatOwner, senderName) => {
    let providerEntityId = null;
    const isMyTwitch =
      channel === 'Twitch' &&
      Boolean(state.destinations.filter((destination) => destination.raw.openAuthId === userId && destination.channel === channel).length);
    const isMyYoutube = channel === 'Youtube' && isChatOwner;
    if (isMyTwitch || isMyYoutube) {
      providerEntityId = state.destinations.find((destination) => destination.user === senderName && destination.channel === channel)?.raw?.providerEntity?.id;
    }
    return providerEntityId;
  };
}

