import store from '@/store';
import i18n from '@/plugins/i18n';
import { NOTIFICATION } from '@/constants/modules';

export const LOCAL_TRACK_ERROR = 'LocalTrackError';

export default function onLocalTrackError({ devices, message }) {
  const msg = i18n.t(message, { devices: devices.join(', ') });
  store.dispatch(NOTIFICATION, 'showToastNotification', { body: msg, translate: false });
}

