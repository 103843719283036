var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "d-flex align-center app-logo",
      class: { "cursor-pointer": _vm.redirectable },
      style: `width: ${_vm.width}`,
      attrs: { draggable: "" },
      on: {
        click: _vm.handleRedirect,
        dragend: _vm.onLogoDragEnd,
        dragstart: _vm.onDragStart,
      },
    },
    [
      _vm.showMobileLogo
        ? _c("span", [_vm._v("Xper Stream Studio")])
        : _c(
            "div",
            { staticClass: "app-logo-wrapper" },
            [
              _c("v-img", {
                attrs: {
                  alt: "Vuetify Logo",
                  contain: "",
                  src: _vm.imageUrl,
                  "data-test-id": _vm.dataTestId,
                  transition: "scale-transition",
                },
              }),
            ],
            1
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }