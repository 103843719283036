var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "video-card",
      class: _vm.wrapperClassName,
      attrs: { id: _vm.wrapperId },
    },
    [
      _c("video", {
        class: [{ local: _vm.local, screen: _vm.isScreen }, _vm.className],
        attrs: { autoplay: "", playsinline: "", id: _vm.id },
      }),
      !_vm.showVideoContainer
        ? _c(
            "div",
            {
              staticClass:
                "video-card--closed d-flex align-center justify-center",
            },
            [
              _c("AppAvatar", {
                attrs: { size: _vm.avatarSize, src: _vm.avatarSource },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.userName && !_vm.isScreen
        ? _c(
            "span",
            {
              staticClass: "video-card--username d-flex align-center",
              class: {
                hidden: this.hideUsername,
                "margin-bottom-username": _vm.isAnimatedSubtitleActive,
              },
              style: _vm.displayNameStyle,
            },
            [
              _vm.micDisabled
                ? _c("AppIcon", {
                    staticClass: "mr-1",
                    attrs: { size: _vm.iconSize, icon: "mic-off" },
                  })
                : _vm._e(),
              _c(
                "span",
                {
                  staticClass: "ellipsis",
                  style: `font-size: ${_vm.fontSize}px`,
                },
                [_vm._v(_vm._s(_vm.userName))]
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }