import { consoleError } from 'xpermeet-lib';

import store from '@/store';
import { UPDATE_USER } from '@/constants/mutation-types';
import { STUDIO } from '@/constants/modules';
import { changeRoles, ROLE_ADMIN, ROLE_PARTICIPANT } from '@/lib/ability';
import { getInstance } from '@/xpermeet';

const xperMeetLib = getInstance();
export const USER_ROLE_CHANGED = 'UserRoleChanged';

export default async function onUserRoleChanged({ userId, role }) {
  store.commitWithModule(STUDIO, UPDATE_USER, { userId, data: { role } });

  if (userId === store.getters[`${STUDIO}/getLocalUser`]?.id) {
    if (role === 'moderator') {
      changeRoles([ROLE_ADMIN]);

      try {
        const roomConfig = await xperMeetLib.conference.fetchRoomConfig();
        const currentStagedUsers = roomConfig.stagedUsers;
        const stagedUsers = currentStagedUsers
          .filter((u) => store.getters[`${STUDIO}/getUserById`](u.id))
          .map((u, i) => ({
            id: u.id,
            order: i,
          }));

        store.dispatchWithModule(STUDIO, 'updateRoomConfig', { stagedUsers });
      } catch (err) {
        consoleError("Room config couln't fetch: onUserRoleChanged ", err);
      }
    } else {
      changeRoles([ROLE_PARTICIPANT]);
    }
  }
}

