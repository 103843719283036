import Vue from 'vue';
import { consoleError, consoleWarning } from 'xpermeet-lib';

import {
  ADD_MESSAGE,
  ADD_STAGED_USER,
  ADD_STREAM_MESSAGE,
  ADD_USER,
  ADD_USER_TRACK,
  REMOVE_STAGED_USER,
  REMOVE_USER,
  REMOVE_USER_TRACK,
  SET_AUDIO_MUTE_STATE,
  SET_SPEAKER_MUTE_STATE,
  SET_CONFERENCE_CREATED_TIMESTAMP,
  SET_CONFERENCE_JOINED,
  SET_CONFERENCE_MAX_USER_REACHED,
  SET_DOMINANT_USER_ID,
  SET_INITIAL_STATE,
  SET_IS_JIBRI,
  SET_ROOM_NAME,
  SET_ROOM_CONFIG,
  SET_RECORDING_STATUS,
  SET_REMOTE_RECORD_START_TIME,
  SET_VIDEO_MUTE_STATE,
  SET_WAITING_FOR_THE_HOST,
  SET_XPER_MEET_INITIALIZE,
  UPDATE_USER,
  SET_PREFERRED_VIDEO_QUALITY,
  SET_CONNECTION_STATE,
  SET_WEBINAR_SESSION_ID,
  SET_MEETING_ID,
  SET_SESSION,
  CLEAR_USERS,
  SET_WEBINAR_STREAM_ID,
  SET_MY_STREAM_MESSAGES,
  SET_MY_STREAM_MESSAGE_FROM_CLIENT,
  SET_MY_STREAM_MESSAGE_FROM_PROVIDER,
  UPDATE_DUPLICATE_MESSAGE,
  SET_SOLO_LAYOUT,
  SET_CURRENT_SESSION,
  SET_VIEWERS,
  UPDATE_STREAM_MESSAGE,
  DELETE_STREAM_MESSAGE,
  SET_CURRENT_BANNERS,
  SET_CURRENT_BANNER_ID,
  READ_ROOM_MESSAGES,
  READ_STREAM_MESSAGES,
  SET_DESIGN,
} from '@/constants/mutation-types';
import { User, getInitialSatate } from '.';

export default {
  [ADD_STAGED_USER](state, { userId }) {
    if (state.stagedUserIds.includes(userId)) {
      consoleWarning(ADD_STAGED_USER, `: User already exists with id ${userId}`);
    } else {
      state.stagedUserIds.push(userId);
    }
  },
  [REMOVE_STAGED_USER](state, { userId }) {
    state.stagedUserIds = state.stagedUserIds.filter((id) => id !== userId);
  },
  [ADD_MESSAGE](state, payload) {
    state.messages.push(payload);
  },
  [ADD_STREAM_MESSAGE](state, payload) {
    state.streamMessages.push(payload);
  },
  [UPDATE_STREAM_MESSAGE](state, payload) {
    const { id } = payload;
    const index = state.streamMessages.findIndex((message) => message.id === id);
    Vue.set(state.streamMessages, index, payload);
  },
  [DELETE_STREAM_MESSAGE](state, id) {
    const index = state.streamMessages.findIndex((message) => message.id === id);
    state.streamMessages.splice(index, 1);
  },
  [ADD_USER](state, { user }) {
    if (!state.users.find((u) => u.id === user.id)) {
      state.users.push(User(user));
    }
  },
  [ADD_USER_TRACK](state, { userId, track }) {
    const user = state.users.find((u) => u.id === userId);

    if (user) {
      if (track.getType() === 'audio') {
        Vue.set(user.tracks, 0, track);
      } else {
        Vue.set(user.tracks, 1, track);
      }
    }
  },
  [REMOVE_USER](state, { userId }) {
    const findIndex = state.users.findIndex((user) => user.id === userId);

    if (findIndex !== -1) {
      Vue.delete(state.users, findIndex);
    }
  },
  [REMOVE_USER_TRACK](state, { userId, track }) {
    const user = state.users.find((u) => u.id === userId);

    if (user) {
      if (track.getType() === 'audio') {
        Vue.delete(user.tracks, 0);
      } else {
        Vue.delete(user.tracks, 1);
      }
    }
  },
  [SET_AUDIO_MUTE_STATE](state, { userId, muteState }) {
    const user = state.users.find((u) => u.id === userId);
    if (user) {
      user.audioMuted = muteState;
    }
  },
  [SET_SPEAKER_MUTE_STATE](state, payload) {
    state.speakerMuted = payload;
  },
  [SET_SESSION](state, payload) {
    state.session = payload;
  },
  [SET_CONFERENCE_CREATED_TIMESTAMP](state, { timestamp }) {
    state.createdTimestamp = timestamp;
  },
  [SET_CONFERENCE_JOINED](state, { status }) {
    state.conferenceJoined = status;
  },
  [SET_CONFERENCE_MAX_USER_REACHED](state, { isReached }) {
    state.conferenceMaxUserReached = isReached;
  },
  [SET_DOMINANT_USER_ID](state, { userId }) {
    state.dominantUserId = userId;
  },
  // eslint-disable-next-line
  [SET_INITIAL_STATE](state) {
    const preventDelete = { currentSession: state.currentSession };
    Object.assign(state, getInitialSatate(), preventDelete);
  },
  [SET_IS_JIBRI](state, { isJibri }) {
    state.isJibri = isJibri;
  },
  [SET_ROOM_NAME](state, { roomName }) {
    state.roomName = roomName;
  },
  [SET_ROOM_CONFIG](state, { config }) {
    Vue.set(state, 'roomConfig', config);
  },
  [SET_RECORDING_STATUS](state, status) {
    state.recordingStatus = status;
  },
  [SET_REMOTE_RECORD_START_TIME](state, { timestamp }) {
    state.remoteRecordStartedAt = timestamp;
  },
  [SET_VIDEO_MUTE_STATE](state, { userId, muteState }) {
    const user = state.users.find((u) => u.id === userId);

    if (user) {
      user.videoMuted = muteState;
    }
  },
  [SET_WAITING_FOR_THE_HOST](state, { status }) {
    state.waitingForTheHost = status;
  },
  [SET_XPER_MEET_INITIALIZE](state, { status }) {
    state.xperMeetInitialized = status;
  },
  [UPDATE_USER](state, { userId, data }) {
    if (!userId || !data) {
      consoleError('UserId or Data is missing!');
      return;
    }

    const user = state.users.find((u) => u.id === userId);

    if (user) {
      Object.entries(data).forEach((item) => {
        Vue.set(user, item[0], item[1]);
      });
    }
  },
  [SET_PREFERRED_VIDEO_QUALITY](state, payload) {
    state.preferredVideoQuality = payload;
  },
  [SET_CONNECTION_STATE](state, payload) {
    state.connectionState = payload;
  },
  [SET_WEBINAR_SESSION_ID](state, payload) {
    state.webinarSessionId = payload;
  },
  [SET_WEBINAR_STREAM_ID](state, payload) {
    state.webinarStreamId = payload;
  },
  [SET_MEETING_ID](state, payload) {
    state.meetingId = payload;
  },
  [CLEAR_USERS](state) {
    state.users = [];
  },
  [SET_MY_STREAM_MESSAGES](state, payload) {
    state.myStreamMessages = payload;
  },
  [SET_MY_STREAM_MESSAGE_FROM_CLIENT](state, payload) {
    const { message, selectedSessionUserProviderIds } = payload;
    const messageWithoutSpaces = message.replace(/\s/g, '');
    selectedSessionUserProviderIds.forEach((session) => {
      if (state.myStreamMessages[session]?.[messageWithoutSpaces]) {
        state.myStreamMessages[session][messageWithoutSpaces] += 1;
      } else {
        Vue.set(state.myStreamMessages[session], messageWithoutSpaces, 1);
      }
    });
  },
  [SET_MY_STREAM_MESSAGE_FROM_PROVIDER](state, payload) {
    const { message, selectedSessionUserProviderId } = payload;
    const messageCount = state.myStreamMessages[selectedSessionUserProviderId][message];
    if (messageCount > 1) {
      state.myStreamMessages[selectedSessionUserProviderId][message] -= 1;
    }
    Vue.delete(state.myStreamMessages[selectedSessionUserProviderId], message);
  },
  [UPDATE_DUPLICATE_MESSAGE](state, payload) {
    const { message, data, openAuthId } = payload;
    const index = state.streamMessages.findIndex((msg) => msg.id === message.id);
    const selectedSessionPayload = [message.streamType, data.streamType];
    message.openAuthId.push(openAuthId);
    Vue.set(state.streamMessages, index, {
      ...message,
      selectedSessionPayload,
    });
  },
  [SET_SOLO_LAYOUT](state, payload) {
    state.selectedSoloLayout = payload;
  },
  [SET_CURRENT_SESSION](state, payload) {
    state.currentSession = payload;
  },
  [SET_VIEWERS](state, payload) {
    const { id, viewerCount } = payload;
    const viewer = state.viewers.find((viewer) => viewer.id === id);
    if (viewer) {
      viewer.viewerCount = viewerCount;
    } else {
      state.viewers.push(payload);
    }
  },
  [SET_CURRENT_BANNERS](state, payload) {
    state.currentBanners = payload;
  },
  [SET_CURRENT_BANNER_ID](state, payload) {
    state.currentBannerId = payload;
  },
  [READ_ROOM_MESSAGES](state) {
    state.messages = state.messages.map((m) => {
      m.unread = false;
      return m;
    });
  },
  [READ_STREAM_MESSAGES](state) {
    state.streamMessages = state.streamMessages.map((m) => {
      m.unread = false;
      return m;
    });
  },
  [SET_DESIGN](state, payload) {
    const { type, value } = payload;
    state.design[type] = value;
  },
};

