<template>
  <v-dialog
    v-model="showDialog"
    no-click-animation
    :persistent="persistent"
    :max-width="maxWidth"
    :content-class="`modal-container ${contentClass} ${!showActions ? 'hide-actions' : ''} `"
    overlay-opacity="0.6"
    overlay-color="modal-overlay"
    :fullscreen="fullScreen || $isMobile"
    @click:outside="close('outside')"
  >
    <v-card elevation="0">
      <div class="d-flex justify-space-between py-4 px-5">
        <div class="d-flex align-center">
          <AppIcon v-if="showBackButton" class="cursor-pointer mr-2 opacity-50" color="white" icon="arrow-left" @click="$emit('back')" />
          <span v-if="!standardTitle" class="card-title opacity-50">{{ title }}</span>
        </div>
        <AppIcon v-if="showCloseButton" data-test-id="modal-close-icon" class="cursor-pointer opacity-50" color="white" icon="close" @click="close('cancel')" />
      </div>

      <v-card-title class="modal-title justify-center" v-if="standardTitle">
        <div class="card-title opacity-50">
          {{ title }}
        </div>
      </v-card-title>
      <v-card-subtitle v-if="subTitle">
        <div class="opacity-50">{{ subTitle }}</div>
      </v-card-subtitle>
      <v-card-text :class="{ 'padding-0': paddingNone, 'pt-0': !standardTitle }">
        <slot />
      </v-card-text>
      <v-divider v-if="showBottomDivider" />
      <v-card-actions v-if="showActions">
        <div class="w-full d-flex justify-center align-center flex-wrap-reverse mb-n2">
          <app-button
            data-test-id="modal-cancel-button"
            class="mb-2"
            :block="$isMobile"
            :class="{ 'mb-3': $isMobile, 'mx-2': !$isMobile }"
            min-width="185"
            @click="close('cancel')"
          >
            <span>
              {{ cancelButtonText }}
            </span>
          </app-button>
          <app-button
            data-test-id="modal-confirm-button"
            :block="$isMobile"
            :class="{ 'mb-3': $isMobile, 'mx-2': !$isMobile }"
            min-width="185"
            class="mb-2"
            :disabled="confirmButtonDisabled"
            @click="close('confirm')"
            color="secondary"
            :loading="loading"
          >
            <span>
              {{ confirmButtonText }}
            </span>
          </app-button>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AppModal',
  props: {
    showCloseButton: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
    },
    showActions: {
      type: Boolean,
    },
    cancelButtonText: {
      type: String,
    },
    confirmButtonText: {
      type: String,
    },
    subTitle: {
      type: String,
    },
    maxWidth: {
      type: String,
      default: '500px',
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    fullScreen: {
      type: Boolean,
      default: false,
    },
    confirmButtonDisabled: {
      type: Boolean,
    },
    standardTitle: {
      type: Boolean,
    },
    paddingNone: {
      type: Boolean,
    },
    autoClose: {
      type: Boolean,
      default: true,
    },
    showBackButton: {
      type: Boolean,
    },
    showBottomDivider: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    contentClass: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showDialog: true,
    };
  },
  methods: {
    close(type) {
      this.$emit('close', type);
      if (this.autoClose && !(type === 'outside' && this.persistent)) {
        this.showDialog = false;
        // Modal Plugin Close Function
        this.$modal?.delayedClose();
      }
    },
  },
};
</script>

<style lang="scss">
.modal-container {
  border: 0.3px solid var(--v-border-color);
  &.v-dialog {
    &--fullscreen {
      overflow: hidden;
      max-height: 100vh;
    }
    border-radius: 8px;
    .v-card {
      display: flex;
      flex-direction: column;
      max-height: 70vh;
      background: var(--v-bg-gray-base);
      .card-title {
        font-size: 13px;
        font-weight: 400;
        line-height: 15px;
      }
      &__title {
        font-size: 18px;
        padding-left: 100px;
        padding-right: 100px;
        padding-top: 0px;
        word-break: initial;
        text-align: center;

        @media (max-width: $breakpoint-mobile) {
          padding-left: 20px;
          padding-right: 20px;
          padding-top: 80px;
        }
      }
      &__subtitle {
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        font-weight: 400;
        opacity: 0.8;
        margin-top: 8px;
        padding-bottom: 0;
        color: var(--v-white-base);
      }

      &__text {
        flex-grow: 1;
        flex-shrink: 1;
        padding: 16px 20px 8px 20px;
        display: flex;
        flex-direction: column;
        overflow: auto;
        @media (max-width: $breakpoint-mobile) {
          padding: 16px 24px;
        }
      }

      &__actions {
        padding: 24px 20px 24px 20px;

        @media (max-width: $breakpoint-mobile) {
          padding: 24px;
        }
      }
    }
  }

  &.hide-actions {
    &.v-dialog {
      .v-card {
        &__text {
          padding: 16px 20px 24px 20px;
          @media (max-width: $breakpoint-mobile) {
            padding: 24px;
            &.padding-0 {
              padding: 0px;
            }
          }
        }
      }
    }
  }
}
</style>
