export default class MediaDevices {
  constructor(xpermeet) {
    this._libJitsi = xpermeet.lib;
    this.eventEmitter = xpermeet.eventEmitter;
    this.enumerateDevices = this._libJitsi.mediaDevices.enumerateDevices;

    this.audioInputDeviceId = null;
    this.audioOutputDeviceId = null;
    this.videoInputDeviceId = null;

    this.handleEvents();
  }

  init() {}

  handleEvents() {
    this._libJitsi.mediaDevices.addEventListener(this._libJitsi.events.mediaDevices.PERMISSION_PROMPT_IS_SHOWN, (browser) => {
      this.eventEmitter.emit("PermissionPromptIsShown", browser);
    });
    this._libJitsi.mediaDevices.addEventListener(this._libJitsi.events.mediaDevices.DEVICE_LIST_CHANGED, (devices) => {
      this.eventEmitter.emit("DeviceListChanged", devices);
    });
  }

  setDefaultDevices(defaultDevices) {
    const { audioInput, audioOutput, videoInput } = defaultDevices;
    this.audioInputDeviceId = audioInput;
    this.audioOutputDeviceId = audioOutput;
    this.videoInputDeviceId = videoInput;
  }
}
