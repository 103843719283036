import store from '@/store';
import i18n from '@/plugins/i18n';

import { UPDATE_USER, SET_AUDIO_MUTE_STATE } from '@/constants/mutation-types';
import { STUDIO } from '@/constants/modules';
import { NOTIFICATION } from '@/constants/modules';

export const USER_AUDIO_MUTE_CHANGED_BY_MODERATOR = 'UserAudioMuteChangedByModerator';

export default function onUserAudioMuteChangedByModerator({ userId, muteState, moderatorDisplayName }) {
  const audioMutedByModerator = store.state[STUDIO].roomConfig.allowAdminStartVideo && muteState;
  store.commitWithModule(STUDIO, SET_AUDIO_MUTE_STATE, { userId, muteState });

  store.commitWithModule(STUDIO, UPDATE_USER, {
    userId,
    data: {
      audioMutedByModerator,
      audioMutedBy: moderatorDisplayName,
    },
  });

  const message = i18n.t(muteState ? 'audioMutedByModerator' : 'audioUnMutedByModerator', { moderatorDisplayName });
  store.dispatchWithModule(NOTIFICATION, 'showToastNotification', { body: message, translate: false });
}
