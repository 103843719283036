<template>
  <AppModal :title="title" :cancel-button-text="cancelText" :persistent="persistent" :confirm-button-text="confirmText" show-actions @close="onModalClose">
    <div class="text-center">
      {{ text }}
    </div>
  </AppModal>
</template>

<script>
export default {
  name: 'ConfirmModal',
  props: {
    title: {
      type: String,
      default: function () {
        return this.$t('confirm');
      },
    },
    text: {
      type: String,
      default: function () {
        return this.$t('areYouSure');
      },
    },
    confirmText: {
      type: String,
      default: function () {
        return this.$t('confirm');
      },
    },
    cancelText: {
      type: String,
      default: function () {
        return this.$t('cancel');
      },
    },
    persistent: {
      type: Boolean,
    },
  },
  methods: {
    onModalClose(type) {
      if (type === 'confirm') {
        this.$emit('confirm');
      } else {
        this.$emit('cancel');
      }
    },
  },
};
</script>
