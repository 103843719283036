var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-avatar",
    _vm._b(
      {
        staticClass: "avatar-wrapper",
        attrs: {
          color: _vm.color,
          size: _vm.getSize,
          "data-test-id": _vm.dataTestId,
        },
      },
      "v-avatar",
      _vm.$props,
      false
    ),
    [
      _vm.src
        ? _c("img", {
            staticClass: "avatar-image",
            attrs: {
              src: _vm.src,
              alt: _vm.text,
              referrerpolicy: "no-referrer",
            },
          })
        : _vm.getText
        ? _c(
            "span",
            { staticClass: "avatar-text", style: `color: ${_vm.textColor}` },
            [_vm._v(_vm._s(_vm.getText))]
          )
        : _c(
            "div",
            [
              _c("AppIcon", {
                attrs: { icon: _vm.getIcon, size: _vm.getIconSize },
              }),
            ],
            1
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }