export const NOTIFICATION_SOUND_TYPES = {
  USER_JOINED: 'userJoined',
};

export const NOTIFICATION_SOUNDS = {
  [NOTIFICATION_SOUND_TYPES.USER_JOINED]: 'user_joined.mp3',
};

export const ROOM_NOTIFICATION_TYPES = {
  TEXT: 0,
  RECORD: 1,
  MODERATOR_ROLE: 2,
  STOP_RECORD: 3,
};

export const NOTIFICATION_TYPES = {
  ROOM: 1,
  TOAST: 2,
};
