import axios from "axios";
import { checkIsJibri } from "../helpers/jibri";

function remoteLog(message) {
  axios.post("https://xperlog.herokuapp.com/log", { message }).catch(console.log);
}

function withRemoteLog(type = "log") {
  const log = logger(type);
  return (...args) => {
    const prefix = checkIsJibri() ? "jibri" : "participant";

    const message = args
      .map((a) => {
        try {
          a = JSON.stringify(a);
        } catch (err) {
          // err
          a = JSON.stringify(err);
        }

        return a;
      })
      .join("--------");

    remoteLog(`${prefix}-#-${message}`);
    log(...args);
  };
}

export function logger(fn = "log", prefix = "Logger: ") {
  if (process.env.NODE_ENV === "development" || fn === "error") {
    return Function.prototype.bind.call(console[fn], console, prefix);
  }
  return () => null;
}

export const consoleRemote = withRemoteLog("log");
export const consoleLog = logger();
export const consoleDebug = logger("debug");
export const consoleWarning = logger("warn");
export const consoleError = logger("error");
