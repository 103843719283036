import ConnectionConfig from "./ConnectionConfig";
import { consoleError, consoleLog } from "../utils/logger";
import { ErrorWithCodes } from "../errors/errors";

export default class ConnectionManager {
  constructor(xpermeet) {
    this._libJitsi = xpermeet.lib;
    this._mediaDeviceInstance = xpermeet.mediaDevices;
    this.connection = null;
    this.appId = undefined;
    this.connHasListeners = false;
    this.eventEmitter = xpermeet.eventEmitter;
  }

  createConnection(config, conferenceName, id, password) {
    const connectionConfig = JSON.parse(JSON.stringify(config));

    const authenticatorToken = localStorage.getItem("event-token") || localStorage.getItem("authenticator-token");
    const usernameOverride = localStorage.getItem("xmpp_username_override");
    const passwordOverride = localStorage.getItem("xmpp_password_override");

    if (usernameOverride && usernameOverride.length > 0) {
      id = usernameOverride;
    }
    if (passwordOverride && passwordOverride.length > 0) {
      password = passwordOverride;
    }

    connectionConfig.serviceUrl = connectionConfig.bosh + "?room=" + conferenceName;

    if (authenticatorToken) {
      connectionConfig.serviceUrl += "&token=" + authenticatorToken;
      // TODO incele neden siliniyor?
      if (!window.customerConfig?.arkTokenRequired) {
        localStorage.removeItem("authenticator-token");
      }
    }

    try {
      this._libJitsi.init(ConnectionConfig(connectionConfig));
      this.connection = new this._libJitsi.JitsiConnection(this.appId, authenticatorToken, ConnectionConfig(connectionConfig));
      this._libJitsi.setLogLevel(this._libJitsi.logLevels.ERROR);
      this.connection.connect({ id, password });
      consoleLog("connection successful");
    } catch (e) {
      consoleError(ErrorWithCodes.CONNECTION_FAILED, e);
    }

    // TODO bak
    // this.dominantUserId = null;

    this.addListeners();

    if (!this.connHasListeners) {
      this._mediaDeviceInstance.init();
      this.connHasListeners = true;
    }
    if (typeof window !== "undefined") {
      window.onbeforeunload = () => {
        this.disconnect();
        this.connection.disconnect();
      };
    }
  }

  disconnect() {
    this.connection.removeEventListener(this._libJitsi.events.connection.CONNECTION_ESTABLISHED, this.onConnectionSuccess.bind(this));
    this.connection.removeEventListener(this._libJitsi.events.connection.CONNECTION_FAILED, this.onConnectionFailed.bind(this));
    // this.connection.removeEventListener(this._libJitsi.lib.events.conference.AUTH_STATUS_CHANGED, xperUser.authStatusChanged);
    this.connection.removeEventListener(this._libJitsi.events.connection.CONNECTION_DISCONNECTED, this.disconnect.bind(this));
    this.connection.removeEventListener(this._libJitsi.events.connection.PASSWORD_REQUIRED, this.onPasswordRequired.bind(this));
  }

  onConnectionSuccess() {
    this.eventEmitter.emit("ConnectionSuccess");
  }
  onConnectionFailed(...args) {
    this.eventEmitter.emit("ConnectionFailed", args);
  }

  addListeners() {
    // this.connection.disconnect();

    this.connection.addEventListener(this._libJitsi.events.connection.CONNECTION_ESTABLISHED, this.onConnectionSuccess.bind(this));
    this.connection.addEventListener(this._libJitsi.events.connection.CONNECTION_FAILED, this.onConnectionFailed.bind(this));
    //this.connection.addEventListener(this._libJitsi.events.conference.AUTH_STATUS_CHANGED, xperUser.authStatusChanged.bind(this));
    this.connection.addEventListener(this._libJitsi.events.connection.CONNECTION_DISCONNECTED, this.disconnect.bind(this));
    this.connection.addEventListener(this._libJitsi.errors.conference.PASSWORD_REQUIRED, this.onPasswordRequired.bind(this));
  }

  onPasswordRequired() {
    consoleError("onPasswordRequired");
  }
}
