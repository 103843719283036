import BaseService from './base.service';

class XssService extends BaseService {
  constructor() {
    super({
      baseUrl: process.env.VUE_APP_PROVIDER_URL,
    });
  }

  addDestination(url) {
    return this._axios.get(url);
  }

  fetchDestinations() {
    return this._axios.get('/api/user/provider');
  }

  deleteUserDestination(id) {
    return this._axios.delete(`/api/user/provider/${id}`);
  }

  fetchSessions(params = {}) {
    return this._axios.get('/api/user/session', {
      params,
    });
  }

  fetchSessionById(id) {
    return this._axios.get(`/api/user/session/sessionId/${id}`);
  }

  fetchPublicSession(id) {
    return this._axios.get(`/api/public/user/session/sessionId/${id}`, { public: true });
  }

  deleteSession(body) {
    return this._axios.post('/api/user/session/delete', body);
  }

  deleteProvider(body) {
    return this._axios.post('/api/user/sessionProvider/delete', body);
  }

  updateSession(body) {
    return this._axios.put('/api/user/session', body);
  }

  shortList() {
    return this._axios.get('/api/provider/short-code-list');
  }

  prepareBroadcast(payload) {
    return this._axios.post('/api/prepareBroadcast', payload);
  }

  startBroadcast({ sessionId }) {
    return this._axios.post(`/api/startBroadcast?sessionId=${sessionId}`);
  }

  fetchSettings() {
    return this._axios.get('/api/user/settings');
  }

  setSettings(payload) {
    return this._axios.post('/api/user/settings', payload);
  }

  sendStreamMessage(payload) {
    return this._axios.post('/api/user/message', payload);
  }

  fetchUserSessionRecording() {
    return this._axios.get('/api/userSessionRecording');
  }

  deleteUserRecord(sessionRecordingId) {
    return this._axios.delete(`/api/recordDelete/${sessionRecordingId}`);
  }

  deleteAccount() {
    return this._axios.delete('/api/user');
  }
}

export default new XssService();
