<template>
  <v-app>
    <v-main class="pt-0">
      <router-view />
    </v-main>
    <toaster />
    <LoginRequiredModal />
  </v-app>
</template>

<script>
import { mapActions } from 'vuex';
import Toaster from '@/components/Notifications/Toast/Toaster';
import { USER, SETTINGS, DESTINATIONS, STUDIO } from '@/constants/modules';
import bannerQueue from '@/helpers/banner';
import { consoleError } from 'xpermeet-lib';
import LoginRequiredModal from '@/components/Modals/LoginRequiredModal.vue';

export default {
  name: 'App',
  components: { Toaster, LoginRequiredModal },
  created() {
    this.fetchData();
    bannerQueue.addCallback('change', this.onBannerChange);
  },
  methods: {
    ...mapActions(USER, ['fetchUser']),
    ...mapActions(SETTINGS, ['fetchSettings']),
    ...mapActions(DESTINATIONS, ['fetchDestinations', 'onBannerChange']),
    ...mapActions(STUDIO, ['onBannerChange']),
    async fetchData() {
      try {
        await this.fetchUser();
        await this.fetchDestinations();
        await this.fetchSettings();
      } catch (err) {
        consoleError('App Fetch Data Error: ', err);
      }
    },
  },
};
</script>

