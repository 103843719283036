export const STUDIO_SETTING_TYPES = {
  GENERAL: 0,
  CAMERA: 1,
  AUDIO: 2,
  HOTKEYS: 3,
  PERMISSIONS: 4,
  NOTIFICATIONS: 5,
};

export const NOTIFICATION_SETTINGS = {
  SOUNDS_ON_OFF: 'soundsOnOff',
};

export const HOTKEYS = {
  USE_DEFAULT_HOTKEYS: 'useDefaultsHotkeys',
  CAMERA_ON_OFF: 'cameraOnOff',
  SPEAKER_ON_OFF: 'speakerOnOff',
  MICROPHONE_ON_OFF: 'microphoneOnOff',
};

export const DEFAULT_HOTKEYS = [
  {
    type: HOTKEYS.CAMERA_ON_OFF,
    value: { key: 'x', keyCode: 88, code: 'KeyX' },
  },
  {
    type: HOTKEYS.SPEAKER_ON_OFF,
    value: { key: 'y', keyCode: 89, code: 'KeyY' },
  },
  {
    type: HOTKEYS.MICROPHONE_ON_OFF,
    value: { key: 'z', keyCode: 90, code: 'KeyZ' },
  },
];

export const VIDEO_QUALITY_LEVELS = {
  ULTRA: 2160,
  HIGH: 720,
  STANDARD: 360,
  LOW: 180,
};

export const EDITOR_DEFAULT_SETTINGS = [
  [
    {
      header: [false, 1, 2, 3, 4, 5, 6],
    },
  ],
  ['bold', 'italic', 'underline', 'strike'], // toggled buttons
  [
    {
      list: 'bullet',
    },
  ],
  ['link'],
];

export const DEFAULT_GENERAL_SETTINGS = {
  liveComments: {
    parmanent: true,
    duration: 4,
  },
  banners: {
    parmanent: true,
    duration: 4,
  },
};