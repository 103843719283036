import store from '@/store';
import { REMOVE_USER_TRACK } from '@/constants/mutation-types';
import { STUDIO } from '@/constants/modules';

/**
 * event name
 */
export const LOCAL_TRACK_REMOVED = 'LocalTrackRemoved';

/**
 * Local Track Removed Event Handler
 */
export default function onLocalTrackRemoved({ userId, track }) {
  store.commitWithModule(STUDIO, REMOVE_USER_TRACK, { userId, track });
}

