var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-input" },
    [
      _vm.label
        ? _c("div", { staticClass: "app-select--label mb-2" }, [
            _vm._v(_vm._s(_vm.label)),
          ])
        : _vm._e(),
      _c(
        "v-select",
        _vm._g(
          _vm._b(
            {
              attrs: {
                items: _vm.items,
                "data-test-id": _vm.dataTestId,
                "item-text": "text",
                "item-value": "value",
                label: "Solo field",
                outlined: "",
                dense: "",
                value: _vm.value,
                solo: "",
              },
              on: {
                input: function ($event) {
                  return _vm.$emit("input", $event)
                },
              },
            },
            "v-select",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }