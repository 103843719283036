import bannerQueue from '@/helpers/banner';

export const BANNER_REMOVED = 'BannerRemoved';

export default function onBannerRemoved(payload) {
  const banner = JSON.parse(payload.value);

  if (!banner.duration) {
    bannerQueue.hide();
  } else {
    bannerQueue.removeById(banner.id);
  }
}

