import Vue from 'vue';
import Vuex from 'vuex';

import User from '@/store/modules/user/index';
import Devices from '@/store/modules/devices/index';
import Studio from '@/store/modules/studio/index';
import Notification from '@/store/modules/notification/index';
import Settings from '@/store/modules/settings/index';
import Destinations from '@/store/modules/destinations/index';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    User,
    Devices,
    Studio,
    Notification,
    Settings,
    Destinations,
  },
});

store.withModule = (method) => (module, name, payload) => store[method](`${module}/${name}`, payload, { root: true });

store.commitWithModule = store.withModule('commit');

store.dispatchWithModule = store.withModule('dispatch');

export default store;
