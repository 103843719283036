import { ENUMS } from 'xpermeet-lib';

import store from '@/store';
import { UPDATE_USER, SET_RECORDING_STATUS, SET_REMOTE_RECORD_START_TIME } from '@/constants/mutation-types';
import { RECORDING_STATUS } from '@/constants/record';
import { STUDIO } from '@/constants/modules';
import { NOTIFICATION } from '@/constants/modules';
import { NOTIFICATION_SOUND_TYPES } from '@/constants/notification';
import { getInstance } from '@/xpermeet';

const xperMeetLib = getInstance();

export const USER_PROPERTY_CHANGE = 'UserPropertyChange';

export default function onUserPropertyChange({ userId, property, value }) {
  switch (property) {
    case 'authenticated':
      value = value === 'true';
      break;
    case 'handsUp':
      if (store.getters[`${STUDIO}/getLocalUser`].id !== userId) {
        if (value === 'true') {
          store.dispatchWithModule(NOTIFICATION, 'showRoomNotification', { text: 'handRaised', userId: userId, type: ENUMS.ROOM_NOTIFICATION_TYPES.HANDS_UP });
        } else {
          store.dispatchWithModule(NOTIFICATION, 'removeRoomNotificationByType', { type: ENUMS.ROOM_NOTIFICATION_TYPES.HANDS_UP, userId });
        }
      }
      value = value === 'true';
      break;
    case 'hasCamera':
    case 'hasMicrophone':
    case 'ARTrack':
    case 'localUserE2ee':
    case 'speakerMuted':
    case 'isMobile': {
      value = value === 'true';
      break;
    }
    case 'avatar':
      break;
    case 'localRecording':
      // TODO: yama olarak yaptık devamlı gostermesin recordd stop notificationu diye
      // false olduktan sonra lib tarafında bir de null yapıyoruz ki norificationa dusmesin
      if (value === 'true') {
        if (store.getters[`${STUDIO}/getLocalUser`].id !== userId) {
          store.dispatchWithModule(NOTIFICATION, 'showRoomNotification', { text: 'recording', type: ENUMS.ROOM_NOTIFICATION_TYPES.RECORD, userId });
        }
        store.dispatchWithModule(NOTIFICATION, 'playSound', NOTIFICATION_SOUND_TYPES.RECORDING);
      } else if (value === 'false') {
        if (store.getters[`${STUDIO}/getLocalUser`].id !== userId) {
          store.dispatchWithModule(NOTIFICATION, 'showRoomNotification', { text: 'stoppedRecording', type: ENUMS.ROOM_NOTIFICATION_TYPES.STOP_RECORD, userId });
        }
        store.dispatchWithModule(NOTIFICATION, 'playSound', NOTIFICATION_SOUND_TYPES.RECORDING);
        store.commitWithModule(STUDIO, SET_RECORDING_STATUS, RECORDING_STATUS.READY_TO_DOWNLOAD);
      }
      value = value === 'true';
      break;
    case 'start_time':
      const onRecordStartTime = xperMeetLib.conference.remoteRecordingManager.getRecordStartTime();
      store.commitWithModule(STUDIO, SET_REMOTE_RECORD_START_TIME, { timestamp: parseInt(onRecordStartTime) });
      break;
    default:
      break;
  }
  store.commitWithModule(STUDIO, UPDATE_USER, { userId, data: { [property]: value } });
}

