import { consoleError } from 'xpermeet-lib';
import store from '@/store';
import storage from '@/lib/storage';
import { isAuthenticated } from '@/helpers/keycloak';
import { ADD_USER, SET_IS_JIBRI } from '@/constants/mutation-types';
import { STUDIO, SETTINGS } from '@/constants/modules';
import { getInstance } from '@/xpermeet';

const xperMeetLib = getInstance();

/**
 * event name
 */
export const LOCAL_USER_JOINED = 'LocalUserJoined';

/**
 * local user joined event listener
 * @param {} localUser
 * @returns Promise<void>
 */
export default async function onLocalUserJoined(localUser) {
  if (localUser.isJibri) {
    store.commitWithModule(STUDIO, SET_IS_JIBRI, { isJibri: true });
    return;
  }

  let displayName = storage.getItem('displayName') || localUser.displayName;

  if (!displayName && isAuthenticated()) {
    try {
      const userInfo = await xperMeetLib.keycloakManager.keycloakInstance.loadUserInfo();
      displayName = userInfo.name;
    } catch (error) {
      consoleError(error);
    }
  }

  if (storage.getItem('startWithSpeakerMuted') === true) {
    store.dispatchWithModule(STUDIO, 'setSpeakerMute', true);
  }
  const avatar = store.state[SETTINGS]?.avatar || '';

  store.commitWithModule(STUDIO, ADD_USER, {
    user: {
      id: localUser.id,
      type: 'local',
      displayName,
      handsUp: localUser.handsUp === 'true',
      avatar,
    },
  });

  store.dispatchWithModule(STUDIO, 'setAvatar', avatar);

  store.dispatchWithModule(STUDIO, 'setDisplayName', displayName);
}

