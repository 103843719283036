import store from '@/store';
import { STUDIO } from '@/constants/modules';

export function getPictureInPictureLayoutItemPosition({ itemIndex, itemCount, isScreen, stagedScreenShareIndex }) {
  let index = itemIndex;
  let zIndex = 2;
  if (stagedScreenShareIndex > -1) {
    if (itemIndex < stagedScreenShareIndex) {
      index = itemIndex + 1;
    }
    if (isScreen) {
      index = 0;
      zIndex = 1;
    }
  }

  let itemWidth = getPictureInPictureItemWidth({ itemIndex: index, itemCount });
  let itemHeight = getPictureInPictureItemHeight({ itemIndex: index, itemCount, itemWidth });
  let left = getPictureInPictureItemLeft({ itemIndex: index, itemWidth });
  let pictureItemTop = getPictureInPictureItemTop({ itemIndex: index, itemCount, itemHeight });
  let scale = store.getters[`${STUDIO}/isAnimatedSubtitleActive`] ? 'var(--v-animated-subtitle-scale)' : '0';
  let top = `calc(${pictureItemTop}% - ${scale} * 30px)`;
  if (isScreen) {
    top = pictureItemTop;
  }
  return { left: `${left}%`, width: `${itemWidth}%`, height: `${itemHeight}%`, top: `${top}`, zIndex };
}

export function getPictureInPictureItemWidth({ itemIndex, itemCount }) {
  if (itemIndex === 0) {
    return 100;
  } else {
    if (itemCount <= 6) {
      return 18;
    } else {
      return (100 - itemCount * 1.5) / (itemCount - 1);
    }
  }
}

export function getPictureInPictureItemHeight({ itemIndex, itemCount, itemWidth }) {
  if (itemIndex === 0) {
    return 100;
  } else {
    if (itemCount <= 6) {
      return 18;
    } else {
      return itemWidth / 0.75;
    }
  }
}

export function getPictureInPictureItemTop({ itemIndex, itemHeight }) {
  if (itemIndex === 0) {
    return 0;
  } else {
    return 100 - itemHeight - 2;
  }
}

export function getPictureInPictureItemLeft({ itemIndex, itemWidth }) {
  if (itemIndex === 0) {
    return 0;
  } else {
    return 1.5 * itemIndex + (itemIndex - 1) * itemWidth;
  }
}

