<template>
  <div class="app-textfield">
    <div class="app-textfield--label mb-2" v-if="label">{{ label }}</div>
    <v-text-field outlined dense v-bind="$attrs" v-on="$listeners" :value="value" @input="$emit('input', $event)"></v-text-field>
  </div>
</template>

<script>
export default {
  name: 'AppTextField',
  props: {
    label: {
      type: String,
      default: null,
    },
    value: {
      type: [String, Number],
    },
  },
};
</script>

<style lang="scss" scoped></style>
