import store from '@/store';
import i18n from '@/plugins/i18n';
import { UPDATE_USER } from '@/constants/mutation-types';
import { STUDIO } from '@/constants/modules';
import { NOTIFICATION } from '@/constants/modules';

export const USER_DISPLAY_NAME_CHANGED = 'UserDisplayNameChanged';

export default function onUserDisplayNameChanged({ userId, displayName }) {
  const participant = store.getters[`${STUDIO}/getUserById`](userId);
  if (participant.displayName !== displayName) {
    store.dispatchWithModule(NOTIFICATION, 'showToastNotification', {
      body: i18n.t('userDisplayNameChanged', { old: participant.displayName, new: displayName }),
      translate: false,
    });
    store.commitWithModule(STUDIO, UPDATE_USER, { userId, data: { displayName } });
  }
}
