<template>
  <AppModal v-if="showLoginModal" :title="$t('inactive.loginRequired')" full-screen persistent :show-close-button="false">
    <div class="d-flex align-center justify-center">
      <div class="d-flex flex-column align-center text-center">
        <AppLogo class="my-4" :width="200" :redirectable="false" />
        <v-img data-test-id="login-image" src="@/assets/images/auth.svg" max-width="300" class="mx-auto my-8"></v-img>
        <div>{{ $t('inactive.requireLoginText') }}</div>
        <div class="d-flex mt-8">
          <div>
            <AppButton :data-test-id="loginBtn" block @click="login" color="secondary">{{ $t('login') }}</AppButton>
          </div>
        </div>
      </div>
    </div>
  </AppModal>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { USER } from '@/constants/modules';

export default {
  name: 'LoginRequiredModal',
  computed: {
    ...mapState(USER, ['showLoginModal']),
    loginBtn() {
      return 'login-button';
    },
  },
  methods: {
    ...mapActions(USER, ['login']),
  },
};
</script>
