import '@/config';
import { consoleError } from 'xpermeet-lib';
import { getInstance } from '@/xpermeet';
import { defineGlobals } from '@/helpers/define-globals';

async function init() {
  const loaderEl = document.getElementById('loader');
  const errorEl = document.getElementById('failed');

  try {
    const xpermeetLib = getInstance();
    await xpermeetLib.initKeycloakManager();

    loaderEl.style.opacity = '0';
    setTimeout(() => {
      loaderEl.style.display = 'none';
    }, 500);

    require('./main');
  } catch (err) {
    loaderEl.style.display = 'none';
    errorEl.style.display = 'flex';
    consoleError('Init XSS error: ', err);
    window.location.replace('/error.html');
  }
}

defineGlobals();
init();

