<template>
  <div class="app-menu-button">
    <AppIconButton v-if="!itemList" :icon="icon" @click="$emit('click')" :border-color="borderColor" v-bind="{ ...$attrs, ...$props }" />
    <v-menu v-else offset-y :top="!prejoin" close-on-content-click>
      <template v-slot:activator="{ on, attrs }">
        <AppIconButton :icon="icon" :border-color="borderColor" @click="$emit('click')" v-bind="{ ...attrs, ...$attrs, ...$props }" />
        <AppIcon
          :icon="attrs['aria-expanded'] === 'true' ? 'chevron-up' : 'chevron-down'"
          color="var(--v-text-gray-base)"
          width="16"
          class="mr-2 dropdown-icon"
          v-bind="{ ...attrs, ...$attrs, ...$props }"
          @click="clickEvent"
          v-on="on"
        />
      </template>
      <v-list-item-group :value="selectedItem">
        <v-list-item
          v-for="(item, index) in itemList"
          :key="index"
          @click="
            clickEvent();
            $emit('onMenuItemClicked', item);
          "
          :value="item[itemValueField]"
          dense
        >
          <v-list-item-title :class="{ ss: true }">{{ item[itemTextField] }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'AppMenuButton',
  props: {
    icon: {
      type: String,
      required: true,
    },
    itemList: {
      type: Array,
      default: null,
    },
    itemTextField: {
      type: String,
      default: 'text',
    },
    itemValueField: {
      type: String,
      default: 'value',
    },
    selectedItem: {
      type: String,
      default: null,
    },
    borderColor: {
      type: String,
      default: null,
    },
    prejoin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return { onMenuOpened: false };
  },
  methods: {
    clickEvent() {
      this.onMenuOpened = !this.onMenuOpened;
    },
  },
};
</script>
<style lang="scss" scoped>
.app-menu-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  .dropdown-icon {
    position: absolute;
    right: -24px;
    background-color: transparent !important;
    cursor: pointer;
  }
}
</style>

