import { DEFAULT_ROOM_CONFIG } from 'xpermeet-lib';

import { RECORDING_STATUS } from '@/constants/record';
import { VIDEO_QUALITY_LEVELS } from '@/constants/settings';
import { WEBINAR_CONNECTION_STATE } from '@/constants/enums';

import * as actions from './actions';
import * as getters from './getters';
import mutations from './mutations';
import { getInstance } from '@/xpermeet';
import { DEFAULT_LOGO } from '@/constants/design';
import { DEFAULT_BACKGROUND_COLOR } from '@/constants/colors';

const xperMeetLib = getInstance();

export const User = ({ id, type = 'remote', displayName, role }) => ({
  id,
  type,
  displayName: displayName || `User ${id}`,
  tracks: [],
  role: role || 'participant',
  audioMuted: false,
  audioMutedByModerator: false,
  videoMuted: true,
  videoMutedByModerator: false,
  screenSharing: false,
  hasCamera: true,
  hasMicrophone: true,
  authenticated: false,
});

export const getInitialSatate = () => ({
  password: null,
  roomConfig: DEFAULT_ROOM_CONFIG,
  roomName: null,
  meetingId: null,
  recordingStatus: xperMeetLib.conference?.remoteRecordingManager?.isActiveSessionExists() ? RECORDING_STATUS.START : RECORDING_STATUS.STOP,
  remoteRecords: [],
  remoteRecordStartedAt: null,
  xperMeetInitialized: false,
  conferenceJoined: false,
  dominantUserId: null,
  users: [],
  messages: [],
  streamMessages: [],
  myStreamMessages: {},
  waitingForTheHost: false,
  speakerMuted: false,
  allAudioMuted: false,
  allVideoMuted: false,
  createdTimestamp: null,
  isJibri: false,
  conferenceMaxUserReached: false,
  maxStagedUser: 8,
  preferredVideoQuality: VIDEO_QUALITY_LEVELS.ULTRA,
  connectionState: WEBINAR_CONNECTION_STATE.IDLE,
  webinarSessionId: null,
  webinarStreamId: null,
  session: {},
  goLiveToken: null,
  selectedSoloLayout: null,
  currentSession: null,
  viewers: [],
  currentBanners: [],
  currentBannerId: null,
  design: {
    background: null,
    studioBackgroundColor: DEFAULT_BACKGROUND_COLOR.COLOR,
    theme: null,
    hideUserName: false,
    animatedElements: {},
    logoList: [DEFAULT_LOGO],
    backgroundList: [],
    overlayList: [],
  },
});

export default {
  namespaced: true,
  state: getInitialSatate(),
  mutations,
  actions,
  getters,
};

