import { STUDIO } from '@/constants/modules';
import { SET_VIEWERS } from '@/constants/mutation-types';
import store from '@/store';

export const VIEWER_COUNT_UPDATED = 'ViewerCountUpdated';

export default async function onViewerCountUpdated(payload) {
  const { id, providerInfo, viewerCount } = JSON.parse(payload.value);

  store.commitWithModule(STUDIO, SET_VIEWERS, { id, providerInfo, viewerCount });
}
