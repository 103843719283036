import store from '@/store';
import { ADD_USER_TRACK } from '@/constants/mutation-types';
import { STUDIO } from '@/constants/modules';

/**
 * event name
 */
export const LOCAL_TRACK_ADDED = 'LocalTrackAdded';

/**
 * Local Track Added Event Handler
 */
export default function onLocalTrackAdded({ userId, track }) {
  const container = document.getElementById(`local-${track.getType()}-${userId}`);

  store.commitWithModule(STUDIO, ADD_USER_TRACK, { userId, track });
  store.dispatchWithModule(STUDIO, 'attachTrackToElement', { container, userType: 'local', userId, track });
}

