import store from '@/store';
import { STUDIO } from '@/constants/modules';
import { WEBINAR_CONNECTION_STATE } from '@/constants/enums';
import { SET_CONNECTION_STATE, SET_WEBINAR_SESSION_ID, SET_WEBINAR_STREAM_ID } from '@/constants/mutation-types';

export const WEBINAR_STOPPED = 'WebinarStopped';

export default function onWebinarStopped(response) {
  if (response.getID() === store.state[STUDIO].webinarSessionId) {
    store.commitWithModule(STUDIO, SET_CONNECTION_STATE, WEBINAR_CONNECTION_STATE.ENDED);
    store.commitWithModule(STUDIO, SET_WEBINAR_SESSION_ID, null);
    store.commitWithModule(STUDIO, SET_WEBINAR_STREAM_ID, null);
  }
}
