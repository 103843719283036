const DOMAIN = process.env.VUE_APP_MEET_HOST;

export default {
  hosts: {
    domain: DOMAIN,
    muc: `muc.${DOMAIN}`,
    authdomain: DOMAIN,
    anonymousdomain: `guest.${DOMAIN}`,
  },
  hiddenDomain: `recorder.${DOMAIN}`,
  websocket: `wss://${DOMAIN}/xmpp-websocket`,
  bosh: `wss://${DOMAIN}/xmpp-websocket`,
  clientNode: 'http://jitsi.org/jitsimeet',
  focusUserJid: `focus@auth.${DOMAIN}`,
  websocketKeepAlive: 0,
  analytics: {},
  channelLastN: -1,
  enableNoAudioDetection: true,
  enableNoisyMicDetection: true,
  enableUserRolesBasedOnToken: false,
  disableAudioLevels: false,
  resolution: 720,
  disableSimulcast: false,
  constraints: {
    video: {
      height: {
        ideal: 720,
        max: 1080,
        min: 180,
      },
      width: {
        ideal: 1280,
        max: 1920,
        min: 320,
      },
    },
  },
  desktopSharingFrameRate: {
    min: 20,
    max: 30,
  },
  testing: {
    disableE2EE: true,
    p2pTestMode: false,
    octo: {
      probability: 1,
    },
  },
  deploymentInfo: {
    region: 'region1',
    userRegion: 'region1',
  },
  isKeycloakActive: true,
};

