var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "no-data text-center py-16" },
    [
      _c("v-img", {
        staticClass: "mx-auto",
        attrs: {
          src: require("@/assets/images/no-data.svg"),
          "max-width": "300",
        },
      }),
      _c("div", { staticClass: "mt-4 text-h5" }, [
        _vm._v(_vm._s(_vm.$t("noData"))),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }