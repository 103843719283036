import { ENUMS } from 'xpermeet-lib';

import { isInStagedUsers } from '@/helpers/staged-users';
import store from '@/store';
import { SET_DOMINANT_USER_ID, REMOVE_USER } from '@/constants/mutation-types';
import { NOTIFICATION, STUDIO } from '@/constants/modules';

export const REMOTE_USER_LEFT = 'RemoteUserLeft';

export default function onRemoteUserLeft(remoteUserId) {
  if (remoteUserId === store.state[STUDIO].dominantUserId) {
    store.commitWithModule(STUDIO, SET_DOMINANT_USER_ID, { userId: null });
  }

  const userNotifications = store.state[NOTIFICATION].roomNotifications.filter(
    (notification) => notification.userId === remoteUserId && notification.type !== ENUMS.ROOM_NOTIFICATION_TYPES.FILE_SHARE,
  );

  if (userNotifications.length) {
    userNotifications.forEach((notification) => {
      store.commitWithModule(NOTIFICATION, 'REMOVE_ROOM_NOTIFICATION', notification.id);
    });
  }

  store.commitWithModule(STUDIO, REMOVE_USER, { userId: remoteUserId });

  if (isInStagedUsers(remoteUserId)) {
    store.dispatchWithModule(STUDIO, 'removeStagedUser', { userId: remoteUserId });
  }
}

