<template>
  <div class="video-card" :class="wrapperClassName" :id="wrapperId">
    <video autoplay playsinline :id="id" :class="[{ local: local, screen: isScreen }, className]"></video>
    <div v-if="!showVideoContainer" class="video-card--closed d-flex align-center justify-center">
      <AppAvatar :size="avatarSize" :src="avatarSource" />
    </div>
    <span
      class="video-card--username d-flex align-center"
      v-if="userName && !isScreen"
      :class="{ hidden: this.hideUsername, 'margin-bottom-username': isAnimatedSubtitleActive }"
      :style="displayNameStyle"
    >
      <AppIcon v-if="micDisabled" :size="iconSize" icon="mic-off" class="mr-1" />
      <span class="ellipsis" :style="`font-size: ${fontSize}px`">{{ userName }}</span>
    </span>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { SETTINGS, STUDIO } from '@/constants/modules';
import { consoleLog } from 'xpermeet-lib';

export default {
  name: 'VideoCard',
  props: {
    id: {
      type: String,
      required: true,
    },
    className: {
      type: String,
      required: false,
    },
    wrapperClassName: {
      type: String,
      required: false,
    },
    userName: {
      type: String,
      default: null,
    },
    micDisabled: {
      type: Boolean,
      default: false,
    },
    closed: {
      type: Boolean,
      default: false,
    },
    hideUsername: {
      type: Boolean,
      default: false,
    },
    local: {
      type: Boolean,
      default: false,
    },
    displayNameStyle: {
      Object,
    },
    isScreen: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
    },
    prejoinAvatar: {
      type: String,
    },
  },
  data() {
    return {
      avatarSize: 28,
      iconSize: 16,
      fontSize: 20,
    };
  },
  computed: {
    ...mapState(SETTINGS, ['avatar']),
    ...mapState(STUDIO, ['getLocalUser']),
    ...mapGetters(STUDIO, ['isAnimatedSubtitleActive']),
    avatarSource() {
      if (this.user?.id === this.getLocalUser?.id) {
        return this.prejoinAvatar || this.user?.avatar || this.avatar;
      } else {
        return this.user?.avatar;
      }
    },
    showVideoContainer() {
      return !this.closed;
    },
    wrapperId() {
      return `video-card-wrapper-${this.id}`;
    },
  },
  mounted() {
    if (window.ResizeObserver) {
      const broadcastWrapper = document.getElementById(this.wrapperId);

      const resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          const w = entry.contentRect.width;
          const h = entry.contentRect.height;
          const min = w > h ? h : w;
          this.avatarSize = min / 2;
          this.iconSize = min / 12;
          this.fontSize = min / 12;
        }
      });

      resizeObserver.observe(broadcastWrapper);
    } else {
      consoleLog('Resize observer not supported!');
    }
  },
};
</script>

<style lang="scss" scoped>
.video-card {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;

  video {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    right: 0;
    bottom: 0;

    &.local {
      transform: scaleX(-1);
    }

    &.screen {
      object-fit: fill !important;
      transform: scaleX(1) !important;
    }
  }

  &--closed {
    background-color: #c4c4c4;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  &--username {
    background-color: var(--v-black-40);
    font-weight: 500;
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 1;
    transition: opacity 1s;
    max-width: 90%;
    padding: 0rem 10px;

    &.hidden {
      opacity: 0;
      transition: opacity 0.4s;
    }
  }
  &.bottom-video-card {
    .video-card--username {
      &.margin-bottom-username {
        margin-bottom: calc(29px * var(--v-animated-subtitle-scale));
      }
    }
  }
}
</style>

