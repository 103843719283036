import { DEVICES, MEDIA_DEVICE_STATE } from '@/constants/devices';
import * as actions from './actions';
import * as getters from './getters';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    [DEVICES.kinds.MICROPHONE]: [],
    [DEVICES.kinds.CAMERA]: [],
    [DEVICES.kinds.SPEAKER]: [],
    cameraState: MEDIA_DEVICE_STATE.PROMPT,
    microphoneState: MEDIA_DEVICE_STATE.PROMPT,
    speakerState: MEDIA_DEVICE_STATE.PROMPT,
    selectedCameraId: '',
    selectedMicrophoneId: '',
    selectedSpeakerId: '',
  },
  actions,
  getters,
  mutations,
};
