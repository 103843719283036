import { getRowCount, getItemRowIndex, getColCount } from '../layout';

export function getGroupLayoutItemPosition({ itemIndex, itemCount, layout, boxWidth }) {
  const rowCount = getRowCount({ itemCount, layout });
  const colCount = getColCount({ itemCount, layout });
  const itemRowIndex = getItemRowIndex({ itemCount, itemIndex, layout });
  const videoWidth = 100 / colCount;
  const w = (boxWidth * videoWidth) / 100;
  const videoHeight = (w * 56) / 100;
  let top = 0;
  let left = 0;

  if (rowCount === 1) {
    top = videoHeight / 2;
  } else if (rowCount === 2) {
    if (colCount < 3) {
      if (itemRowIndex === 2) {
        top = videoHeight;
      }
    } else {
      if (itemRowIndex === 2) {
        top = videoHeight * 1.5;
      } else {
        top = videoHeight / 2;
      }
    }
  } else if (rowCount === 3) {
    if (itemRowIndex === 2) {
      top = videoHeight;
    } else if (itemRowIndex === 3) {
      top = videoHeight * 2;
    }
  }

  const colIndex = itemIndex % colCount;

  left = videoWidth * colIndex;

  if (itemCount === 3 && itemIndex === 2) {
    left += videoWidth / 2;
  } else if (itemCount === 5) {
    if (itemIndex === 3) {
      left += videoWidth / 2;
    } else if (itemIndex === 4) {
      left = videoWidth * 1.5;
    }
  } else if (itemCount === 7) {
    if (itemIndex === 6) {
      left += videoWidth;
    }
  } else if (itemCount === 8) {
    if (itemIndex === 6) {
      left += videoWidth / 2;
    }
    if (itemIndex === 7) {
      left = videoWidth * 1.5;
    }
  }
  const bottomVideoCard = top === videoHeight * (colCount - 1);

  return { left: `${left}%`, top: `${top}px`, width: `${videoWidth}%`, height: `${videoHeight}px`, bottomVideoCard };
}
