import { DEFAULT_LOGO_PATH, DEFAULT_OVERLAY_PATH, DEFAULT_BACKGROUND_LIST } from '@/constants/design';

export const checkIsDefaultLogoExists = (logos = []) => !!logos.find((l) => l.url === DEFAULT_LOGO_PATH);

export const checkIsDefaultOverlayExists = (overlays = []) => !!overlays.find((l) => l.url === DEFAULT_OVERLAY_PATH);

export const getBackgrounds = (logos = []) => {
  const backgrounds = structuredClone(logos);
  for (let i in DEFAULT_BACKGROUND_LIST) {
    const index = backgrounds.findIndex((background) => background.url === DEFAULT_BACKGROUND_LIST[i].url);
    if (index === -1) {
      backgrounds.push(DEFAULT_BACKGROUND_LIST[i]);
    }
  }
  return backgrounds;
};

