import { ENUMS } from 'xpermeet-lib';

import store from '@/store';
import { SET_ROOM_CONFIG } from '@/constants/mutation-types';
import { STUDIO } from '@/constants/modules';
import { getInstance } from '@/xpermeet';

const xperMeetLib = getInstance();

/**
 * event name
 */
export const ROOM_CONFIG_CHANGED = 'configParameterChanged';

/**
 * configParameterChanged Event Handler
 */
export default function onRoomConfigChanged({ key, value }) {
  const { START_MUTED_POLICY, START_VIDEO_MUTED_POLICY, ALLOW_ADMIN_START_VIDEO } = ENUMS.RoomConfigEnums;
  const newConfigObject = Object.assign({}, store.state[STUDIO].roomConfig, {
    [key]: value,
  });

  // if moderator changeroom configuration
  // clients must change instantly.
  // below code set room config on the fly...
  if (START_MUTED_POLICY === key || START_VIDEO_MUTED_POLICY === key || ALLOW_ADMIN_START_VIDEO === key) {
    // Will be remove in future, only required for jitsi mobile app.
    xperMeetLib.conference.setStartMutedPolicy({
      startMutedPolicy: newConfigObject.startMutedPolicy,
      startVideoMutedPolicy: newConfigObject.startVideoMutedPolicy,
    });
  }

  if (key === START_MUTED_POLICY) {
    xperMeetLib.conference.localUser.onAudioStartMutedPolicyChange(value);
  }

  if (key === START_VIDEO_MUTED_POLICY) {
    xperMeetLib.conference.localUser.onVideoStartMutedPolicyChange(value);
  }

  store.commitWithModule(STUDIO, SET_ROOM_CONFIG, { config: newConfigObject });
}

