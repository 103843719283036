import { DEVICES, MEDIA_DEVICE_STATE } from '@/constants/devices';

export function getMicrophones(state) {
  return state[DEVICES.kinds.MICROPHONE].filter((d) => d.deviceId);
}

export function getCameras(state) {
  return state[DEVICES.kinds.CAMERA].filter((d) => d.deviceId);
}

export function getSpeakers(state) {
  return state[DEVICES.kinds.SPEAKER]
    .map((device) => {
      return {
        ...device,
        label: device.label || 'Default Speaker',
        deviceId: device.deviceId || 'default',
        kind: DEVICES.kinds.SPEAKER,
      };
    })
    .filter((d) => d.deviceId);
}

export function getSelectedMicrophone(state, getters) {
  return getters.getMicrophones.find((d) => d.deviceId === state.selectedMicrophoneId);
}

export function getSelectedMicrophoneId(state, getters) {
  return state.selectedMicrophoneId || (getters.getMicrophones.length ? getters.getMicrophones[0].deviceId : '');
}

export function getSelectedCamera(state, getters) {
  return getters.getCameras.find((d) => d.deviceId === state.selectedCameraId);
}

export function getSelectedCameraId(state, getters) {
  return state.selectedCameraId || (getters.getCameras.length ? getters.getCameras[0].deviceId : '');
}

export function getSelectedSpeaker(state, getters) {
  return getters.getSpeakers.find((d) => d.deviceId === state.selectedSpeakerId);
}

export function getSelectedSpeakerId(state, getters) {
  return state.selectedSpeakerId || (getters.getSpeakers.length ? getters.getSpeakers[0].deviceId : '');
}

export function hasCamera(state, getters) {
  return state.cameraState !== MEDIA_DEVICE_STATE.NOT_FOUND_ERROR && !!getters.getCameras.length;
}

export function hasMicrophone(state, getters) {
  return state.microphoneState !== MEDIA_DEVICE_STATE.NOT_FOUND_ERROR && !!getters.getMicrophones.length;
}

export function hasSpeaker(state, getters) {
  return state.speakerState !== MEDIA_DEVICE_STATE.NOT_FOUND_ERROR && !!getters.getSpeakers.length;
}

export function cameraAllowed(state) {
  return state.cameraState === MEDIA_DEVICE_STATE.ALLOWED;
}

export function microphoneAllowed(state) {
  return state.microphoneState === MEDIA_DEVICE_STATE.ALLOWED;
}
