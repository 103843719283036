var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        "no-click-animation": "",
        persistent: _vm.persistent,
        "max-width": _vm.maxWidth,
        "content-class": `modal-container ${_vm.contentClass} ${
          !_vm.showActions ? "hide-actions" : ""
        } `,
        "overlay-opacity": "0.6",
        "overlay-color": "modal-overlay",
        fullscreen: _vm.fullScreen || _vm.$isMobile,
      },
      on: {
        "click:outside": function ($event) {
          return _vm.close("outside")
        },
      },
      model: {
        value: _vm.showDialog,
        callback: function ($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { elevation: "0" } },
        [
          _c(
            "div",
            { staticClass: "d-flex justify-space-between py-4 px-5" },
            [
              _c(
                "div",
                { staticClass: "d-flex align-center" },
                [
                  _vm.showBackButton
                    ? _c("AppIcon", {
                        staticClass: "cursor-pointer mr-2 opacity-50",
                        attrs: { color: "white", icon: "arrow-left" },
                        on: {
                          click: function ($event) {
                            return _vm.$emit("back")
                          },
                        },
                      })
                    : _vm._e(),
                  !_vm.standardTitle
                    ? _c("span", { staticClass: "card-title opacity-50" }, [
                        _vm._v(_vm._s(_vm.title)),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _vm.showCloseButton
                ? _c("AppIcon", {
                    staticClass: "cursor-pointer opacity-50",
                    attrs: {
                      "data-test-id": "modal-close-icon",
                      color: "white",
                      icon: "close",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.close("cancel")
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm.standardTitle
            ? _c(
                "v-card-title",
                { staticClass: "modal-title justify-center" },
                [
                  _c("div", { staticClass: "card-title opacity-50" }, [
                    _vm._v(" " + _vm._s(_vm.title) + " "),
                  ]),
                ]
              )
            : _vm._e(),
          _vm.subTitle
            ? _c("v-card-subtitle", [
                _c("div", { staticClass: "opacity-50" }, [
                  _vm._v(_vm._s(_vm.subTitle)),
                ]),
              ])
            : _vm._e(),
          _c(
            "v-card-text",
            {
              class: {
                "padding-0": _vm.paddingNone,
                "pt-0": !_vm.standardTitle,
              },
            },
            [_vm._t("default")],
            2
          ),
          _vm.showBottomDivider ? _c("v-divider") : _vm._e(),
          _vm.showActions
            ? _c("v-card-actions", [
                _c(
                  "div",
                  {
                    staticClass:
                      "w-full d-flex justify-center align-center flex-wrap-reverse mb-n2",
                  },
                  [
                    _c(
                      "app-button",
                      {
                        staticClass: "mb-2",
                        class: {
                          "mb-3": _vm.$isMobile,
                          "mx-2": !_vm.$isMobile,
                        },
                        attrs: {
                          "data-test-id": "modal-cancel-button",
                          block: _vm.$isMobile,
                          "min-width": "185",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.close("cancel")
                          },
                        },
                      },
                      [
                        _c("span", [
                          _vm._v(" " + _vm._s(_vm.cancelButtonText) + " "),
                        ]),
                      ]
                    ),
                    _c(
                      "app-button",
                      {
                        staticClass: "mb-2",
                        class: {
                          "mb-3": _vm.$isMobile,
                          "mx-2": !_vm.$isMobile,
                        },
                        attrs: {
                          "data-test-id": "modal-confirm-button",
                          block: _vm.$isMobile,
                          "min-width": "185",
                          disabled: _vm.confirmButtonDisabled,
                          color: "secondary",
                          loading: _vm.loading,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.close("confirm")
                          },
                        },
                      },
                      [
                        _c("span", [
                          _vm._v(" " + _vm._s(_vm.confirmButtonText) + " "),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }