<template>
  <v-btn
    v-bind="$attrs"
    v-on="$listeners"
    :color="backgroundColor"
    :outlined="outlined"
    min-width="initial"
    v-bind:disabled="disabled"
    class="icon-button pa-1"
    :style="iconButtonStyle"
    :loading="loading"
    :rounded="rounded || circle"
  >
    <AppIcon :icon="icon" :color="color" :size="iconSize" />
  </v-btn>
</template>

<script>
export default {
  name: 'AppIconButton',
  props: {
    width: {
      type: [Number, String],
      default: 40,
    },
    height: {
      type: [Number, String],
    },
    icon: {
      type: String,
      required: true,
    },
    iconSize: {
      type: String,
      default: '20px',
    },
    color: {
      type: String,
      default: 'white',
    },
    borderColor: {
      type: String,
      default: 'white',
    },
    circle: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    backgroundColor: {
      type: String,
      default: 'primary',
    },
  },
  data() {
    return {
      rendered: true,
    };
  },
  computed: {
    iconButtonStyle() {
      return {
        width: `${this.width}px`,
        height: this.height ? `${this.height}px` : `${this.width}px`,
        'border-color': (!this.disabled && this.borderColor) || '',
      };
    },
  },
  watch: {
    icon() {
      this.rendered = false;
      this.$nextTick(() => {
        this.rendered = true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn--disabled {
  opacity: 0.7;
}
</style>

