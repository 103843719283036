export const DEFAULT_BANNER_COLOR = {
  COLOR: 'var(--v-bg-gray-50)',
  CODE: '20202080',
};

export const DEFAULT_BACKGROUND_COLOR = {
  COLOR: 'black',
  CODE: '000000FF',
};

