import * as getters from './getters';
import * as actions from './actions';
import mutations from './mutations';
import { HOTKEYS, DEFAULT_HOTKEYS, DEFAULT_GENERAL_SETTINGS } from '@/constants/settings';
const { USE_DEFAULT_HOTKEYS } = HOTKEYS;

/**
 * Example Banner List:
 *
 * banners: [
    { name: 'Fold1', id: 1, banners: ['text', 'text'] },
    { name: 'Fold2', id: 2, banners: ['text'] },
  ],

  * logoList: [
    { url: string, id: string, actice: boolean, positionX: number, positionY: number, opacity: string }
  ]
 */

export default {
  namespaced: true,
  state: {
    defaultHotkeys: DEFAULT_HOTKEYS,
    hotkeys: DEFAULT_HOTKEYS,
    banners: [],
    logoList: [],
    backgroundList: [],
    [USE_DEFAULT_HOTKEYS]: false,
    generalSettings: DEFAULT_GENERAL_SETTINGS,
    avatar: null,
    showLogoUploadModal: false,
    showBackgroundUploadModal: false,
    showOverlayUploadModal: false,
  },
  mutations,
  actions,
  getters,
};

