import { consoleError } from 'xpermeet-lib';

import store from '@/store';
import { SET_WAITING_FOR_THE_HOST, SET_CONFERENCE_JOINED, SET_ROOM_CONFIG, UPDATE_USER } from '@/constants/mutation-types';
import { STUDIO, DEVICES } from '@/constants/modules';
import { getInstance } from '@/xpermeet';

export const CONFERENCE_JOINED = 'ConferenceJoined';
const xperMeetLib = getInstance();

export default async function onConferenceJoined() {
  // const { roomName } = store.state;

  store.commitWithModule(STUDIO, SET_WAITING_FOR_THE_HOST, { status: false });
  store.commitWithModule(STUDIO, SET_CONFERENCE_JOINED, { status: true });

  // init room config
  try {
    const roomConfig = await xperMeetLib.conference.fetchRoomConfig();
    store.commitWithModule(STUDIO, SET_ROOM_CONFIG, { config: { ...roomConfig } });
  } catch (e) {
    consoleError('Room Config Fetch Error. Somethings will be wrong');
  }

  // Webinar handle events
  store.dispatchWithModule(STUDIO, 'checkExistingWebinarStream', {
    roomName: store.state[STUDIO].roomName,
  });

  const hasMicrophone = store.getters[`${DEVICES}/hasMicrophone`] && store.getters[`${DEVICES}/microphoneAllowed`];
  const hasCamera = store.getters[`${DEVICES}/hasCamera`] && store.getters[`${DEVICES}/cameraAllowed`];

  xperMeetLib.conference.localUser.setLocalParticipantProperty('hasMicrophone', hasMicrophone.toString());
  xperMeetLib.conference.localUser.setLocalParticipantProperty('hasCamera', hasCamera.toString());

  store.commitWithModule(STUDIO, UPDATE_USER, {
    userId: store.getters[`${STUDIO}/getLocalUser`].id,
    data: { hasMicrophone: hasMicrophone, hasCamera: hasCamera },
  });
}

