var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showLoginModal
    ? _c(
        "AppModal",
        {
          attrs: {
            title: _vm.$t("inactive.loginRequired"),
            "full-screen": "",
            persistent: "",
            "show-close-button": false,
          },
        },
        [
          _c("div", { staticClass: "d-flex align-center justify-center" }, [
            _c(
              "div",
              { staticClass: "d-flex flex-column align-center text-center" },
              [
                _c("AppLogo", {
                  staticClass: "my-4",
                  attrs: { width: 200, redirectable: false },
                }),
                _c("v-img", {
                  staticClass: "mx-auto my-8",
                  attrs: {
                    "data-test-id": "login-image",
                    src: require("@/assets/images/auth.svg"),
                    "max-width": "300",
                  },
                }),
                _c("div", [
                  _vm._v(_vm._s(_vm.$t("inactive.requireLoginText"))),
                ]),
                _c("div", { staticClass: "d-flex mt-8" }, [
                  _c(
                    "div",
                    [
                      _c(
                        "AppButton",
                        {
                          attrs: {
                            "data-test-id": _vm.loginBtn,
                            block: "",
                            color: "secondary",
                          },
                          on: { click: _vm.login },
                        },
                        [_vm._v(_vm._s(_vm.$t("login")))]
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }