// Devices
export const SET_DEVICE = 'SET_DEVICE';
export const SELECT_DEVICE = 'SELECT_DEVICE';
export const SET_DEVICE_STATE = 'SET_DEVICE_STATE';
export const CLEAR_DEVICES = 'CLEAR_DEVICES';
export const SELECT_CAMERA = 'SELECT_CAMERA';
export const SELECT_MICROPHONE = 'SELECT_MICROPHONE';
export const SELECT_SPEAKER = 'SELECT_SPEAKER';

// Studio
export const ADD_MESSAGE = 'ADD_MESSAGE';
export const ADD_STREAM_MESSAGE = 'ADD_STREAM_MESSAGE';
export const ADD_USER = 'ADD_USER';
export const ADD_USER_TRACK = 'ADD_USER_TRACK';
export const REMOVE_USER = 'REMOVE_USER';
export const REMOVE_USER_TRACK = 'REMOVE_USER_TRACK';
export const SET_AUDIO_MUTE_STATE = 'SET_AUDIO_MUTE_STATE';
export const SET_CONFERENCE_CREATED_TIMESTAMP = 'SET_CONFERENCE_CREATED_TIMESTAMP';
export const SET_CONFERENCE_JOINED = 'SET_CONFERENCE_JOINED';
export const SET_CONFERENCE_MAX_USER_REACHED = 'SET_CONFERENCE_MAX_USER_REACHED';
export const SET_DOMINANT_USER_ID = 'SET_DOMINANT_USER_ID';
export const SET_INITIAL_STATE = 'SET_INITIAL_STATE';
export const SET_IS_JIBRI = 'SET_IS_JIBRI';
export const SET_ROOM_NAME = 'SET_ROOM_NAME';
export const SET_ROOM_CONFIG = 'SET_ROOM_CONFIG';
export const SET_RECORDING_STATUS = 'SET_RECORDING_STATUS';
export const SET_REMOTE_RECORD_START_TIME = 'SET_REMOTE_RECORD_START_TIME';
export const SET_VIDEO_MUTE_STATE = 'SET_VIDEO_MUTE_STATE';
export const SET_SPEAKER_MUTE_STATE = 'SET_SPEAKER_MUTE_STATE';
export const SET_WAITING_FOR_THE_HOST = 'SET_WAITING_FOR_THE_HOST';
export const SET_XPER_MEET_INITIALIZE = 'SET_XPER_MEET_INITIALIZE';
export const UPDATE_USER = 'UPDATE_USER';
export const SET_LAYOUT_TYPE = 'SET_LAYOUT_TYPE';
export const ADD_STAGED_USER = 'ADD_STAGED_USER';
export const REMOVE_STAGED_USER = 'REMOVE_STAGED_USER';
export const SET_PREFERRED_VIDEO_QUALITY = 'SET_PREFERRED_VIDEO_QUALITY';
export const SET_CONNECTION_STATE = 'SET_CONNECTION_STATE';
export const SET_MEETING_ID = 'SET_MEETING_ID';
export const SET_WEBINAR_SESSION_ID = 'SET_WEBINAR_SESSION_ID';
export const SET_WEBINAR_STREAM_ID = 'SET_WEBINAR_STREAM_ID';
export const SET_SESSION = 'SET_SESSION';
export const CLEAR_USERS = 'CLEAR_USERS';
export const SET_MY_STREAM_MESSAGES = 'SET_MY_STREAM_MESSAGES';
export const SET_MY_STREAM_MESSAGE_FROM_CLIENT = 'SET_MY_STREAM_MESSAGE_FROM_CLIENT';
export const SET_MY_STREAM_MESSAGE_FROM_PROVIDER = 'SET_MY_STREAM_MESSAGE_FROM_PROVIDER';
export const UPDATE_DUPLICATE_MESSAGE = 'UPDATE_DUPLICATE_MESSAGE';
export const SET_SOLO_LAYOUT = 'SET_SOLO_LAYOUT';
export const SET_CURRENT_SESSION = 'SET_CURRENT_SESSION';
export const SET_VIEWERS = 'SET_VIEWERS';
export const UPDATE_STREAM_MESSAGE = 'UPDATE_STREAM_MESSAGE';
export const DELETE_STREAM_MESSAGE = 'DELETE_STREAM_MESSAGE';
export const SET_CURRENT_BANNERS = 'SET_CURRENT_BANNERS';
export const SET_CURRENT_BANNER_ID = 'SET_CURRENT_BANNER_ID';
export const READ_ROOM_MESSAGES = 'READ_ROOM_MESSAGES';
export const READ_STREAM_MESSAGES = 'READ_STREAM_MESSAGES';
export const SET_DESIGN = 'SET_DESIGN';

// User
export const SET_USER = 'SET_USER';
export const SET_SHOW_LOGIN_MODAL = 'SET_SHOW_LOGIN_MODAL';

// Notifications
export const SET_NOTIFICATION_SOUND_SETTING = 'SET_NOTIFICATION_SOUND_SETTING';
export const SET_NOTIFICATION_FROM_LOCAL_STORAGE = 'SET_NOTIFICATION_FROM_LOCAL_STORAGE';
export const ADD_ROOM_NOTIFICATION = 'ADD_ROOM_NOTIFICATION';
export const READ_ROOM_NOTIFICATIONS = 'READ_ROOM_NOTIFICATIONS';
export const SET_ROOM_NOTIFICATION_HIGHLIGHT = 'SET_ROOM_NOTIFICATION_HIGHLIGHT';
export const REMOVE_ROOM_NOTIFICATION = 'REMOVE_ROOM_NOTIFICATION';
export const ADD_TOAST_NOTIFICATION = 'ADD_TOAST_NOTIFICATION';
export const REMOVE_TOAST_NOTIFICATION = 'REMOVE_TOAST_NOTIFICATION';

// Broadcast

//Settings
export const SET_USE_DEFAULT_HOTKEYS = 'SET_USE_DEFAULT_HOTKEYS';
export const SET_HOTKEYS = 'SET_HOTKEYS';
export const SET_BANNERS = 'SET_BANNERS';
export const SET_AVATAR = 'SET_AVATAR';
export const SET_GENERAL_SETTINGS = 'SET_GENERAL_SETTINGS';
export const SET_SHOW_LOGO_UPLOAD_MODAL = 'SET_SHOW_LOGO_UPLOAD_MODAL';
export const SET_SHOW_BACKGROUND_UPLOAD_MODAL = 'SET_SHOW_BACKGROUND_UPLOAD_MODAL';
export const SET_SHOW_OVERLAY_UPLOAD_MODAL = 'SET_SHOW_OVERLAY_UPLOAD_MODAL';
export const SET_LOGO_LIST = 'SET_LOGO_LIST';
export const SET_BACKGROUND_LIST = 'SET_BACKGROUND_LIST';
export const SET_OVERLAY_LIST = 'SET_OVERLAY_LIST';

// Destinations
export const SET_DESTINATIONS = 'SET_DESTINATIONS';
export const SET_DESTINATIONS_LOADING = 'SET_DESTINATIONS_LOADING';
export const DELETE_DESTINATION = 'DELETE_DESTINATION';

