import store from '@/store';
import { ADD_MESSAGE, ADD_STREAM_MESSAGE, SET_MY_STREAM_MESSAGE_FROM_PROVIDER, UPDATE_DUPLICATE_MESSAGE } from '@/constants/mutation-types';
import { STUDIO, DESTINATIONS } from '@/constants/modules';

export const ON_MESSAGE_RECEIVED = 'onMessageReceived';
export const ON_PRIVATE_MESSAGE_RECEIVED = 'onPrivateMessageReceived';

export default function onMessageReceived() {
  return (data) => {
    const [userId, message, timestamp, nickName] = data;
    const localUser = store.getters[`${STUDIO}/getLocalUser`];
    const sender = store.getters[`${STUDIO}/getUserById`](userId);
    const date = timestamp ? new Date(timestamp) : new Date();
    const hour = date.getHours();
    const minutes = `0${date.getMinutes()}`.slice(-2);
    const dateTime = Math.ceil(date.getTime() / 1000);
    try {
      const parsedMessage = JSON.parse(message);
      const moderatorDestinations = store.state[STUDIO].currentSession;
      const { providerInfo, messageText, senderId, senderName, senderAvatar, isChatOwner = false } = parsedMessage;
      const selectedSessionUserProviderId = store.getters[`${DESTINATIONS}/myDestinationProviderEntityId`](senderId, providerInfo, isChatOwner, senderName);
      let openAuthId = null;

      const streamMessagePayload = {
        id: store.state[STUDIO].streamMessages.length + 1,
        userId: null,
        senderId,
        senderAvatar,
        body: messageText,
        time: `${hour}:${minutes}`,
        streamType: providerInfo,
        type: 'stream',
        sender: senderName,
        incoming: true,
        dateTime,
        openAuthId: [],
        messageFrom: 'provider',
      };
      const messageDestination = moderatorDestinations.items.find(
        (destination) => destination.providerShortCode === providerInfo && destination.userProviderDetail.displayName === senderName,
      );
      openAuthId = messageDestination?.userProviderDetail?.oauthId;
      streamMessagePayload.openAuthId.push(openAuthId);
      const duplicatedMessage = store.getters[`${STUDIO}/findStreamMessageByKeys`](messageText, openAuthId, 'provider');
      if (duplicatedMessage) {
        if (dateTime - duplicatedMessage?.dateTime <= 30) {
          store.commitWithModule(STUDIO, UPDATE_DUPLICATE_MESSAGE, {
            message: duplicatedMessage,
            data: streamMessagePayload,
            openAuthId,
          });
          return;
        }
      }

      if (selectedSessionUserProviderId) {
        const messageWithoutSpaces = messageText.replace(/\s/g, '');
        const sendedMessageCount = store.state[STUDIO].myStreamMessages[selectedSessionUserProviderId]?.[messageWithoutSpaces];
        if (sendedMessageCount) {
          store.commitWithModule(STUDIO, SET_MY_STREAM_MESSAGE_FROM_PROVIDER, {
            message: messageText,
            selectedSessionUserProviderId,
          });
        } else {
          store.commitWithModule(STUDIO, ADD_STREAM_MESSAGE, {
            id: store.state[STUDIO].streamMessages.length + 1,
            userId: null,
            senderId,
            senderAvatar,
            body: messageText,
            time: `${hour}:${minutes}`,
            streamType: providerInfo,
            type: 'stream',
            sender: senderName,
            incoming: false,
            dateTime,
            openAuthId: [openAuthId],
            messageFrom: 'provider',
          });
        }
      } else {
        streamMessagePayload.unread = true;
        store.commitWithModule(STUDIO, ADD_STREAM_MESSAGE, streamMessagePayload);
      }
    } catch (error) {
      store.commitWithModule(STUDIO, ADD_MESSAGE, {
        userId,
        id: store.state[STUDIO].messages.length + 1,
        body: message,
        time: `${hour}:${minutes}`,
        type: 'inRoom',
        sender: nickName || sender?.displayName,
        incoming: localUser.id !== userId,
        unread: localUser.id !== userId,
      });
    }
  };
}
