import { consoleError } from 'xpermeet-lib';
import { isAuthenticated } from '@/helpers/keycloak';
import { STUDIO } from '@/constants/modules';
import store from '@/store';

export const SESSION_UPDATED = 'SessionUpdated';

export default async function onSessionUpdated() {
  let isOwner = false;
  const { sessionId } = store.state[STUDIO].currentSession;
  if (isAuthenticated()) {
    try {
      await store.dispatchWithModule(STUDIO, 'fetchSessionById', sessionId);
      isOwner = true;
    } catch (err) {
      consoleError('fetchSession: ', err);
      await fetchPublicSession(sessionId);
    }
  } else {
    await fetchPublicSession(sessionId);
  }

  return isOwner;
}

async function fetchPublicSession(sessionId) {
  try {
    store.dispatchWithModule(STUDIO, 'fetchPublicSessionById', sessionId);
  } catch (err) {
    consoleError('fetchPublicSession error: ', err);
  }
}
