export function getSpotlightLayoutItemPosition({ itemIndex, itemCount }) {
  let itemHeight = getSpotlightItemHeight({ itemIndex, itemCount });
  let itemWidth = getSpotlightItemWidth({ itemIndex, itemCount, itemHeight });
  let left = getSpotlightItemLeft({ itemIndex, itemWidth });
  let top = getSpotlightItemTop({ itemIndex, itemCount, itemHeight });
  let bottomVideoCard = false;
  if (itemCount > 3 && Math.ceil(top + itemHeight) >= 100) {
    bottomVideoCard = true;
  }
  return { left: `${left}%`, width: `${itemWidth}%`, height: `${itemHeight}%`, top: `${top}%`, bottomVideoCard };
}

export function getSpotlightItemWidth({ itemIndex, itemCount, itemHeight }) {
  if (itemIndex === 0) {
    return 78.5;
  } else {
    if (itemCount <= 5) {
      return 20;
    } else {
      return itemHeight * 0.75;
    }
  }
}

export function getSpotlightItemHeight({ itemIndex, itemCount }) {
  if (itemIndex === 0) {
    return 78.5;
  } else {
    if (itemCount <= 4) {
      return 26.666;
    } else {
      return 100 / (itemCount - 1);
    }
  }
}

export function getSpotlightItemTop({ itemIndex, itemCount, itemHeight }) {
  if (itemIndex === 0) {
    return 10.75;
  } else {
    const skipHeight = (itemCount - 1 - itemIndex) * itemHeight;
    const totalItemHeight = Math.ceil((itemCount - 1) * itemHeight);
    return (100 - totalItemHeight) / 2 + skipHeight;
  }
}

export function getSpotlightItemLeft({ itemIndex, itemWidth }) {
  if (itemIndex === 0) {
    return 0.5;
  } else {
    return 100 - itemWidth - 0.5;
  }
}

