var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-menu-button" },
    [
      !_vm.itemList
        ? _c(
            "AppIconButton",
            _vm._b(
              {
                attrs: { icon: _vm.icon, "border-color": _vm.borderColor },
                on: {
                  click: function ($event) {
                    return _vm.$emit("click")
                  },
                },
              },
              "AppIconButton",
              { ..._vm.$attrs, ..._vm.$props },
              false
            )
          )
        : _c(
            "v-menu",
            {
              attrs: {
                "offset-y": "",
                top: !_vm.prejoin,
                "close-on-content-click": "",
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on, attrs }) {
                    return [
                      _c(
                        "AppIconButton",
                        _vm._b(
                          {
                            attrs: {
                              icon: _vm.icon,
                              "border-color": _vm.borderColor,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.$emit("click")
                              },
                            },
                          },
                          "AppIconButton",
                          { ...attrs, ..._vm.$attrs, ..._vm.$props },
                          false
                        )
                      ),
                      _c(
                        "AppIcon",
                        _vm._g(
                          _vm._b(
                            {
                              staticClass: "mr-2 dropdown-icon",
                              attrs: {
                                icon:
                                  attrs["aria-expanded"] === "true"
                                    ? "chevron-up"
                                    : "chevron-down",
                                color: "var(--v-text-gray-base)",
                                width: "16",
                              },
                              on: { click: _vm.clickEvent },
                            },
                            "AppIcon",
                            { ...attrs, ..._vm.$attrs, ..._vm.$props },
                            false
                          ),
                          on
                        )
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "v-list-item-group",
                { attrs: { value: _vm.selectedItem } },
                _vm._l(_vm.itemList, function (item, index) {
                  return _c(
                    "v-list-item",
                    {
                      key: index,
                      attrs: { value: item[_vm.itemValueField], dense: "" },
                      on: {
                        click: function ($event) {
                          _vm.clickEvent()
                          _vm.$emit("onMenuItemClicked", item)
                        },
                      },
                    },
                    [
                      _c("v-list-item-title", { class: { ss: true } }, [
                        _vm._v(_vm._s(item[_vm.itemTextField])),
                      ]),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }