export function findSettingByKeys(state) {
  return ({ code, keyCode }) => {
    return state.hotkeys.find((hotkey) => hotkey.value.code === code && hotkey.value.keyCode === keyCode);
  };
}

export function findDefaultHotkeyByType(state) {
  return ({ type }) => {
    return state.defaultHotkeys.find((hotkey) => hotkey.type === type);
  };
}

export function getBannerSettings(state) {
  return (
    state.generalSettings?.banners || {
      parmanent: false,
      duration: 4,
    }
  );
}

export function getLiveCommentSettings(state) {
  return (
    state.generalSettings.liveComments || {
      parmanent: false,
      duration: 4,
    }
  );
}

export function getDisplayName(state) {
  return state.generalSettings.displayName || null;
}
