<template>
  <div class="app-input">
    <div class="app-select--label mb-2" v-if="label">{{ label }}</div>
    <v-select
      :items="items"
      :data-test-id="dataTestId"
      item-text="text"
      item-value="value"
      label="Solo field"
      outlined
      dense
      v-bind="$attrs"
      v-on="$listeners"
      :value="value"
      @input="$emit('input', $event)"
      solo
    ></v-select>
  </div>
</template>

<script>
export default {
  name: 'AppSelect',
  props: {
    label: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
      required: true,
    },
    value: {
      type: [Number, String],
    },
    dataTestId: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.app-select {
  &--label {
    font-size: 20px;
  }
}
</style>
