import { ERROR_WITH_CODES } from 'xpermeet-lib';

import router from '@/router';
import store from '@/store';
import { SET_WAITING_FOR_THE_HOST, SET_CONFERENCE_MAX_USER_REACHED } from '@/constants/mutation-types';
import { STUDIO } from '@/constants/modules';

export const CONFERENCE_FAILED = 'ConferenceFailed';

export default function onConferenceFailed(error) {
  if ([ERROR_WITH_CODES.PASSWORD_REQUIRED, ERROR_WITH_CODES.MEMBERS_ONLY].includes(error)) {
    store.commitWithModule(STUDIO, SET_WAITING_FOR_THE_HOST, { status: false });
  }

  if (error === ERROR_WITH_CODES.AUTHENTICATION_REQUIRED) {
    store.commitWithModule(STUDIO, SET_WAITING_FOR_THE_HOST, { status: true });
  }

  if (error === ERROR_WITH_CODES.ROOM_MAX_USERS_ERROR) {
    store.commitWithModule(STUDIO, SET_CONFERENCE_MAX_USER_REACHED, { isReached: true });
  }

  if (error === ERROR_WITH_CODES.CONFERENCE_ACCESS_DENIED) {
    router.push({ name: 'rejected' });
  }
}

