<template>
  <div class="app-input">
    <div class="app-input--label mb-2" v-if="label">{{ label }}</div>
    <v-text-field
      :data-test-id="dataTestId"
      ref="textField"
      outlined
      dense
      v-bind="$attrs"
      v-on="$listeners"
      :value="value"
      @input="$emit('input', $event)"
    ></v-text-field>
  </div>
</template>

<script>
export default {
  name: 'AppInput',
  props: {
    label: {
      type: String,
      default: null,
    },
    value: {
      type: [String, Number],
    },
    maxLength: {
      type: Number,
      required: false,
    },
    dataTestId: {
      type: String,
      default: null,
    },
  },
  mounted() {
    if (this.maxLength && this.maxLength > 0) {
      const inputElement = this.$refs.textField.$el.querySelector('input');
      inputElement.maxLength = this.maxLength;
    }
  },
};
</script>

<style lang="scss" scoped>
.app-input {
  &--label {
    font-size: 20px;
    font-weight: 300;
  }
}
</style>
