import axios from "axios";
import qs from "qs";
import { consoleError, consoleLog } from "../utils/logger";
import { getQueryParams } from "../helpers/url";
import { strictCheck } from "../helpers/string";
import { parseJwt } from "../helpers/jwt";

const Keycloak = window.Keycloak;

class KeycloakManager {
  constructor({ arkToken, arkResponse } = {}, xpermeet) {
    this.TOKEN_GENERATOR_ENDPOINT = window.XPER_CONFIG?.keycloakTokenGeneratorUrl;
    this.TOKEN_GENERATOR_SERVICE = window.XPER_CONFIG?.keycloakTokenGeneratorPath;
    this.MANAGE_SERVICE_ENDPOINT = window.XPER_CONFIG?.manageService;
    this.eventEmitter = xpermeet.eventEmitter;
    this.keycloakInstance = null;
    this.active = window.XPER_CONFIG?.isKeycloakActive;
    this.autoRefreshToken = true;
    this.arkToken = arkToken;
    this.arkResponse = arkResponse;
    this.config = {
      url: "",
      realm: "",
      clientId: "",
    };
  }

  getOrigin() {
    return window.location.hostname === "localhost" ? "beta.xpermeet.com" : window.location.hostname;
  }

  async createKeycloakInstance({ endpoint = this.TOKEN_GENERATOR_ENDPOINT, force = false } = {}) {
    try {
      if (this.keycloakInstance && !force) {
        return this.keycloakInstance;
      }

      const keycloakJson = await axios.get(`${endpoint}/api/keycloak.json`);
      this.config = {
        url: keycloakJson.data["auth-server-url"],
        realm: keycloakJson.data.realm,
        clientId: keycloakJson.data.resource,
      };

      const tokenResponse = await this.checkRefreshToken();
      if (tokenResponse?.access_token && tokenResponse?.refresh_token) {
        this.keycloakInstance = new KeycloakAdapter(this.createKeycloakInstance.bind(this), tokenResponse, this.config);
      } else {
        this.keycloakInstance = new Keycloak(this.config);
      }

      window.kc = this.keycloakInstance;
      this.keycloakInstance.onTokenExpired = this.onTokenExpired.bind(this);

      return this.keycloakInstance;
    } catch (e) {
      consoleError("Could not get keycloakJson!");
      return null;
    }
  }

  async checkRefreshToken() {
    try {
      const { refresh_token } = getQueryParams();
      const keycloakRefreshToken = refresh_token || localStorage.getItem("temp-keycloak-refresh-token");

      if (!keycloakRefreshToken) {
        return null;
      }

      const tokenObj = await this.manuallyRefreshToken(keycloakRefreshToken);
      if (tokenObj) {
        localStorage.setItem("temp-keycloak-refresh-token", keycloakRefreshToken);
      }

      return tokenObj;
    } catch (e) {
      consoleError("checkRefreshToken", e);
      localStorage.removeItem("temp-keycloak-refresh-token");
    }
  }

  async manuallyRefreshToken(keycloakRefreshToken) {
    try {
      const refreshToken = keycloakRefreshToken || localStorage.getItem("temp-keycloak-refresh-token");

      if (!strictCheck(refreshToken)) {
        throw new Error("manuallyRefreshToken: keycloakRefreshToken is not found");
      }

      const config = {
        method: "post",
        url: `${this.MANAGE_SERVICE_ENDPOINT}/keycloaktoken`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: qs.stringify({
          refresh_token: refreshToken,
          client_id: this.config.clientId,
          grant_type: "refresh_token",
        }),
      };

      const { data } = await axios(config);

      if (!data.access_token || !data.refresh_token) {
        throw new Error("Invalid response");
      }

      localStorage.setItem("keycloak-token", data.access_token);
      localStorage.setItem("temp-keycloak-refresh-token", data.refresh_token);

      consoleLog(`Manually Token refreshed ${new Date()}`);
      return data;
    } catch (err) {
      consoleError("Failed to manually refresh token " + new Date(), err);
      localStorage.removeItem("temp-keycloak-refresh-token");
      return null;
    }
  }

  handleAuthentication(roomName) {
    return new Promise((resolve, reject) => {
      if (!this.active) {
        return resolve();
      }

      this.getJwt(roomName)
        .then((jwt) => {
          this.eventEmitter.emit("authenticated");
          localStorage.setItem("authenticator-token", jwt);
          resolve();
        })
        .catch((e) => {
          if (e?.response?.data?.error === "Wrong user login. Forbidden") {
            reject("WrongUserLogin", e);
          } else {
            consoleError("Get JWT failed!", e);
            reject("GuestLogin");
          }
        });
    });
  }

  checkSSO(keycloak) {
    return new Promise(async (resolve, reject) => {
      try {
        keycloak
          .init({
            onLoad: "check-sso",
            checkLoginIframe: false,
          })
          .then(async (auth) => {
            consoleLog("auth", auth);
            if (!auth) {
              return resolve(auth);
            }

            if (keycloak.token && keycloak.refreshToken) {
              localStorage.setItem("keycloak-token", keycloak.token);
              localStorage.setItem("keycloak-refresh-token", keycloak.refreshToken);
            } else {
              localStorage.removeItem("keycloak-token");
              localStorage.removeItem("keycloak-refresh-token");
            }

            if (keycloak.isTokenExpired()) {
              try {
                await this.refreshToken();
              } catch (e) {
                consoleError("checkSSO error");
                reject(e);
                return;
              }
            }

            resolve(auth);
          })
          .catch((e) => {
            consoleError("checkSSO error", JSON.stringify(e));
            reject(e);
          });
      } catch (err) {
        reject(err);
      }
    });
  }

  getTokenGeneratorPath({ roomName }) {
    if (this.arkToken) {
      return `${this.TOKEN_GENERATOR_SERVICE}/${roomName}?arkToken=${this.arkToken}`;
    } else {
      return `${this.TOKEN_GENERATOR_SERVICE}/${roomName}`;
    }
  }

  async getJwt(roomName) {
    if (!this.arkToken) {
      await this.keycloakInstance.loadUserProfile();
    }

    const response = await axios.get(this.getTokenGeneratorPath({ roomName }), {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("keycloak-token")}`,
      },
    });

    return response.data.token;
  }

  onTokenExpired() {
    if (this.autoRefreshToken) {
      consoleLog("Auth token expired, refreshing " + new Date());

      if (this.keycloakInstance instanceof KeycloakAdapter) {
        return this.manuallyRefreshToken();
      } else {
        return this.refreshToken();
      }
    } else {
      consoleLog("Auth token expired");
    }
  }

  async refreshToken(minValidity = 30) {
    try {
      const refreshed = await this.keycloakInstance.updateToken(minValidity);
      if (refreshed) {
        consoleLog("token refreshed " + new Date());
        localStorage.setItem("keycloak-token", this.keycloakInstance.token);
        localStorage.setItem("keycloak-refresh-token", this.keycloakInstance.refreshToken);
        this.eventEmitter.emit("TokenRefreshed", this.keycloakInstance.token);
      } else {
        consoleLog("token not refreshed " + new Date());
        this.keycloakInstance.clearToken();
      }
    } catch (e) {
      consoleError("Failed to refresh token " + new Date(), e);
      this.keycloakInstance.clearToken();
    }
  }

  redirectLoginPage() {
    this.keycloakInstance.login();
  }

  async getUsers(params = {}) {
    try {
      const { search } = params;
      const response = await axios.get(`${this.TOKEN_GENERATOR_ENDPOINT}/api/users?search=${search}`);
      return response?.data || [];
    } catch (e) {
      consoleError(e); // eslint-disable-line
      return [];
    }
  }

  logout(config) {
    // this.keycloakManager.keycloakInstance.authenticated is false incognito tabs
    const redirectUri = config?.redirectUri || window.location.origin;
    const iframeLogout = config?.iframeLogout;

    const logoutUrl = this.keycloakInstance.createLogoutUrl({ redirectUri });

    localStorage.removeItem("keycloak-token");
    localStorage.removeItem("moderatorSecret");

    if (iframeLogout) {
      let iframe = document.createElement("iframe");
      iframe.src = logoutUrl;
      iframe.style = "display: none;";
      document.body.appendChild(iframe);
    } else {
      location.href = logoutUrl;
    }
  }
}

export class KeycloakAdapter {
  constructor(createKeycloakInstance, tokenObj, config) {
    this._authenticated = true;
    this.token = tokenObj.access_token;
    this.refreshToken = tokenObj.refresh_token;
    this.onTokenExpired = null;
    this.createKeycloakInstance = createKeycloakInstance;
    this.config = { ...config };

    this.configureExpireTimer();
  }

  get authenticated() {
    return this._authenticated;
  }

  set authenticated(value) {
    this._authenticated = value;
  }

  configureExpireTimer() {
    try {
      const { exp } = parseJwt(this.token);
      const time = exp * 1000 - new Date().getTime();

      setTimeout(async () => {
        if (this.onTokenExpired && typeof this.onTokenExpired === "function") {
          const refreshed = await this.onTokenExpired();
          if (refreshed) {
            this.setTokens();
            this.configureExpireTimer();
          } else {
            this.logout();
            this.createKeycloakInstance({ force: true });
          }
        }
      }, time);
    } catch (err) {
      consoleError("configureExpireTimer: ", err);
    }
  }

  loadUserProfile() {
    consoleLog("KeycloakAdapter::loadUserProfile");
    return this.loadUserInfo();
  }

  loadUserInfo() {
    consoleLog("KeycloakAdapter::loadUserInfo");

    return new Promise((resolve) => {
      try {
        const parsedJwt = parseJwt(this.token);
        resolve(parsedJwt);
      } catch (err) {
        resolve();
      }
    });
  }

  updateToken() {
    consoleLog("KeycloakAdapter::updateToken");
  }

  createLogoutUrl(options) {
    consoleLog("KeycloakAdapter::createLogoutUrl");
    this.logout();
    const redirectUri = options?.redirectUri || window.location.origin;
    return `${this.config.url}realms/${this.config.realm}/protocol/openid-connect/logout?redirect_uri=${encodeURIComponent(redirectUri)}`;
  }

  createLoginUrl() {
    consoleLog("KeycloakAdapter::createLoginUrl");
  }

  isTokenExpired() {
    consoleLog("KeycloakAdapter::isTokenExpired");
    const { exp } = parseJwt(this.token);
    const time = exp * 1000 - new Date().getTime();
    return time <= 0;
  }

  logout() {
    consoleLog("KeycloakAdapter::logout");
    localStorage.removeItem("keycloak-token");
    localStorage.removeItem("keycloak-refresh-token");
    localStorage.removeItem("temp-keycloak-refresh-token");
    localStorage.removeItem("moderatorSecret");
    this.token = null;
    this.refreshToken = null;
  }

  setTokens() {
    this.token = localStorage.getItem("keycloak-token");
    this.refreshToken = localStorage.getItem("keycloak-refresh-token");
  }

  init() {
    consoleLog("KeycloakAdapter::init");

    return new Promise((resolve) => {
      resolve(this.token && this.refreshToken);
    });
  }
}

export default KeycloakManager;
