import { GROUP, NEWS, SPOTLIGHT, LAYOUT_TYPES, CINEMA, SCREEN, PICTURE_IN_PICTURE } from '@/constants/layout';
import { getScreenLayoutItemPosition } from '@/helpers/layout/screen';
import { getSpotlightLayoutItemPosition } from '@/helpers/layout/spotlight';
import { getPictureInPictureLayoutItemPosition } from '@/helpers/layout/picture-in-picture';
import { getNewsLayoutItemPosition } from '@/helpers/layout/news';
import { getCinemaLayoutItemPosition } from '@/helpers/layout/cinema';
import { getCroppedLayoutItemPosition } from '@/helpers/layout/cropped';
import { getGroupLayoutItemPosition } from '@/helpers/layout/group';

export function findLayoutByName(name) {
  return LAYOUT_TYPES.find((l) => l.name === name);
}

export function getMaxRowItem({ itemCount, layout }) {
  return Math.ceil(itemCount / getRowCount({ itemCount, layout }));
}

export function getMaxColItem({ itemCount, layout }) {
  return Math.ceil(itemCount / getColCount({ itemCount, layout }));
}

export function getRowCount({ itemCount, layout }) {
  if (layout === 'group') {
    if (itemCount <= 2) {
      return 1;
    }

    if (itemCount <= 6) {
      return 2;
    }

    return 3;
  }

  return itemCount > 3 ? 2 : 1;
}

export function getItemRowIndex({ itemCount, itemIndex, layout }) {
  return Math.ceil((itemIndex + 1) / getMaxRowItem({ itemCount, layout }));
}

export function getItemColIndex({ itemCount, itemIndex, layout }) {
  return Math.ceil((itemIndex + 1) / getMaxColItem({ itemCount, layout }));
}

export function getColCount({ itemCount, layout }) {
  if (layout === 'group') {
    if (itemCount <= 2) return itemCount;
    if (itemCount <= 4) return 2;
    return 3;
  }

  if (itemCount < 4) return itemCount;

  return Math.ceil(itemCount / 2);
}

export function getItemPosition({ itemIndex, itemCount, layout, boxHeight, boxWidth, isScreen, stagedScreenShareIndex }) {
  switch (layout) {
    case GROUP.name:
      return getGroupLayoutItemPosition({ itemIndex, itemCount, layout, boxHeight, boxWidth });

    case SPOTLIGHT.name:
      return getSpotlightLayoutItemPosition({ itemIndex, itemCount });

    case NEWS.name:
      return getNewsLayoutItemPosition({ isScreen, boxWidth });

    case CINEMA.name:
      return getCinemaLayoutItemPosition();

    case SCREEN.name:
      return getScreenLayoutItemPosition({ itemIndex, itemCount, isScreen, stagedScreenShareIndex });

    case PICTURE_IN_PICTURE.name:
      return getPictureInPictureLayoutItemPosition({ itemIndex, itemCount, isScreen, stagedScreenShareIndex });

    default:
      return getCroppedLayoutItemPosition({ itemIndex, itemCount, layout });
  }
}

