var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-btn",
    _vm._b(
      { staticClass: "app-btn", on: { click: _vm.click } },
      "v-btn",
      (_vm.$props, _vm.$attrs),
      false
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }