var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "hotkey-input-wrapper d-flex align-center mt-3" },
    [
      _c("v-text-field", {
        staticClass: "hotkey-input",
        attrs: {
          readonly: "",
          color: "var(--v-secondary-lighten1)",
          "hide-details": "",
          outlined: "",
          dense: "",
          disabled: _vm.disabled,
          value: _vm.selectedKey,
          error: !_vm.selectedKey,
        },
        on: { keydown: _vm.onKeyDown },
      }),
      _c("span", { staticClass: "hotkey-input-label" }, [
        _vm._v(
          _vm._s(_vm.label) +
            " (" +
            _vm._s(
              _vm.$t("rightMenu.settings.defaultWithValue", {
                value: _vm.defaultKey,
              })
            ) +
            ")"
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }