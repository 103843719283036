import storage from '@/lib/storage';
import { DEVICES } from '@/constants/devices';
import { SET_DEVICE, SELECT_DEVICE, SET_DEVICE_STATE, CLEAR_DEVICES, SELECT_CAMERA, SELECT_MICROPHONE, SELECT_SPEAKER } from '@/constants/mutation-types';

export default {
  [SET_DEVICE](state, payload) {
    let { deviceId } = payload;
    const { kind } = payload;
    if (!state[kind].find((d) => d.deviceId === deviceId)) {
      const selectedDeviceId = storage.getItem(`selected_${kind}`);
      if (kind === DEVICES.kinds.SPEAKER && !deviceId) {
        deviceId = 'default';
      }
      let selected = false;
      if (selectedDeviceId === deviceId) {
        selected = true;
      } else if (!selectedDeviceId && deviceId === 'default') {
        selected = true;
      }
      if (selected) {
        switch (kind) {
          case DEVICES.kinds.MICROPHONE:
            state.selectedMicrophoneId = deviceId;
            break;
          case DEVICES.kinds.CAMERA:
            state.selectedCameraId = deviceId;
            break;
          case DEVICES.kinds.SPEAKER:
            state.selectedSpeakerId = deviceId;
            break;
        }
      }
      state[kind].push(payload);
    }
  },
  [SELECT_DEVICE](state, payload) {
    if (!payload) {
      return;
    }

    switch (payload.kind) {
      case DEVICES.kinds.MICROPHONE:
        state.selectedMicrophoneId = payload.deviceId;
        break;
      case DEVICES.kinds.CAMERA:
        state.selectedCameraId = payload.deviceId;
        break;
      case DEVICES.kinds.SPEAKER:
        state.selectedSpeakerId = payload.deviceId;
        break;
    }

    storage.setItem(`selected_${payload.kind}`, payload.deviceId);
  },
  [SET_DEVICE_STATE](state, payload) {
    const { type, value } = payload;
    if (type === 'camera') {
      state.cameraState = value;
    } else if (type === 'microphone') {
      state.microphoneState = value;
    }
  },
  [CLEAR_DEVICES](state) {
    state[DEVICES.kinds.MICROPHONE] = [];
    state[DEVICES.kinds.CAMERA] = [];
    state[DEVICES.kinds.SPEAKER] = [];
  },
  [SELECT_CAMERA](state, payload) {
    if (state[DEVICES.kinds.CAMERA].some((d) => d.deviceId === payload)) {
      state.selectedCameraId = payload;
      storage.setItem('selected_videoinput', payload);
    } else {
      storage.removeItem('selected_videoinput');
    }
  },
  [SELECT_MICROPHONE](state, payload) {
    if (state[DEVICES.kinds.MICROPHONE].some((d) => d.deviceId === payload)) {
      state.selectedMicrophoneId = payload;
      storage.setItem('selected_audioinput', payload);
    } else {
      storage.removeItem('selected_audioinput');
    }
  },
  [SELECT_SPEAKER](state, payload) {
    if (state[DEVICES.kinds.SPEAKER].some((d) => d.deviceId === payload)) {
      state.selectedSpeakerId = payload;
      storage.setItem('selected_audiooutput', payload);
    } else {
      storage.removeItem('selected_audiooutput');
    }
  },
};
