import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,
    options: {
      customProperties: true,
    },
    themes: {
      dark: {
        primary: '#292b35',
        secondary: '#7484FF',
        success: '#91E499',
        yellow: '#FFCD36',
        error: '#FF5A6E',
        warning: '#FFDC77',
        gray: '#7F7F7F',
        'light-gray': '#C4C4C4',
        'light-gray-2': '#7A7A7A',
        'light-gray-3': '#585454',
        'dark-gray': '#868484',
        'dark-gray-2': '#444343',
        white: '#FFFFFF',
        'night-rider': '#2b2b2b',
        'night-blue': '#222222',
        'bg-gray': '#202020',
        darkgreen: '#309393',
        cyan: '#45E4E4',
        'text-gray': '#D2D2D2',
        'milano-red': '#B1230A',
        red: '#D62323',
        'dark-green': '#334A3C',
        'light-green': '#16B813',
        'dark-blue': '#101127',
        black: '#000000',
        'popup-main': '#4E5EAF',
        'right-menu-main': '#101127',
        matterhorn: '#504E4E',

        'theme-backstage-name-bg-color': '#303030',
        'theme-dark-btn-color': '#292929',
        'theme-btn-color': '#2d2e31',
        'theme-dark': '#0F131A',
        'theme-primary': '#FF911A',
        'theme-gray': '#404040',
        'theme-light-gray': '#E0E0E0',
        'gray-91': '#E8E8E8',
      },
    },
  },
  breakpoint: {
    mobileBreakpoint: 800,
    thresholds: {
      sm: 800,
    },
  },
  icons: {
    iconfont: 'mdi', // default - only for display purposes
  },
});
