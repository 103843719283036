import { v4 as uuidv4 } from 'uuid';

export const LOGO_MAX_SIZE = 10000000;

export const BACKGROUND_MAX_SIZE = 10000000;

export const OVERLAY_MAX_SIZE = 25000000;

export const DEFAULT_LOGO_PATH = '/images/staged-logo.png';

export const DEFAULT_BACKGROUND_PATHS = [
  'https://bizbize-client.s3.eu-west-1.amazonaws.com/media/xssBackgrounds/bg-1.svg',
  'https://bizbize-client.s3.eu-west-1.amazonaws.com/media/xssBackgrounds/bg-2.svg',
  'https://bizbize-client.s3.eu-west-1.amazonaws.com/media/xssBackgrounds/bg-3.svg',
  'https://bizbize-client.s3.eu-west-1.amazonaws.com/media/xssBackgrounds/bg-4.svg',
  'https://bizbize-client.s3.eu-west-1.amazonaws.com/media/xssBackgrounds/bg-5.svg',
  'https://bizbize-client.s3.eu-west-1.amazonaws.com/media/xssBackgrounds/bg-6.svg',
  'https://bizbize-client.s3.eu-west-1.amazonaws.com/media/xssBackgrounds/bg-7.svg',
  'https://bizbize-client.s3.eu-west-1.amazonaws.com/media/xssBackgrounds/bg-8.svg',
  'https://bizbize-client.s3.eu-west-1.amazonaws.com/media/xssBackgrounds/bg-9.svg',
];

export const DEFAULT_OVERLAY_PATH = 'https://bizbize-client.s3.eu-west-1.amazonaws.com/media/xssOverlays/defaultOverlay.png';

export const DEFAULT_OVERLAY = {
  id: uuidv4(),
  url: DEFAULT_OVERLAY_PATH,
  active: false,
};

export const DEFAULT_LOGO = {
  id: uuidv4(),
  parmanent: true,
  url: DEFAULT_LOGO_PATH,
  active: true,
  size: 150,
  style: {
    opacity: 50,
  },
};

export const DEFAULT_BACKGROUND_LIST = [
  {
    id: uuidv4(),
    url: DEFAULT_BACKGROUND_PATHS[0],
    active: false,
  },
  {
    id: uuidv4(),
    url: DEFAULT_BACKGROUND_PATHS[1],
    active: false,
  },
  {
    id: uuidv4(),
    url: DEFAULT_BACKGROUND_PATHS[2],
    active: false,
  },
  {
    id: uuidv4(),
    url: DEFAULT_BACKGROUND_PATHS[3],
    active: false,
  },
  {
    id: uuidv4(),
    url: DEFAULT_BACKGROUND_PATHS[4],
    active: false,
  },
  {
    id: uuidv4(),
    url: DEFAULT_BACKGROUND_PATHS[5],
    active: false,
  },
  {
    id: uuidv4(),
    url: DEFAULT_BACKGROUND_PATHS[6],
    active: false,
  },
  {
    id: uuidv4(),
    url: DEFAULT_BACKGROUND_PATHS[7],
    active: false,
  },
  {
    id: uuidv4(),
    url: DEFAULT_BACKGROUND_PATHS[8],
    active: false,
  },
];

