import { isAuthenticated } from '@/helpers/keycloak';
import { SET_USER } from '@/constants/mutation-types';
import { getInstance } from '@/xpermeet';
import xssService from '@/services/xss.service';

const xperMeetLib = getInstance();

export function login() {
  xperMeetLib.keycloakManager.redirectLoginPage();
}

export function logout({ commit }) {
  commit(SET_USER, { user: null });
  xperMeetLib.keycloakManager.logout();
}

export async function fetchUser({ commit }) {
  if (isAuthenticated()) {
    const user = await xperMeetLib.keycloakManager.keycloakInstance.loadUserProfile();
    commit(SET_USER, { user });
  }
}

export async function deleteAccount() {
  return xssService.deleteAccount();
}

