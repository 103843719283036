import { NOTIFICATION_SOUND_TYPES } from '@/constants/notification';
import { NOTIFICATION_SETTINGS } from '@/constants/settings';
import * as actions from './actions';
import * as getters from './getters';
import mutations from './mutations';

const { SOUNDS_ON_OFF } = NOTIFICATION_SETTINGS;
const { USER_JOINED } = NOTIFICATION_SOUND_TYPES;

export const defaultNotificationSoundSettings = () => {
  return {
    [SOUNDS_ON_OFF]: true,
    [USER_JOINED]: true,
  };
};

export default {
  namespaced: true,
  state: {
    roomNotifications: [],
    roomNotificationHighlight: false,
    toastNotifications: [],
    notificationSoundSettings: defaultNotificationSoundSettings(),
  },
  mutations,
  actions,
  getters,
};
