import axios from 'axios';

import { KEYCLOAK_TOKEN } from '@/constants/token';

export default class BaseService {
  constructor(options) {
    const { baseUrl, tokenKey } = options;

    this._axios = axios.create({
      baseURL: baseUrl,
    });

    this._setupInterceptors(tokenKey);
  }

  _setupInterceptors(tokenKey = KEYCLOAK_TOKEN) {
    this._axios.interceptors.request.use((config) => {
      const token = localStorage.getItem(tokenKey);

      if (token && !config.public) {
        config.headers.common['Authorization'] = `Bearer ${token}`;
      }

      return config;
    });
  }
}
