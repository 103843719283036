export function getScreenLayoutItemPosition({ itemIndex, itemCount, isScreen, stagedScreenShareIndex }) {
  let index = itemIndex;
  if (stagedScreenShareIndex > -1) {
    if (itemIndex < stagedScreenShareIndex) {
      index = itemIndex + 1;
    }
    if (isScreen) {
      index = 0;
    }
  }

  let itemHeight = getScreenItemHeight({ itemIndex: index, itemCount });
  let itemWidth = getScreenItemWidth({ itemIndex: index, itemCount, itemHeight });
  let right = getScreenItemRight({ itemIndex: index, itemWidth });
  let top = getScreenItemTop({ itemIndex: index, itemCount, itemHeight });
  return { right: `${right}%`, width: `${itemWidth}%`, height: `${itemHeight}%`, top: `${top}%` };
}

export function getScreenItemWidth({ itemIndex, itemCount, itemHeight }) {
  if (itemIndex === 0) {
    return 78.5;
  } else {
    if (itemCount <= 5) {
      return 20;
    } else {
      return itemHeight * 0.75;
    }
  }
}

export function getScreenItemHeight({ itemIndex, itemCount }) {
  if (itemIndex === 0) {
    return 78.5;
  } else {
    if (itemCount <= 4) {
      return 26.666;
    } else {
      return 100 / (itemCount - 1);
    }
  }
}

export function getScreenItemTop({ itemIndex, itemCount, itemHeight }) {
  if (itemIndex === 0) {
    return 10.75;
  } else {
    const skipHeight = (itemCount - 1 - itemIndex) * itemHeight;
    const totalItemHeight = Math.ceil((itemCount - 1) * itemHeight);
    return (100 - totalItemHeight) / 2 + skipHeight;
  }
}

export function getScreenItemRight({ itemIndex, itemWidth }) {
  if (itemIndex === 0) {
    return 0.5;
  } else {
    return 100 - itemWidth - 0.5;
  }
}

