import store from '@/store';
import { UPDATE_USER } from '@/constants/mutation-types';
import { STUDIO } from '@/constants/modules';

export const SCREEN_SHARE_STARTED = 'ScreenShareStarted';
export const SCREEN_SHARE_STOPPED = 'ScreenShareStopped';

export default function onScreenShare({ screenSharing }) {
  return ({ userId }) => {
    store.commitWithModule(STUDIO, UPDATE_USER, { userId, data: { screenSharing } });
  };
}

