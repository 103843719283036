<template>
  <div class="hotkey-input-wrapper d-flex align-center mt-3">
    <v-text-field
      class="hotkey-input"
      readonly
      color="var(--v-secondary-lighten1)"
      hide-details
      outlined
      dense
      :disabled="disabled"
      :value="selectedKey"
      @keydown="onKeyDown"
      :error="!selectedKey"
    ></v-text-field>
    <span class="hotkey-input-label">{{ label }} ({{ $t('rightMenu.settings.defaultWithValue', { value: defaultKey }) }})</span>
  </div>
</template>
<script>
export default {
  name: 'AppHotkeySelectInput',
  props: {
    label: {
      type: String,
      default: null,
    },
    value: {
      type: [String, Number, Boolean],
    },
    defaultKey: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    hotkeysData: {
      type: Array,
    },
    type: {
      type: String,
    },
  },
  data() {
    return {
      isFocused: false,
      selectedKey: this.value,
    };
  },
  methods: {
    onKeyDown(e) {
      const anySettedKey = this.findSettingByKeys({ code: e.code, keyCode: e.keyCode });
      if (anySettedKey) {
        this.selectedKey = '';
        this.$emit('changeHotkey', { keyCode: null, code: null });
      } else {
        this.selectedKey = e.key.trim() || e.code;
        this.$emit('changeHotkey', e);
      }
    },
    findSettingByKeys({ code, keyCode }) {
      return this.hotkeysData.find((hotkey) => hotkey.value.code === code && hotkey.value.keyCode === keyCode && this.type !== hotkey.type);
    },
  },
};
</script>
<style lang="scss" scoped>
.hotkey-input-wrapper {
  ::v-deep .hotkey-input {
    caret-color: transparent !important;
    .v-input__slot {
      width: 150px;
      cursor: pointer;
      input {
        cursor: pointer;
      }
    }
  }
}
</style>
