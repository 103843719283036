import { SET_USER, SET_SHOW_LOGIN_MODAL } from '@/constants/mutation-types';

export default {
  [SET_USER](state, { user }) {
    state.user = user;
  },
  [SET_SHOW_LOGIN_MODAL](state, payload) {
    state.showLoginModal = payload;
  },
};
