import { consoleWarning } from 'xpermeet-lib';

import store from '@/store';
import { ability } from '@/lib/ability';
import { STUDIO, NOTIFICATION } from '@/constants/modules';
import { TOAST_TYPE } from '@/constants/toast';
import { SOLO, CROPPED, CINEMA, SCREEN } from '@/constants/layout';
import { findLayoutByName } from './layout';

export function getStagedUsers() {
  const { stagedUsers } = store.state[STUDIO]?.roomConfig;
  return stagedUsers;
}

export function getMaxStagedUserCount() {
  return store.state[STUDIO]?.maxStagedUser;
}

export function findStagedUserById(userId) {
  return getStagedUsers()?.find((u) => u.id === userId);
}

export function getStagedUserOrderById(userId) {
  return findStagedUserById(userId)?.order;
}

export function isInStagedUsers(userId) {
  const user = findStagedUserById(userId);
  return !!user;
}

export function isMaxStagedUsersReached() {
  const stagedUsers = getStagedUsers();
  const maxStagedUserCount = getMaxStagedUserCount();

  return stagedUsers.length >= maxStagedUserCount;
}

export function canIAddToStaged(userId) {
  if (!ability.can('addRemoveStream', 'Studio')) {
    consoleWarning('User do not have permission to edite sateged users: ', userId);
    return false;
  }

  if (isMaxStagedUsersReached()) {
    store.dispatchWithModule(NOTIFICATION, 'showToastNotification', { body: 'warning.maxStagedUserCountReached', config: { type: TOAST_TYPE.WARNING } });
    return false;
  }

  if (isInStagedUsers(userId)) {
    consoleWarning('This user is already in staged list: ', userId);
    return false;
  }

  const user = store.getters[`${STUDIO}/getUserById`](userId);
  if (!user) {
    consoleWarning('User not found for adding staged users: ', userId);
    return;
  }

  return true;
}

export function canIRemoveFromStaged(userId) {
  if (!isInStagedUsers(userId)) {
    consoleWarning('User not found in the staged list: ', userId);
    return false;
  }

  if (!ability.can('addRemoveStream', 'Studio')) {
    consoleWarning('User do not have permission to edite sateged users: ', userId);
    return false;
  }

  return true;
}

export function getNewStagedConfig(stagedUsers) {
  const layoutName = store.getters[`${STUDIO}/getLayoutName`];
  const layout = findLayoutByName(layoutName);
  const isSoloLayout = layoutName === SOLO.name;
  const size = stagedUsers.length;
  const newRoomConfig = { stagedUsers };
  const screenUser = stagedUsers.find((u) => u.isScreen);
  const stagedUser = stagedUsers.find((u) => !u.isScreen);

  if (screenUser && !layout.screen) {
    if (stagedUser) {
      newRoomConfig.layout = SCREEN;
    } else {
      newRoomConfig.layout = CINEMA;
      newRoomConfig.stagedUsers = [
        {
          ...screenUser,
          order: 0,
        },
      ];
    }
  } else if (screenUser) {
    if (size > layout.maxUser) {
      newRoomConfig.layout = SCREEN;
    }
  } else if (size === 1 && !isSoloLayout) {
    newRoomConfig.layout = SOLO;
  } else if (size > 1 && isSoloLayout) {
    newRoomConfig.layout = CROPPED;
  }

  return newRoomConfig;
}

export const reOderStagedUsers = (stagedUsers) => stagedUsers.map((u, i) => ({ ...u, order: i }));

