<template>
  <div class="toast-container">
    <transition-group name="notification" tag="div" class="toaster toaster-leftTop">
      <Toast v-for="toast in toasts['leftTop']" :key="toast.id" :toast="toast"></Toast>
    </transition-group>
    <transition-group name="notification" tag="div" class="toaster toaster-leftCenter">
      <Toast v-for="toast in toasts['leftCenter']" :key="toast.id" :toast="toast"></Toast>
    </transition-group>
    <transition-group name="notification" tag="div" class="toaster toaster-leftBottom">
      <Toast v-for="toast in toasts['leftBottom']" :key="toast.id" :toast="toast"></Toast>
    </transition-group>
    <transition-group name="notification" tag="div" class="toaster toaster-rightTop">
      <Toast v-for="toast in toasts['rightTop']" :key="toast.id" :toast="toast"></Toast>
    </transition-group>
    <transition-group name="notification" tag="div" class="toaster toaster-rightCenter">
      <Toast v-for="toast in toasts['rightCenter']" :key="toast.id" :toast="toast"></Toast>
    </transition-group>
    <transition-group name="notification" tag="div" class="toaster toaster-rightBottom">
      <Toast v-for="toast in toasts['rightBottom']" :key="toast.id" :toast="toast"></Toast>
    </transition-group>
    <transition-group name="notification" tag="div" class="toaster toaster-centerTop">
      <Toast v-for="toast in toasts['centerTop']" :key="toast.id" :toast="toast"></Toast>
    </transition-group>
    <transition-group name="notification" tag="div" class="toaster toaster-centerCenter">
      <Toast v-for="toast in toasts['centerCenter']" :key="toast.id" :toast="toast"></Toast>
    </transition-group>
    <transition-group name="notification" tag="div" class="toaster toaster-centerBottom">
      <Toast v-for="toast in toasts['centerBottom']" :key="toast.id" :toast="toast"></Toast>
    </transition-group>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { TOAST_POSITION } from '@/constants/toast';
import Toast from '@/components/Notifications/Toast/Toast';

export default {
  name: 'Toaster',
  components: { Toast },
  computed: {
    ...mapState('Notification', ['toastNotifications']),
    toasts() {
      return {
        leftTop: this.toastNotifications.filter((n) => n?.config?.position === TOAST_POSITION.LEFT_TOP),
        leftCenter: this.toastNotifications.filter((n) => n?.config?.position === TOAST_POSITION.LEFT_CENTER),
        leftBottom: this.toastNotifications.filter((n) => !n?.config?.position || n?.config?.position === TOAST_POSITION.LEFT_BOTTOM),
        rightTop: this.toastNotifications.filter((n) => n?.config?.position === TOAST_POSITION.RIGHT_TOP),
        rightCenter: this.toastNotifications.filter((n) => n?.config?.position === TOAST_POSITION.RIGHT_CENTER),
        rightBottom: this.toastNotifications.filter((n) => n?.config?.position === TOAST_POSITION.RIGHT_BOTTOM),
        centerTop: this.toastNotifications.filter((n) => n?.config?.position === TOAST_POSITION.CENTER_TOP),
        centerCenter: this.toastNotifications.filter((n) => n?.config?.position === TOAST_POSITION.CENTER_CENTER),
        centerBottom: this.toastNotifications.filter((n) => n?.config?.position === TOAST_POSITION.CENTER_BOTTOM),
      };
    },
  },
};
</script>

<style lang="scss">
.toast-container {
  $toaster-width: 360px !default;
  $toaster-offset: 10px !default;

  .toaster {
    display: block;
    position: fixed;
    width: $toaster-width;
    z-index: 9999;
    box-sizing: border-box;
    pointer-events: none;

    * {
      box-sizing: border-box;
    }
  }

  .toaster-leftTop,
  .toaster-leftCenter,
  .toaster-leftBottom {
    left: $toaster-offset;
  }

  .toaster-rightTop,
  .toaster-rightCenter,
  .toaster-rightBottom {
    right: $toaster-offset;
  }

  .toaster-centerTop,
  .toaster-centerCenter,
  .toaster-centerBottom {
    left: calc(50% - #{$toaster-width} / 2);
  }

  .toaster-leftTop,
  .toaster-centerTop,
  .toaster-rightTop {
    top: $toaster-offset;
  }

  .toaster-leftCenter,
  .toaster-rightCenter,
  .toaster-centerCenter {
    top: 50%;
    transform: translateY(-50%);
  }

  .toaster-leftBottom,
  .toaster-rightBottom,
  .toaster-centerBottom {
    bottom: $toaster-offset;
  }

  // Animations
  .notification-enter-active,
  .notification-leave-active {
    transition: all 0.5s;
  }

  .notification-enter,
  .notification-leave-to {
    opacity: 0;
  }

  .toaster-rightTop,
  .toaster-rightCenter,
  .toaster-rightBottom {
    .notification-enter,
    .notification-leave-to {
      transform: translateX(300px);
    }
  }

  .toaster-leftTop,
  .toaster-leftCenter,
  .toaster-leftBottom {
    .notification-enter,
    .notification-leave-to {
      transform: translateX(-300px);
    }
  }

  .toaster-centerTop {
    .notification-enter,
    .notification-leave-to {
      transform: translateY(-50px);
    }
  }
  .toaster-centerBottom {
    .notification-enter,
    .notification-leave-to {
      transform: translateY(50px);
    }
  }
}
</style>
