import { consoleError, consoleLog, consoleWarning, ERROR_WITH_CODES } from 'xpermeet-lib';
import { v4 as uuidv4 } from 'uuid';

import xssService from '@/services/xss.service';
import i18n from '@/plugins/i18n';
import { getLocalSelectedDevices } from '@/helpers/devices';
import { setupEventListeners } from '@/event-listeners';
import storage from '@/lib/storage';
import { ability } from '@/lib/ability';
import { RECORDING_STATUS } from '@/constants/record';
import { TOAST_TYPE } from '@/constants/toast';
import { WEBINAR_CONNECTION_STATE } from '@/constants/enums';
import { isAuthenticated } from '@/helpers/keycloak';
import { canIAddToStaged, canIRemoveFromStaged, getNewStagedConfig, reOderStagedUsers } from '@/helpers/staged-users';
import {
  ADD_STREAM_MESSAGE,
  SET_SPEAKER_MUTE_STATE,
  SET_CONFERENCE_JOINED,
  SET_INITIAL_STATE,
  SET_ROOM_NAME,
  SET_RECORDING_STATUS,
  SET_XPER_MEET_INITIALIZE,
  UPDATE_USER,
  SET_PREFERRED_VIDEO_QUALITY,
  SET_CONNECTION_STATE,
  SET_WEBINAR_SESSION_ID,
  SET_ROOM_CONFIG,
  SET_WEBINAR_STREAM_ID,
  SET_SOLO_LAYOUT,
  SET_CURRENT_SESSION,
  SET_CURRENT_BANNERS,
  SET_CURRENT_BANNER_ID,
} from '@/constants/mutation-types';
import { SOLO, CROPPED, GROUP, SPOTLIGHT, NEWS, SCREEN, CINEMA, PICTURE_IN_PICTURE } from '@/constants/layout';
import { getInstance } from '@/xpermeet';

const xperMeetLib = getInstance();

export function resetMeetState({ commit }) {
  commit(SET_XPER_MEET_INITIALIZE, { status: false });
  commit(SET_CONFERENCE_JOINED, { status: false });
  commit(SET_INITIAL_STATE);
}

export function leaveCall({ commit }) {
  xperMeetLib.leaveCall({
    callback() {
      resetMeetState({ commit });
    },
  });
}

export function destroyRoomAndLeave({ commit }) {
  xperMeetLib.destroyRoomAndLeave({
    callback() {
      resetMeetState({ commit });
    },
  });
}

export async function initXperMeet({ commit, dispatch }, roomConfig) {
  const { roomName, password, startMutedPolicyConfig, ticket, token, startWithAudioMuted, startWithVideoMuted, withoutAuth } = roomConfig;

  if (!roomName) {
    consoleError('RoomName is required');
    return;
  }

  consoleLog('Connection Initializing:', roomName);

  const deviceDeviceIds = getLocalSelectedDevices();
  commit(SET_XPER_MEET_INITIALIZE, { status: false });
  commit(SET_CONFERENCE_JOINED, { status: false });
  commit(SET_INITIAL_STATE);

  const config = {
    config: XPER_CONFIG.serverConfig,
    roomName,
    password,
    deviceDeviceIds,
    startMutedPolicyConfig,
    ticket,
    token,
    startWithAudioMuted,
    startWithVideoMuted,
    withoutAuth,
  };

  try {
    const response = await xperMeetLib.init(config);

    commit(SET_XPER_MEET_INITIALIZE, { status: true });
    commit(SET_ROOM_NAME, { roomName });

    setupEventListeners();

    dispatch('Devices/fetchDevices', {}, { root: true }).then(() => {
      dispatch('Devices/changeSpeakerById', deviceDeviceIds.audioOutput, { root: true });
    });

    if (response === 'GuestLogin') {
      consoleLog('Gues Login');
    } else if (response === 'GetJwtFailed') {
      xperMeetLib.logout({ redirectUri: window.location.href, forceLogout: true });
    }

    dispatch('Devices/handleEvents', { roomName }, { root: true });
  } catch (error) {
    consoleError(error);

    if (error === ERROR_WITH_CODES.ROOM_IS_NOT_ACTIVE) {
      dispatch('Notification/showToastNotification', 'roomIsNotActiveError', { root: true });
    }
  }
}

export function attachTrackToElement({ commit }, { container, userType, userId, track }) {
  const property = track.getType() === 'video' ? 'videoMuted' : 'audioMuted';
  commit(UPDATE_USER, { userId, data: { [property]: track.isMuted() } });
  xperMeetLib.conference.attachTrackToElement(container, userType, userId, track);
}

export function setVideoMute({ getters, rootGetters, dispatch }, muteState) {
  if (!rootGetters['Devices/cameraAllowed']) {
    const message = i18n.t('mediaPermissionDenied', { devices: 'camera' });
    dispatch('Notification/showToastNotification', { body: message, translate: false }, { root: true });
    return;
  }

  const localUser = getters.getLocalUser;

  if (localUser.videoMutedByModerator && !localUser.isModerator) {
    const message = i18n.t(!muteState ? 'videoMutedByModerator' : 'videoUnMutedByModerator', { moderatorDisplayName: localUser.videoMutedBy || 'Admin' });
    dispatch('Notification/showToastNotification', { body: message, translate: false }, { root: true });
    return;
  }

  xperMeetLib.conference.localUser.muteVideo(muteState);
}

export function setAudioMute({ getters, rootGetters, dispatch }, muteState) {
  if (!rootGetters['Devices/microphoneAllowed']) {
    const message = i18n.t('mediaPermissionDenied', { devices: 'microphone' });
    dispatch('Notification/showToastNotification', { body: message, translate: false }, { root: true });
    return;
  }

  const localUser = getters.getLocalUser;

  if (localUser.audioMutedByModerator && !localUser.isModerator) {
    const message = i18n.t(!muteState ? 'audioMutedByModerator' : 'audioUnMutedByModerator', { moderatorDisplayName: localUser.audioMutedBy || 'Admin' });
    dispatch('Notification/showToastNotification', { body: message, translate: false }, { root: true });
    return;
  }

  xperMeetLib.conference.localUser.muteAudio(muteState);
}

export function setSpeakerMute({ commit, getters }, muteState) {
  const elems = document.querySelectorAll('audio');
  [].forEach.call(elems, (elem) => {
    if (!elem.id.includes(getters.getLocalUser.id)) {
      elem.muted = muteState;
    }
  });
  commit(SET_SPEAKER_MUTE_STATE, muteState);
  xperMeetLib.conference.localUser.setSpeakerMuted(muteState);
}

export function sendMessage(_, payload) {
  const { message } = payload;
  xperMeetLib.conference.sendMessage(message);
}

export function addStreamMessage({ state, getters, commit }, payload) {
  const {
    message,
    userId = null,
    sender = null,
    senderAvatar = null,
    uuid = null,
    selectedSessionPayload = null,
    targetProviders = null,
    statusType = null,
    unclickable = false,
  } = payload;
  const user = getters.getLocalUser;
  const date = new Date();
  const hour = date.getHours();
  const minutes = `0${date.getMinutes()}`.slice(-2);
  commit(ADD_STREAM_MESSAGE, {
    id: state.streamMessages.length + 1,
    userId: user.id,
    incoming: getters.getLocalUser?.id !== userId,
    time: `${hour}:${minutes}`,
    body: message,
    sender: sender || user.displayName,
    senderAvatar,
    uuid,
    selectedSessionPayload,
    targetProviders,
    statusType,
    unclickable,
    type: 'stream',
  });
}

export function startRecording({ commit, dispatch }) {
  commit(SET_RECORDING_STATUS, RECORDING_STATUS.PENDING);
  xperMeetLib.conference.remoteRecordingManager.startRecording().catch((error) => {
    consoleError('Start Recording Error:', error);
    commit(SET_RECORDING_STATUS, RECORDING_STATUS.REMOTE_RECORD_ERROR);
    dispatch('Notification/showToastNotification', 'record.startRecordingErrorNotification', { root: true });
  });
}

export function stopRecording({ commit, dispatch }) {
  commit(SET_RECORDING_STATUS, RECORDING_STATUS.PENDING);

  xperMeetLib.conference.remoteRecordingManager.stopRecording().catch((err) => {
    commit(SET_RECORDING_STATUS, RECORDING_STATUS.STOP);
    consoleError('Stop Recording Error:', err);
    dispatch('Notification/showToastNotification', { body: 'somethingWentWrong', config: { type: TOAST_TYPE.ERROR } }, { root: true });
  });
}

export function startScreenShare({ dispatch, state }) {
  return xperMeetLib.conference.localUser.startScreenShare(state.roomName).catch((error) => {
    let payload = 'somethingWentWrong';

    if (error === ERROR_WITH_CODES.NOT_ALLOWED) {
      payload = 'screenSharingNotAllowed';
    } else if (error?.message) {
      payload = error.message;
    }

    dispatch('Notification/showToastNotification', payload, { root: true });
  });
}

export function stopScreenShare() {
  xperMeetLib.conference.localUser.stopScreenShare();
}

export function setDisplayName({ rootState, commit, getters, dispatch }, displayName) {
  const localUser = getters.getLocalUser;
  if (displayName) {
    xperMeetLib.conference.localUser.setDisplayName(displayName);
    storage.setItem('displayName', displayName);
    commit('UPDATE_USER', { userId: localUser.id, data: { displayName } });
  }
  if (isAuthenticated()) {
    const settings = structuredClone(rootState.Settings.generalSettings);
    settings.displayName = displayName;
    const payload = {
      shortCode: 'generalSettings',
      state: JSON.stringify(settings),
    };
    dispatch('Settings/setSettings', payload, { root: true });
  }
}

export function changeLayout({ commit, getters, dispatch }, { layout }) {
  storage.setItem('layout', layout);
  commit(SET_SOLO_LAYOUT, null);

  const users = getters.getUsers;
  const newRoomConfig = {
    layout,
    stagedUsers: getters.getStagedUsers,
  };

  users.forEach((user) => {
    if (user.isModerator === true) {
      if (layout.name === SOLO.name) {
        newRoomConfig.stagedUsers = [
          {
            order: user.order,
            id: user.id,
          },
        ];
      } else if (layout.name === NEWS.name) {
        const isScreenUser = users.filter((u) => u.isScreen);
        newRoomConfig.stagedUsers = [];

        newRoomConfig.stagedUsers = [
          {
            order: user.order,
            id: user.id,
          },
          {
            order: isScreenUser[0].order,
            id: isScreenUser[0].id,
            isScreen: isScreenUser[0].isScreen,
          },
        ];
      }
    }

    if (layout.name === CROPPED.name || layout.name === GROUP.name || layout.name === SPOTLIGHT.name) {
      newRoomConfig.stagedUsers = [];
      const filterUsers = users.filter((u) => !u.isScreen);

      for (let key in filterUsers) {
        const newStageUsers = {
          order: key,
          id: filterUsers[key].id,
        };
        newRoomConfig.stagedUsers.push(newStageUsers);
      }
    }

    if (user.isScreen) {
      if (layout.name === CINEMA.name) {
        const isScreenUser = users.filter((u) => u.isScreen);
        newRoomConfig.stagedUsers = [];

        newRoomConfig.stagedUsers = [
          {
            order: isScreenUser[0].order,
            id: isScreenUser[0].id,
            isScreen: isScreenUser[0].isScreen,
          },
        ];
      } else if (layout.name === SCREEN.name || layout.name === PICTURE_IN_PICTURE.name) {
        newRoomConfig.stagedUsers = [];

        for (let key in users) {
          const newStageUsers = {
            order: key,
            id: users[key].id,
            isScreen: users[key].isScreen,
          };
          newRoomConfig.stagedUsers.push(newStageUsers);
        }
      }
    }

    if (layout.maxUser < newRoomConfig.stagedUsers.length) {
      newRoomConfig.stagedUsers = newRoomConfig.stagedUsers.splice(0, layout.maxUser);
    }
  });

  newRoomConfig.stagedUsers = reOderStagedUsers(newRoomConfig.stagedUsers);
  dispatch('updateRoomConfig', newRoomConfig);
}

export function changeRoomDesign(_, design) {
  xperMeetLib.conference.roomConfig.setProperty('design', { ...design });
}

export function addStagedUser({ state, dispatch }, { userId, isScreen }) {
  if (!canIAddToStaged(userId)) {
    return;
  }

  let stagedUsers = [...state.roomConfig.stagedUsers];

  if (isScreen) {
    stagedUsers = stagedUsers
      .filter((u) => !u.isScreen)
      .map((u, i) => ({
        ...u,
        order: i,
      }));
  }

  const newStagedUsers = [
    ...stagedUsers,
    {
      isScreen,
      id: userId,
      order: stagedUsers.length,
    },
  ];

  dispatch('updateRoomConfig', getNewStagedConfig(newStagedUsers));
  consoleLog('User added to staged list: ', userId);
}

export function removeStagedUser({ state, dispatch }, { userId }) {
  if (!canIRemoveFromStaged(userId)) {
    return;
  }

  const stagedUsers = structuredClone(state.roomConfig.stagedUsers);
  const newStagedUsers = stagedUsers
    .filter((user) => user.id !== userId)
    .map((user, index) => {
      return {
        ...user,
        order: index,
      };
    });

  dispatch('updateRoomConfig', getNewStagedConfig(newStagedUsers));
  consoleLog('User deleted from staged list', userId);
}

export function swtichStagedUsersOrder({ state, commit }, payload) {
  const [id1, id2] = payload;
  const oldStagedUsers = state.roomConfig.stagedUsers;
  const order1 = oldStagedUsers.find((u) => u.id === id1)?.order;
  const order2 = oldStagedUsers.find((u) => u.id === id2)?.order;
  const stagedUsers = [...oldStagedUsers].map((u) => {
    if (u.id === id1) {
      u.order = order2;
    } else if (u.id === id2) {
      u.order = order1;
    }
    return u;
  });

  if (ability.can('addRemoveStream', 'Studio')) {
    xperMeetLib.conference.roomConfig.setProperty('stagedUsers', stagedUsers);
  }

  commit(SET_ROOM_CONFIG, {
    config: {
      ...state.roomConfig,
      stagedUsers,
    },
  });
}

export function setInitialRoomConfig({ state, dispatch }) {
  if (state.roomConfig?.layout !== undefined) {
    return;
  }

  const layout = storage.getItem('layout') || SOLO;
  if (layout) {
    dispatch('changeLayout', { layout });
  }
}

export function setPreferredVideoQuality({ commit }, payload) {
  commit(SET_PREFERRED_VIDEO_QUALITY, payload);
  xperMeetLib.conference.localUser.setReceiverVideoConstraint(payload);
  xperMeetLib.conference.localUser.setSenderVideoConstraint(payload);
}

export async function sendMeetingRequest({ dispatch }, payload) {
  try {
    await xperMeetLib.conference.localUser.sendMeetingRequest(payload);
    dispatch('Notification/showToastNotification', 'meetingRequest.sendSuccess', { root: true });
    return true;
  } catch (error) {
    dispatch('Notification/showToastNotification', { body: 'meetingRequest.sendError', config: { type: TOAST_TYPE.ERROR } }, { root: true });
    return false;
  }
}

export async function startBroadcast({ commit, state, getters }, payload) {
  const { sessionId, saveWebinarStream } = payload;

  const response = await xssService.startBroadcast({ sessionId });
  const goLivetoken = response.data.goLiveToken;

  storage.setItem('goLiveToken', goLivetoken);
  commit(SET_CONNECTION_STATE, WEBINAR_CONNECTION_STATE.PENDING);
  const streamId = uuidv4(); // This is moderatorSecret key

  localStorage.setItem('moderatorSecret', streamId);
  return xperMeetLib.conference.webinar.startWebinar(streamId, saveWebinarStream, state.meetingId, goLivetoken, true).then((res) => {
    commit(SET_WEBINAR_SESSION_ID, res.getID());

    const remoteModerators = getters.getRemoteUsers.filter((u) => u.isModerator);
    remoteModerators.forEach((u) => {
      xperMeetLib.conference.sendWebinarStreamId(streamId, u.id);
    });

    return res;
  });
}

export async function stopBroadcast({ state }) {
  const { webinarSessionId } = state;

  if (!webinarSessionId) {
    consoleError('stopBroadcast: Webinar session id not found!');
    return;
  }

  consoleLog('stopBroadcast: ', webinarSessionId);
  xperMeetLib.conference.webinar.stopWebinar(webinarSessionId);
}

export function setReceiverConstraints(_, constraints) {
  xperMeetLib.conference.setReceiverConstraints(constraints);
}

export function updateRoomConfig({ commit, state }, payload) {
  const config = {
    ...state.roomConfig,
    ...payload,
  };

  xperMeetLib.conference.roomConfig.updateDataSource(config);
  commit(SET_ROOM_CONFIG, { config });
}

export function checkExistingWebinarStream({ state, dispatch }, { roomName }) {
  if (!state.conferenceJoined) {
    return;
  }

  const streams = xperMeetLib.conference.webinar.checkExistingStream();

  if (streams && streams.length) {
    if (streams.length > 1) {
      consoleError('Multiple webinar stream found. How it is possible? Check this!');
    } else {
      try {
        dispatch('onWebinarStarted', {
          res: xperMeetLib.conference.room.recordingManager.getSession(streams[0]),
          roomName,
        });
      } catch (e) {
        consoleError('Webinar Event Handler Register Error');
      }
    }
  } else {
    localStorage.removeItem('moderatorSecret');
  }

  return streams;
}

export async function onWebinarStarted({ commit, dispatch, getters, state }, { res }) {
  if (getters.isWebinarStarted) {
    return;
  }

  commit(SET_CONNECTION_STATE, WEBINAR_CONNECTION_STATE.WATCHING);
  commit(SET_WEBINAR_SESSION_ID, res.getID());
  if (localStorage.getItem('moderatorSecret') && !state.webinarStreamId) {
    commit(SET_WEBINAR_STREAM_ID, localStorage.getItem('moderatorSecret'));
  }

  if (!xperMeetLib.conference.localUser.getUser.isJibri) {
    const message = i18n.t('studio.broadcastStarted');
    dispatch(
      'Notification/showToastNotification',
      {
        title: message,
        body: getters.webinarUrl,
        config: { icon: 'tv-2', type: TOAST_TYPE.COPY },
      },
      { root: true },
    );
  }
}

export function setRemoteAudioMute(_, { participantId, muteState }) {
  if (ability.can('muteUser', 'Studio')) {
    xperMeetLib.conference.muteRemoteAudio(participantId, muteState);
  }
}

export function setRemoteVideoMute(_, { participantId, muteState }) {
  if (ability.can('muteUser', 'Studio')) {
    xperMeetLib.conference.muteRemoteVideo(participantId, muteState);
  }
}

export function selectSoloUser({ state, commit, dispatch }, { participantId }) {
  if (!ability.can('addRemoveStream', 'Studio')) {
    consoleWarning('User do not have permission to edit sateged user: ', participantId);
    return;
  }

  const { selectedSoloLayout, roomConfig } = state;
  let newRoomConfig = {};
  let soloLayout = null;

  if (selectedSoloLayout?.soloUserId === participantId) {
    newRoomConfig = {
      stagedUsers: selectedSoloLayout.stagedUsers,
      layout: selectedSoloLayout.layout,
    };
  } else {
    newRoomConfig = {
      stagedUsers: [{ order: 0, id: participantId }],
      layout: SOLO,
    };

    soloLayout = {
      soloUserId: participantId,
      stagedUsers: roomConfig.stagedUsers,
      layout: roomConfig.layout,
    };
  }

  commit(SET_SOLO_LAYOUT, soloLayout);
  dispatch('updateRoomConfig', newRoomConfig);
}

export function sendBannerToBroadcast(_, payload) {
  xperMeetLib.conference.addBannerCommand(payload);
}

export function removeBannerFromBroadcast(_, payload) {
  xperMeetLib.conference.removeBannerCommand(payload);
}

export async function fetchSessionById({ commit }, id) {
  const { data } = await xssService.fetchSessionById(id);
  commit(SET_CURRENT_SESSION, data);
}

export function fetchSessions(_, payload) {
  if (!isAuthenticated()) {
    consoleWarning('Login required to fetch sessions');
    return;
  }

  return xssService.fetchSessions(payload.query);
}

export async function fetchPublicSessionById({ commit }, id) {
  const { data } = await xssService.fetchPublicSession(id);
  commit(SET_CURRENT_SESSION, data);
}

export function updateCurrentSession() {
  xperMeetLib.conference.sessionUpdateCommand();
}

export function onBannerChange({ commit }, payload) {
  const { items, currentItemId } = payload;
  commit(SET_CURRENT_BANNERS, items);
  commit(SET_CURRENT_BANNER_ID, currentItemId);
}

export function kickParticipant(_, participantId) {
  xperMeetLib.conference.kickParticipant(participantId);
}

export function setAvatar({ commit, getters }, avatar) {
  const localUser = getters.getLocalUser;
  if (localUser.id) {
    commit(UPDATE_USER, { userId: localUser.id, data: { avatar } });
    xperMeetLib.conference.localUser.setAvatar(avatar);
  }
}

