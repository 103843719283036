import Vue from 'vue';
import { SET_DESTINATIONS, SET_DESTINATIONS_LOADING, DELETE_DESTINATION } from '@/constants/mutation-types';

export default {
  [SET_DESTINATIONS](state, payload) {
    state.destinations = payload;
  },
  [SET_DESTINATIONS_LOADING](state, payload) {
    state.destinationsLoading = payload;
  },
  [DELETE_DESTINATION](state, destinationId) {
    const findIndex = state.destinations.findIndex((destination) => destination.id === destinationId);
    if (findIndex !== -1) {
      Vue.delete(state.destinations, findIndex);
    }
  },
};
