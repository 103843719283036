import { ERROR_WITH_CODES } from 'xpermeet-lib';

import store from '@/store';
import { RECORDING_STATUS } from '@/constants/record';
import { SET_RECORDING_STATUS } from '@/constants/mutation-types';
import { TOAST_TYPE } from '@/constants/toast';
import { STUDIO, NOTIFICATION } from '@/constants/modules';

export const REMOTE_RECORD_STOPPED = 'RemoteRecordStopped';

export default function onRemoteRecordStopped({ errorCode }) {
  const { STOP } = RECORDING_STATUS;
  if (errorCode) {
    if (errorCode === ERROR_WITH_CODES.NOT_ENOUGHT_SPACE) {
      store.dispatchWithModule(NOTIFICATION, 'showToastNotification', { body: 'record.notEnoughtSpaceError', config: { type: TOAST_TYPE.ERROR } });
    } else if (errorCode === ERROR_WITH_CODES.RECORD_FAILED_TO_START) {
      store.dispatchWithModule(NOTIFICATION, 'showToastNotification', { body: 'record.startRecordingErrorNotification', config: { type: TOAST_TYPE.ERROR } });
    }
  }
  if (store.state[STUDIO].recordingStatus === STOP) {
    store.dispatchWithModule(NOTIFICATION, 'showToastNotification', { body: 'record.stoppedRecording', config: { type: TOAST_TYPE.ERROR } });
  }

  store.commitWithModule(STUDIO, SET_RECORDING_STATUS, STOP);
}
