import { v4 as uuidv4 } from 'uuid';
import { consoleError, consoleWarning } from 'xpermeet-lib';
import { isAuthenticated } from '@/helpers/keycloak';

import i18n from '@/plugins/i18n';
import xssService from '@/services/xss.service';
import { checkIsDefaultLogoExists, getBackgrounds, checkIsDefaultOverlayExists } from '@/helpers/design';
import { TOAST_TYPE } from '@/constants/toast';
import { DEFAULT_LOGO, DEFAULT_OVERLAY } from '@/constants/design';
import {
  SET_NOTIFICATION_SOUND_SETTING,
  SET_USE_DEFAULT_HOTKEYS,
  SET_HOTKEYS,
  SET_BANNERS,
  SET_GENERAL_SETTINGS,
  SET_AVATAR,
  SET_LOGO_LIST,
  SET_BACKGROUND_LIST,
  SET_OVERLAY_LIST,
  SET_DESIGN,
} from '@/constants/mutation-types';

const getDefaultGeneralSettings = () => ({
  liveComments: {
    parmanent: false,
    duration: 4,
  },
  banners: {
    parmanent: true,
    duration: 4,
  },
});

export async function fetchSettings({ commit, dispatch }) {
  if (!isAuthenticated()) {
    consoleWarning('FetchSettings: User is not authorized');
    return;
  }

  try {
    const res = await xssService.fetchSettings();
    if (res?.data?.length) {
      let { data } = res;
      const dataObject = data.reduce((a, v) => {
        try {
          return { ...a, [v.shortCode]: JSON.parse(v.state) };
        } catch (error) {
          dispatch('setSettings', {
            shortCode: v.shortCode,
            state: JSON.stringify(''),
          });
        }
      }, {});

      if (dataObject?.notifications) {
        commit(`Notification/${SET_NOTIFICATION_SOUND_SETTING}`, dataObject.notifications, { root: true });
      }

      if (dataObject?.hotkeys) {
        const { useDefaultsHotkeys, hotkeys } = dataObject?.hotkeys;
        commit(SET_USE_DEFAULT_HOTKEYS, useDefaultsHotkeys);
        commit(SET_HOTKEYS, hotkeys);
      }

      if (dataObject?.banners) {
        commit(SET_BANNERS, dataObject.banners);
      }

      dispatch('addDefaultLogo', dataObject?.logoList);

      dispatch('addDefaultBackgrounds', dataObject?.backgroundList);

      dispatch('addDefaultOverlays', dataObject?.overlayList);

      const avatar = dataObject?.avatar || null;
      commit(SET_AVATAR, avatar);

      if (dataObject?.generalSettings) {
        commit(SET_GENERAL_SETTINGS, Object.assign(getDefaultGeneralSettings(), dataObject.generalSettings));
      }
    }
  } catch (err) {
    consoleError('Fetch Settings Error: ', err);
    dispatch(
      'Notification/showToastNotification',
      { body: i18n.t('error.couldNotFetch', { name: i18n.t('rightMenu.settings.text') }), translate: false, config: { type: TOAST_TYPE.ERROR } },
      { root: true },
    );
  }
}

export function addDefaultLogo({ commit, dispatch }, payload = []) {
  let logos = structuredClone(payload);

  if (!checkIsDefaultLogoExists(payload)) {
    logos = [
      DEFAULT_LOGO,
      ...logos.map((l) => ({
        ...l,
        parmanent: false,
      })),
    ];
  }

  commit(SET_LOGO_LIST, logos);
  dispatch('setSettings', {
    shortCode: 'logoList',
    state: JSON.stringify(logos),
  });
}

export function addDefaultOverlays({ dispatch }, payload = []) {
  let overlays = structuredClone(payload);
  if (!checkIsDefaultOverlayExists(payload)) {
    overlays = [
      DEFAULT_OVERLAY,
      ...overlays.map((l) => ({
        ...l,
        parmanent: false,
      })),
    ];
  }
  dispatch('setOverlayList', overlays);
}

export async function addDefaultBackgrounds({ dispatch }, payload = []) {
  let backgrounds = getBackgrounds(payload);
  await dispatch('setBackgroundList', backgrounds);
}

export async function addLogo({ state, dispatch }, payload) {
  const { url } = payload;

  try {
    const data = {
      shortCode: 'logoList',
      state: JSON.stringify([
        ...state.logoList,
        {
          id: uuidv4(),
          url,
          active: false,
          size: '10%',
          style: {
            opacity: `50%`,
          },
        },
      ]),
    };

    await dispatch('setSettings', data);
    await dispatch('fetchSettings');
  } catch (error) {
    console.log(error);
  }
}

export async function addBackground({ state, dispatch }, payload) {
  const { url } = payload;

  try {
    const data = {
      shortCode: 'backgroundList',
      state: JSON.stringify([
        ...state.backgroundList,
        {
          id: uuidv4(),
          url,
          active: false,
          addedByModerator: true,
        },
      ]),
    };

    await dispatch('setSettings', data);
    await dispatch('fetchSettings');
  } catch (error) {
    console.log(error);
  }
}

export async function addOverlay({ state, dispatch }, payload) {
  const { url } = payload;

  try {
    const data = {
      shortCode: 'overlayList',
      state: JSON.stringify([
        ...state.overlayList,
        {
          id: uuidv4(),
          url,
          active: false,
          addedByModerator: true,
        },
      ]),
    };

    await dispatch('setSettings', data);
    await dispatch('fetchSettings');
  } catch (error) {
    console.log(error);
  }
}

export async function updateLogo({ commit, dispatch, state }, payload) {
  const { id, partialData } = payload;
  const logos = structuredClone(state.logoList);
  const index = logos.findIndex((l) => l.id === id);

  if (!logos[index]) {
    consoleWarning(`Logo is not found. index: ${index}`);
    return;
  }

  if (partialData.style) {
    logos[index] = {
      ...logos[index],
      ...partialData,
      style: {
        ...logos[index].style,
        ...partialData.style,
      },
    };
  } else {
    logos[index] = {
      ...logos[index],
      ...partialData,
    };
  }

  commit(SET_LOGO_LIST, logos);
  dispatch('setSettings', {
    shortCode: 'logoList',
    state: JSON.stringify(logos),
  });

  return logos;
}

export async function setSettings(_, payload) {
  try {
    await xssService.setSettings(payload);
    return true;
  } catch (err) {
    consoleError('Save Settings Error: ', err);
  }
}

export async function deleteLogo({ state, dispatch }, payload) {
  const { id } = payload;
  const logoList = structuredClone(state.logoList).filter((l) => l.id !== id);

  await dispatch('setSettings', {
    shortCode: 'logoList',
    state: JSON.stringify(logoList),
  });
  await dispatch('fetchSettings');
}

export async function toggleLogo({ state, commit, dispatch }, payload) {
  const { id } = payload;
  const logoList = structuredClone(state.logoList);
  const index = logoList.findIndex((l) => l.id === id);
  logoList[index].active = !logoList[index].active;

  await dispatch('setSettings', {
    shortCode: 'logoList',
    state: JSON.stringify(logoList),
  });

  commit(SET_LOGO_LIST, logoList);

  return logoList;
}

export async function toggleBackground({ state, dispatch }, payload) {
  const { id } = payload;
  const backgroundList = structuredClone(
    state.backgroundList.map((background) => {
      const active = background.id === id ? background.active : false;
      return {
        ...background,
        active,
      };
    }),
  );
  const index = backgroundList.findIndex((l) => l.id === id);
  backgroundList[index].active = !backgroundList[index].active;

  await dispatch('setBackgroundList', backgroundList);

  return backgroundList;
}

export async function toggleOverlay({ state, dispatch }, payload) {
  const { id } = payload;
  const overlayList = structuredClone(
    state.overlayList.map((overlay) => {
      const active = overlay.id === id ? overlay.active : false;
      return {
        ...overlay,
        active,
      };
    }),
  );
  const index = overlayList.findIndex((l) => l.id === id);
  overlayList[index].active = !overlayList[index].active;

  await dispatch('setOverlayList', overlayList);

  return overlayList;
}

export async function setBackgroundsPassive({ state, dispatch }) {
  const backgroundList = structuredClone(
    state.backgroundList.map((background) => {
      return {
        ...background,
        active: false,
      };
    }),
  );

  await dispatch('setBackgroundList', backgroundList);
  return backgroundList;
}
export async function deleteBackground({ state, dispatch }, payload) {
  const { id } = payload;
  const backgroundList = structuredClone(state.backgroundList).filter((l) => l.id !== id);

  await dispatch('setBackgroundList', backgroundList);
  await dispatch('fetchSettings');
}

export async function deleteOverlay({ state, dispatch }, payload) {
  const { id } = payload;
  const overlayList = structuredClone(state.overlayList).filter((l) => l.id !== id);

  await dispatch('setOverlayList', overlayList);
  await dispatch('fetchSettings');
}

export async function setBackgroundList({ commit, dispatch }, payload) {
  await dispatch('setSettings', {
    shortCode: 'backgroundList',
    state: JSON.stringify(payload),
  });

  commit(SET_BACKGROUND_LIST, payload);
  commit(`Studio/${SET_DESIGN}`, { type: 'backgroundList', value: payload }, { root: true });
}

export async function setOverlayList({ commit, dispatch }, payload) {
  await dispatch('setSettings', {
    shortCode: 'overlayList',
    state: JSON.stringify(payload),
  });

  commit(SET_OVERLAY_LIST, payload);
  commit(`Studio/${SET_DESIGN}`, { type: 'overlayList', value: payload }, { root: true });
}

