import store from '@/store';
import { RECORDING_STATUS } from '@/constants/record';
import { SET_RECORDING_STATUS, SET_REMOTE_RECORD_START_TIME } from '@/constants/mutation-types';
import { STUDIO, NOTIFICATION } from '@/constants/modules';
import { TOAST_TYPE } from '@/constants/toast';

import { getInstance } from '@/xpermeet';

const xperMeetLib = getInstance();

export const REMOTE_RECORD_STARTED = 'RemoteRecordStarted';

export default function onRemoteRecordStarted(showIsRecording) {
  const { REMOTE_RECORD, STOP } = RECORDING_STATUS;
  const status = showIsRecording ? REMOTE_RECORD : STOP;

  if (store.state[STUDIO].recordingStatus !== status) {
    if (showIsRecording) {
      store.dispatchWithModule(NOTIFICATION, 'showToastNotification', {
        body: 'record.startRecordingNotification',
        config: { type: TOAST_TYPE.INFO },
      });
    }
    store.commitWithModule(STUDIO, SET_RECORDING_STATUS, status);
    const onRecordStartTime = xperMeetLib.conference.remoteRecordingManager.getRecordStartTime();
    store.commitWithModule(STUDIO, SET_REMOTE_RECORD_START_TIME, { timestamp: parseInt(onRecordStartTime) });
  }
}