import { getRowCount, getItemRowIndex, getColCount } from '../layout';

export function getCroppedLayoutItemPosition({ itemIndex, itemCount, layout }) {
  const rowCount = getRowCount({ itemCount });
  const itemRowIndex = getItemRowIndex({ itemCount, itemIndex });
  const top = itemRowIndex > 1 ? 100 / itemRowIndex : 0;
  const videoHeight = 100 / rowCount;
  const videoWidth = 100 / getColCount({ itemCount, layout });
  const bottomVideoCard = videoHeight + top === 100;
  let left = 0;

  if (itemIndex !== 0 || itemRowIndex === 2) {
    if (itemRowIndex === 1) {
      left = videoWidth * itemIndex;
    } else {
      if (itemCount % 2 !== 0) {
        left = videoWidth * (itemIndex - Math.ceil(itemCount / 2)) + videoWidth / 2;
      } else {
        left = videoWidth * (itemIndex - Math.ceil(itemCount / 2));
      }
    }
  }

  return { left: `${left}%`, top: `${top}%`, width: `${videoWidth}%`, height: `${videoHeight}%`, bottomVideoCard };
}

