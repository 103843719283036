var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c("v-main", { staticClass: "pt-0" }, [_c("router-view")], 1),
      _c("toaster"),
      _c("LoginRequiredModal"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }