var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "toast",
      class: [`toast--${_vm.config.type}`],
      style: _vm.computedStyle,
      on: { click: _vm.onClick },
    },
    [
      _vm.icon
        ? _c(
            "div",
            { staticClass: "toast__icon ml-1 mr-4" },
            [
              _c("AppIcon", {
                staticClass: "mt-2",
                attrs: { icon: _vm.icon, size: "20" },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "flex-grow-1 d-flex justify-center flex-column" },
        [
          _vm.toast.title
            ? _c("div", { staticClass: "toast__title" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.toast.translate
                        ? _vm.$t(_vm.toast.title)
                        : _vm.toast.title
                    ) +
                    " "
                ),
              ])
            : _vm._e(),
          _vm.toast.body
            ? _c(
                "div",
                { staticClass: "toast__body" },
                [
                  _vm.config.type === _vm.TOAST_TYPE.COPY
                    ? [_c("ToastCopy", { attrs: { toast: _vm.toast } })]
                    : [
                        _c("div", [
                          _vm._v(
                            _vm._s(
                              _vm.toast.translate
                                ? _vm.$t(_vm.toast.body)
                                : _vm.toast.body
                            )
                          ),
                        ]),
                        _vm.hasButton
                          ? _c(
                              "div",
                              { staticClass: "d-flex mt-2" },
                              _vm._l(_vm.buttons, function (button, key) {
                                return _c(
                                  "button",
                                  {
                                    key: key,
                                    staticClass: "prompt-button mx-1",
                                    class: {
                                      "prompt-button--bold": button.bold,
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.onButtonClick(button)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.toast.translate
                                            ? _vm.$t(button.text)
                                            : button.text
                                        ) +
                                        " "
                                    ),
                                  ]
                                )
                              }),
                              0
                            )
                          : _vm._e(),
                      ],
                ],
                2
              )
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }