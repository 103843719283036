import * as actions from './actions';
import * as getters from './getters';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    user: null,
    showLoginModal: false,
  },
  actions,
  getters,
  mutations,
};

