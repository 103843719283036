import axios from "axios";

class TicketManager {
  constructor() {
    this.TOKEN_GENERATOR_ENDPOINT = window.XPER_CONFIG?.keycloakTokenGeneratorUrl;
  }

  verify({ token, roomName }) {
    return axios.post(`${this.TOKEN_GENERATOR_ENDPOINT}/createroom/verify`, { token, roomName });
  }
}

export default new TicketManager();
