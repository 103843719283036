const INITIAL_OBJECT = {};

class Storage {
  constructor() {
    this.storageName = 'xperstream';
    this.storage = window.localStorage;
    const data = this.storage.getItem(this.storageName);
    this.data = data ? JSON.parse(data) : INITIAL_OBJECT;
    this.flush();
  }

  getItem(key) {
    return this.data[key];
  }

  setItem(key, value) {
    this.data[key] = value;
    this.flush();
  }

  removeItem(key) {
    delete this.data[key];
    this.flush();
  }

  flush() {
    this.storage.setItem(this.storageName, JSON.stringify(this.data));
  }
}

export default new Storage();
