<template>
  <v-avatar class="avatar-wrapper" :color="color" :size="getSize" v-bind="$props" :data-test-id="dataTestId">
    <img class="avatar-image" v-if="src" :src="src" :alt="text" referrerpolicy="no-referrer" />
    <span class="avatar-text" v-else-if="getText" :style="`color: ${textColor}`">{{ getText }}</span>
    <div v-else>
      <AppIcon :icon="getIcon" :size="getIconSize" />
    </div>
  </v-avatar>
</template>

<script>
import { mapActions } from 'vuex';
import storage from '@/lib/storage';
import { STUDIO } from '@/constants/modules';

export default {
  name: 'AppAvatar',
  props: {
    color: {
      type: String,
      default: '#444343',
    },
    size: {
      type: [Number, String],
      default: 30,
    },
    src: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    textColor: {
      type: String,
      default: 'white',
    },
    dataTestId: {
      type: String,
      default: null,
    },
  },
  computed: {
    getSize() {
      if (this.size.toString().endsWith('px')) {
        return Number(this.size.replace('px', '')) / 2;
      } else {
        return this.size;
      }
    },
    getIcon() {
      return this.icon || 'user';
    },
    getIconSize() {
      return this.getSize / 2;
    },
    getText() {
      if (!this.text) return null;

      return this.text
        .split(' ')
        .map((t) => t.charAt(0).toUpperCase())
        .join('');
    },
  },
  created() {
    if (storage.getItem('avatar')) {
      this.setAvatar(storage.getItem('avatar'));
    }
  },
  methods: {
    ...mapActions(STUDIO, ['setAvatar']),
  },
};
</script>
<style lang="scss" scoped>
.avatar-wrapper {
  .avatar-text {
    font-size: 9px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .avatar-image {
    object-fit: cover;
  }
}
</style>
