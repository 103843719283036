import { ENUMS } from 'xpermeet-lib';

export const { RoomConfigEnums } = ENUMS;

export const BROADCAST_FILTERS = {
  UPCOMING: 'ready',
  LIVE: 'live',
  PAST: 'finished',
};

export const UI_ERROR_CODES = {
  UNSUPPORTED_DEVICE: 1,
};

export const DESTINATION_TYPES = {
  ALL: 'All',
  YOUTUBE: 'Youtube',
  TWITCH: 'Twitch',
};

export const YOUTUBE_BROADCAST_PRIVACY_TYPES = {
  PUBLIC: 'Public',
  UNLISTED: 'Unlisted',
  PRIVATE: 'Private',
};

export const OS = {
  ANDROID: 1,
  IOS: 2,
  MAC: 3,
  UNKNOWN: 4,
  WINDOWS: 5,
};

export const RIGHT_MENU_ITEMS = {
  MESSAGES: 'messages',
  BANNERS: 'banners',
  SOUNDS: 'sounds',
  DESIGN: 'design',
  SETTINGS: 'settings',
};

export const BROADCAST_STASUSES = {
  READY: 'ready',
  LIVE: 'live',
  FINISHED: 'finished',
};

export const WEBINAR_CONNECTION_STATE = {
  IDLE: 1,
  PENDING: 2,
  WATCHING: 3,
  ENDED: 4,
};

export const SOCKET_CONNECTION_STATUS = {
  IDLE: 0,
  CONNECTING: 1,
  CONNECTED: 2,
  CLOSED: 3,
};

