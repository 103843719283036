import Vue from 'vue';

import storage from '@/lib/storage';
import {
  SET_NOTIFICATION_SOUND_SETTING,
  SET_NOTIFICATION_FROM_LOCAL_STORAGE,
  ADD_ROOM_NOTIFICATION,
  READ_ROOM_NOTIFICATIONS,
  SET_ROOM_NOTIFICATION_HIGHLIGHT,
  REMOVE_ROOM_NOTIFICATION,
  ADD_TOAST_NOTIFICATION,
  REMOVE_TOAST_NOTIFICATION,
} from '@/constants/mutation-types';
import { defaultNotificationSoundSettings } from '.';

export default {
  [SET_NOTIFICATION_SOUND_SETTING](state, payload) {
    state.notificationSoundSettings = { ...payload };
    // storage.setItem('notificationSoundSettings', state.notificationSoundSettings);
  },
  [SET_NOTIFICATION_FROM_LOCAL_STORAGE](state) {
    const notificationSoundSettings = storage.getItem('notificationSoundSettings');
    if (notificationSoundSettings) {
      state.notificationSoundSettings = { ...defaultNotificationSoundSettings(), ...notificationSoundSettings };
    }
  },
  [ADD_ROOM_NOTIFICATION](state, payload) {
    state.roomNotifications.unshift(payload);
    state.roomNotificationHighlight = true;
  },
  [READ_ROOM_NOTIFICATIONS](state) {
    const notifications = state.roomNotifications.map((notification) => {
      return {
        ...notification,
        read: true,
      };
    });
    Vue.set(state, 'roomNotifications', notifications);
  },
  [SET_ROOM_NOTIFICATION_HIGHLIGHT](state, payload) {
    state.roomNotificationHighlight = payload;
  },
  [REMOVE_ROOM_NOTIFICATION](state, payload) {
    const findIndex = state.roomNotifications.findIndex((n) => n.id === payload);
    if (findIndex > -1) {
      Vue.delete(state.roomNotifications, findIndex);
    }
    if (!state.roomNotifications.length || !state.roomNotifications.some((notification) => !notification.read)) {
      state.roomNotificationHighlight = false;
    }
  },
  [ADD_TOAST_NOTIFICATION](state, payload) {
    state.toastNotifications.push(payload);
  },
  [REMOVE_TOAST_NOTIFICATION](state, payload) {
    const findIndex = state.toastNotifications.findIndex((n) => n.id === payload);
    if (findIndex > -1) {
      Vue.delete(state.toastNotifications, findIndex);
    }
  },
};
