import axios from "axios";

export default class DialInManager {
  get(roomName) {
    let numberListUrl = `${window.XPER_CONFIG?.sip?.numberListUrl}?conference=${roomName}@${window.XPER_CONFIG?.sip?.muc}`;
    let conferenceMapper = `${window.XPER_CONFIG?.sip?.conferenceMapper}?conference=${roomName}@${window.XPER_CONFIG?.sip?.muc}`;
    return new Promise(async (resolve, reject) => {
      try {
        const numberListResponse = await axios.get(numberListUrl);
        if (numberListResponse?.data) {
          const conferenceMapperResponse = await axios.get(conferenceMapper);
          if (conferenceMapperResponse?.data) {
            return resolve({ numberList: numberListResponse.data, conferenceMapper: conferenceMapperResponse.data });
          }
        } else {
          return reject();
        }
      } catch (e) {
        return reject();
      }
    });
  }
}
