import { consoleLog } from "../utils/logger";
import { ErrorWithCodes } from "../errors/errors";
import ENUMS from "../constants/enums";

/**
 * Remote record session ID. We must keep this data for knowing who started
 * current remote session. If session Id is equal to the incoming event session,
 * means this user started a remote record
 */
const recordSessionID = {
  field: "remote_session_id",
  get() {
    return localStorage.getItem(this.field);
  },
  set(id) {
    localStorage.setItem(this.field, id);
  },
  delete() {
    localStorage.removeItem(this.field);
  },
};

export default class RemoteRecordingManager {
  constructor(conference) {
    this.conference = conference;
    this.eventEmitter = conference.eventEmitter;
  }

  handleEvents() {
    recordSessionID.delete();
    this.conference.room.on(this.conference.libJitsi.events.conference.RECORDER_STATE_CHANGED, this.onRecorderStateChanged.bind(this));
  }

  getRecordStartTime() {
    const participants = this.conference.room.getParticipants();
    const jibri = participants.find((p) => p.getStatsID() === "jibri" && p.isHidden());
    return jibri?.getProperty("start_time") || null;
  }

  onRecorderStateChanged(response) {
    consoleLog("RECORDER_STATE_CHANGED", response);

    const mode = response.getMode();
    const status = response.getStatus();
    const id = response.getID();
    const error = response.getError();

    const isFile = mode === "file";
    const isOn = status === "on";
    const isOff = status === "off";

    const showIsRecording = !recordSessionID.get() || recordSessionID.get() === id;
    if (isFile && isOn) {
      this.eventEmitter.emit("RemoteRecordStarted", showIsRecording);
      recordSessionID.set(id);
    } else if (isFile && isOff) {
      let errorCode;
      /**
       * if error object is string 'undefined', that means
       * "there is not enough space in xperdrive for this user"
       */
      if (error) {
        errorCode = error === "undefined" ? ErrorWithCodes.NOT_ENOUGHT_SPACE : ErrorWithCodes.RECORD_FAILED_TO_START;
        this.conference.roomConfig.setProperty(ENUMS.RoomConfigEnums.REMOTE_RECORD_STARTED_BY, null);
        recordSessionID.delete();
      }
      if (showIsRecording) {
        this.eventEmitter.emit("RemoteRecordStopped", { errorCode });
      }
    }
  }

  startRecording() {
    const baseUrl = window.location.hostname === "localhost" ? process.env.VUE_APP_DEV_ENV_URL : window.location.origin;
    const appData = JSON.stringify({
      file_recording_metadata: {
        hostName: localStorage.getItem("keycloak-token"),
        baseUrl,
      },
    });

    return new Promise((resolve, reject) => {
      this.conference.room
        .startRecording({
          mode: "file",
          appData,
        })
        .then((response) => {
          const keycloakId = this.conference.room.getLocalParticipantProperty("KeycloakId");
          this.conference.roomConfig.setProperty(ENUMS.RoomConfigEnums.REMOTE_RECORD_STARTED_BY, keycloakId);
          recordSessionID.set(response.getID());
          resolve();
        })
        .catch(reject);
    });
  }

  stopRecording() {
    return new Promise((resolve, reject) => {
      const sessionID = recordSessionID.get();
      const session = this.getSession(sessionID);

      if (!session) {
        return reject(new Error("Could not find session"));
      }

      session
        .stop({
          focusMucJid: this.conference.room.recordingManager._chatRoom.focusMucJid,
        })
        .then(() => {
          resolve();
          recordSessionID.delete();
          this.conference.roomConfig.setProperty(ENUMS.RoomConfigEnums.REMOTE_RECORD_STARTED_BY, null);
        })
        .catch(reject);
    });
  }

  getSession(sessionID) {
    return this.conference.room.recordingManager._sessions[sessionID];
  }

  getActiveSessions() {
    if (!this.conference.room) return [];

    const sessions = this.conference.room.recordingManager._sessions;
    const result = [];

    Object.values(sessions).forEach((session) => {
      if (session._status === "on") {
        result.push(session);
      }
    });

    return result;
  }

  isActiveSessionExists() {
    return this.getActiveSessions().length > 0;
  }
}
