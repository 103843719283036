import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '@/store';
import { isAuthenticated } from '@/helpers/keycloak';
import { SET_SHOW_LOGIN_MODAL } from '@/constants/mutation-types';
import { USER } from '@/constants/modules';

Vue.use(VueRouter);

const routes = [
  {
    name: 'Home',
    path: '/',
    redirect: '/dashboard/broadcasts',
  },
  {
    name: 'Dashboard',
    path: '/dashboard',
    redirect: '/dashboard/broadcasts',
    component: () => import('@/views/Dashboard/Dashboard.vue'),
    children: [
      {
        name: 'Broadcasts',
        path: 'broadcasts',
        component: () => import('@/views/Dashboard/Broadcasts.vue'),
      },
      {
        name: 'Videos',
        path: 'videos',
        component: () => import('@/views/Dashboard/Videos.vue'),
      },
      {
        name: 'VideoDetail',
        path: 'video-detail',
        component: () => import('@/views/Dashboard/VideoDetail.vue'),
      },
      {
        name: 'Destinations',
        path: 'destinations',
        component: () => import('@/views/Dashboard/Destinations.vue'),
      },
      {
        name: 'Usage',
        path: 'usage',
        component: () => import('@/views/Dashboard/Usage.vue'),
      },
      {
        name: 'DeleteAccount',
        path: 'account/delete',
        component: () => import('@/views/Dashboard/DeleteAccount.vue'),
      },
    ],
  },
  {
    name: 'Logout',
    path: '/logout',
    component: () => import('@/views/Logout.vue'),
  },
  {
    name: 'Ended',
    path: '/ended',
    component: () => import('@/views/Ended.vue'),
    meta: {
      public: true,
    },
  },
  {
    name: 'Prejoin',
    path: '/prejoin/:sessionId',
    component: () => import('@/views/Prejoin.vue'),
    meta: {
      public: true,
    },
  },
  {
    name: 'Jibri',
    path: '/jibri/:sessionId',
    component: () => import('@/views/Jibri.vue'),
    meta: {
      public: true,
    },
  },
  {
    name: 'Error',
    path: '/error/:code?',
    component: () => import('@/views/Error.vue'),
  },
  {
    name: 'Studio',
    path: '/studio/:sessionId',
    component: () => import('@/views/Studio.vue'),
    meta: {
      public: true,
    },
  },
  {
    name: 'NotFound',
    path: '*',
    component: () => import('@/views/NotFound.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const isLoginRequired = !isAuthenticated() && !to?.meta?.public;
  store.commitWithModule(USER, SET_SHOW_LOGIN_MODAL, isLoginRequired);

  return next();
});

export default router;

