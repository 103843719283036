import store from '@/store';
import { REMOVE_USER_TRACK } from '@/constants/mutation-types';
import { STUDIO } from '@/constants/modules';

/**
 * Event name
 */
export const REMOTE_TRACK_REMOVED = 'RemoteTrackRemoved';

/**
 * onRemoteTrackAdded event handler
 * @param {*} participantId
 * @param {*} track
 */
export default function onRemoteTrackRemoved({ userId, track }) {
  store.commitWithModule(STUDIO, REMOVE_USER_TRACK, { userId, track });
}

