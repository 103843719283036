<template>
  <div
    class="d-flex align-center app-logo"
    :class="{ 'cursor-pointer': redirectable }"
    draggable
    :style="`width: ${width}`"
    @click="handleRedirect"
    @dragend="onLogoDragEnd"
    @dragstart="onDragStart"
  >
    <span v-if="showMobileLogo">Xper Stream Studio</span>
    <div v-else class="app-logo-wrapper">
      <v-img alt="Vuetify Logo" contain :src="imageUrl" :data-test-id="dataTestId" transition="scale-transition" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { consoleError } from 'xpermeet-lib';

import { SETTINGS, STUDIO } from '@/constants/modules';
import { SET_DESIGN } from '@/constants/mutation-types';

let offsetX;
let offsetY;

export default {
  name: 'AppLogo',
  props: {
    mobile: {
      type: Boolean,
      default: false,
    },
    src: {
      type: String,
      default: null,
    },
    width: {
      type: String,
      default: '90px',
    },
    logoId: {
      type: String,
      required: false,
    },
    redirectable: {
      type: Boolean,
      default: true,
    },
    draggable: {
      type: Boolean,
      default: false,
    },
    dataTestId: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapState(STUDIO, ['design']),
    showMobileLogo() {
      return this.mobile && this.$vuetify.breakpoint.smAndDown;
    },
    imageUrl() {
      return this.src || '/images/logo.png';
    },
  },
  methods: {
    ...mapActions(SETTINGS, ['updateLogo']),
    ...mapActions(STUDIO, ['changeRoomDesign']),
    ...mapMutations(STUDIO, [SET_DESIGN]),
    handleRedirect() {
      if (this.$route.name !== 'Broadcasts' && this.redirectable) {
        this.$router.push('/dashboard');
      }
    },
    async onLogoDragEnd(e) {
      try {
        const wrapper = document.getElementById('broadcast');
        const srcEl = e.srcElement;
        const maxH = wrapper.clientHeight - srcEl.clientHeight;
        const maxW = wrapper.clientWidth - srcEl.clientWidth;
        let left = e.clientX - wrapper.offsetLeft - offsetX;
        let top = e.clientY - wrapper.offsetTop - offsetY;

        if (left < 0) left = 0;
        if (left > maxW) left = maxW;
        if (top < 0) top = 0;
        if (top > maxH) top = maxH;

        // convert percent
        top = (100 / wrapper.clientHeight) * top;
        left = (100 / wrapper.clientWidth) * left;

        const newLogoList = await this.updateLogo({
          id: this.logoId,
          partialData: {
            style: {
              top: `${top}%`,
              left: `${left}%`,
            },
          },
        });

        this[SET_DESIGN]({ type: 'logoList', newLogoList });
        this.changeRoomDesign({
          ...this.design,
          logoList: newLogoList,
        });
      } catch (err) {
        consoleError('onLogoDragEnd::', e);
      }
    },
    onDragStart(e) {
      const rect = e.target.getBoundingClientRect();
      offsetX = e.clientX - rect.x;
      offsetY = e.clientY - rect.y;
      this.$emit('dragStart');
    },
  },
};
</script>

<style lang="scss">
.app-logo {
  width: 10%;

  .app-logo-wrapper {
    max-width: 100%;
  }
}
</style>

