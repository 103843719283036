import serverConfig from './server.config';

const config = {
  serverConfig,
  keycloakTokenGeneratorUrl: process.env.VUE_APP_KEYCLOAK_TOKEN_GENERATOR_URL,
  keycloakTokenGeneratorPath: `${process.env.VUE_APP_KEYCLOAK_TOKEN_GENERATOR_URL}/${process.env.VUE_APP_KEYCLOAK_TOKEN_GENERATOR_PATH}`,
  manageService: process.env.VUE_APP_MANAGE_SERVICE_URL,
  mailServer: process.env.VUE_APP_COMMUNICATION_SERVER,
  roomNameService: process.env.VUE_APP_KEYCLOAK_TOKEN_GENERATOR_URL,
  mailServerTenant: process.env.VUE_APP_COMMUNICATION_SERVER_TENANT,
  isKeycloakActive: true,
};

window.XPER_CONFIG = config;

