const { freeze } = Object;

/**
 * Layout Types
 */
export const SOLO = freeze({ name: 'solo', icon: 'user-full-color', value: 0, maxUser: 1, draggable: false, iconSize: 30 });
export const CROPPED = freeze({ name: 'cropped', icon: 'cropped-layout', value: 1, maxUser: 9, draggable: true, iconSize: 45 });
export const GROUP = freeze({ name: 'group', icon: 'group-layout', value: 2, maxUser: 9, draggable: true, iconSize: 45 });
export const SPOTLIGHT = freeze({ name: 'spotlight', icon: 'spotlight-layout', value: 3, maxUser: 9, draggable: true, iconSize: 40 });
export const NEWS = freeze({ name: 'news', icon: 'news-layout', screen: true, maxUser: 2, draggable: false, iconSize: 45 });
export const SCREEN = freeze({ name: 'screen', icon: 'screen-layout', screen: true, maxUser: 9, draggable: true, iconSize: 45 });
export const CINEMA = freeze({ name: 'cinema', icon: 'cinema-layout', screen: true, maxUser: 1, draggable: false, iconSize: 45 });
export const PICTURE_IN_PICTURE = freeze({
  name: 'pictureInPicture',
  icon: 'picture-in-picture-layout',
  screen: true,
  maxUser: 9,
  draggable: true,
  iconSize: 45,
});
export const THEATER = freeze({ name: 'theater', icon: 'theater-layout', iconSize: 30 });
export const TABLE = freeze({ name: 'table', icon: 'table-layout', iconSize: 30 });
export const STORY_TELLER = freeze({ name: 'storyTeller', icon: 'story-teller-layout', iconSize: 30 });
export const LAYOUT_TYPES = [SOLO, CROPPED, GROUP, SPOTLIGHT, NEWS, SCREEN, CINEMA, PICTURE_IN_PICTURE];

