<template>
  <div class="text-center">
    <v-pagination v-model="value" v-bind="{ ...$props, ...$attrs }"></v-pagination>
  </div>
</template>

<script>
export default {
  name: 'AppPagination',
  props: {
    page: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      value: this.page,
    };
  },
  watch: {
    page() {
      if (this.value !== this.page) {
        this.value = this.page;
      }
    },
    value() {
      this.$emit('change', this.value);
    },
  },
};
</script>
