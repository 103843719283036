<template>
  <v-text-field background-color="var(--v-primary-lighten1)" readonly outlined hide-details :value="text" dense class="copy-link-wrapper">
    <template v-slot:append>
      <div class="cursor-pointer" v-clipboard:copy="copyText" v-clipboard:success="onCopy" v-clipboard:error="onError">
        <AppIcon size="24" color="var(--v-white-base)" :icon="copyIcon" />
      </div>
    </template>
  </v-text-field>
</template>

<script>
import { mapActions } from 'vuex';
import { NOTIFICATION } from '@/constants/modules';
import { TOAST_TYPE } from '@/constants/toast';

export default {
  name: 'AppCopyLink',
  props: {
    text: {
      type: String,
    },
    copyText: {
      type: String,
    },
  },
  data() {
    return {
      copyIcon: 'copy',
    };
  },
  methods: {
    ...mapActions(NOTIFICATION, ['showToastNotification']),
    onCopy() {
      this.copyIcon = 'check-1';
      setTimeout(() => {
        this.copyIcon = 'copy';
      }, 2000);
    },
    onError() {
      this.showToastNotification({ body: 'error.failedToCopyText', config: { type: TOAST_TYPE.WARNING } });
    },
  },
};
</script>
<style lang="scss" scoped>
.copy-link-wrapper {
  ::v-deep .v-input__slot {
    &::before {
      border-color: transparent !important;
    }
  }
}
</style>
