<template>
  <v-btn class="app-btn" v-bind="($props, $attrs)" @click="click"><slot></slot></v-btn>
</template>
<script>
export default {
  name: 'AppButton',
  methods: {
    click() {
      this.$emit('click');
    },
  },
};
</script>
<style lang="scss" scoped>
.app-btn {
  border-radius: 4px;
  ::v-deep.v-btn__content {
    text-transform: none;
    font-size: 11px;
    font-weight: 400;
  }
  &.night-rider {
    color: var(--v-text-gray-base);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  }
}
</style>

