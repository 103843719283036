import { consoleLog } from 'xpermeet-lib';

export const BANNER_CALLBACK_TYPES = {
  show: 'showCallbacks',
  hide: 'hideCallbacks',
  change: 'changeCallbacks',
};

class Banner {
  constructor() {
    this.items = [];
    this.timeOut = null;
    this.currentItemId = null;
    this.duration = 4000;
    this.showCallbacks = [];
    this.hideCallbacks = [];
    this.changeCallbacks = [];
  }

  addCallback(type, callback) {
    if (typeof callback === 'function') {
      if (BANNER_CALLBACK_TYPES[type]) {
        this[BANNER_CALLBACK_TYPES[type]].push(callback);

        consoleLog('Banner Callback Added', type);
      }
    }
  }

  onChange() {
    this.changeCallbacks.forEach((callback) => {
      callback({
        items: this.items,
        currentItemId: this.currentItemId,
      });
    });
  }

  onShow(item) {
    this.showCallbacks.forEach((callback) => {
      callback(item);
    });
  }

  onHide() {
    this.hideCallbacks.forEach((callback) => {
      callback();
    });
  }

  add(item) {
    this.items.push(item);

    if (!this.timeOut) {
      this.show();
    }

    this.onChange();
  }

  remove() {
    return this.items.shift();
  }

  removeById(id) {
    this.items = this.items.filter((item) => item.id !== id);
    this.onChange();
  }

  itemIsExists(id) {
    return this.currentItemId === id || !!this.items.find((item) => item.id === id);
  }

  isEmpty() {
    return this.items.length === 0;
  }

  getQuteItems() {
    return this.items.map((item, index) => {
      return {
        order: index + 1,
        id: item.id,
      };
    });
  }

  getItemDuration(item) {
    return item.duration ? item.duration * 1000 : null;
  }

  show() {
    const item = this.remove();

    if (item) {
      const duration = this.getItemDuration(item);
      if (!duration) {
        this.hide(item);
      }
      setTimeout(() => {
        this.currentItemId = item.id;
        this.onChange();
        this.onShow(item);
      }, 500);

      if (duration) {
        this.timeOut = setTimeout(() => {
          this.hide(item);

          if (!this.isEmpty()) {
            setTimeout(() => {
              this.show();
            }, 1000);
          } else {
            clearTimeout(this.timeOut);
            this.timeOut = null;
          }
        }, duration);
      }
    }
  }

  hide(item) {
    this.currentItemId = null;
    this.onHide(item);
    this.onChange();
  }
}

export default new Banner();
