import { formatDate } from '@/helpers/date';

export default {
  filters: {
    formatDate,
    getUniques(value) {
      if (Array.isArray(value)) {
        return [...new Set(value)];
      } else {
        return value;
      }
    },
  },
};
