import { consoleLog } from "../utils/logger";

class RemoteUser {
  constructor(id, user, conferenceInstance) {
    this.tracks = [];
    this.jitsiUser = user;
    this.id = id;
    this.conferenceInstance = conferenceInstance;
    this.eventEmitter = conferenceInstance.eventEmitter;
  }

  get getUser() {
    return {
      id: this.id,
      displayName: this.jitsiUser.getDisplayName(),
      role: this.jitsiUser.getRole(),
    };
  }

  addTrack(track) {
    this.tracks.push(track);

    track.addEventListener(this.conferenceInstance.libJitsi.events.track.TRACK_AUDIO_LEVEL_CHANGED, this.onAudioLevelChanged.bind(this));
    track.addEventListener(this.conferenceInstance.libJitsi.events.track.TRACK_MUTE_CHANGED, this.onMuteChanged.bind(this));
    track.addEventListener(this.conferenceInstance.libJitsi.events.track.LOCAL_TRACK_STOPPED, () => this.onTrackStopped.bind(this));
    track.addEventListener(this.conferenceInstance.libJitsi.events.track.TRACK_AUDIO_OUTPUT_CHANGED, this.onAudioOutputChanged.bind(this));
  }

  removeTrack(track) {
    const trackIndex = this.tracks.findIndex((t) => t.getId() === track.getId());
    if (trackIndex > -1) {
      this.tracks.splice(trackIndex, 1);
    }

    track.removeEventListener(this.conferenceInstance.libJitsi.events.track.TRACK_AUDIO_LEVEL_CHANGED, this.onAudioLevelChanged.bind(this));
    track.removeEventListener(this.conferenceInstance.libJitsi.events.track.TRACK_MUTE_CHANGED, this.onMuteChanged.bind(this));
    track.removeEventListener(this.conferenceInstance.libJitsi.events.track.LOCAL_TRACK_STOPPED, () => this.onTrackStopped.bind(this));
    track.removeEventListener(this.conferenceInstance.libJitsi.events.track.TRACK_AUDIO_OUTPUT_CHANGED, this.onAudioOutputChanged.bind(this));
  }

  // eslint-disable-next-line
  onAudioLevelChanged(audioLevel) {
    // consoleLog(`Audio Level remote: ${audioLevel}`);
  }

  onMuteChanged(track) {
    if (track.getType() === "video") {
      this.eventEmitter.emit("UserVideoMuteChanged", { userId: this.getUser.id, muteState: track.isMuted() });
    } else {
      this.eventEmitter.emit("UserAudioMuteChanged", { userId: this.getUser.id, muteState: track.isMuted() });
    }
  }

  onTrackStopped() {
    consoleLog("remote track stopped");
  }

  onAudioOutputChanged(deviceId) {
    consoleLog(`track audio output device was changed to ${deviceId}`);
  }
}

export default RemoteUser;
