/* global videojs, SLDP */

import axios from "axios";
import { consoleError, consoleLog } from "../utils/logger"; //eslint-disable-line

const SOCKET_CONNECTION_STATUS = {
  IDLE: 0,
  CONNECTING: 1,
  CONNECTED: 2,
  CLOSED: 3,
};

const WEBINAR_STATUS = {
  OFF: 0,
  ON: 1,
  PENDING: 2,
};

const WS_ERROR = {
  KEY_DOESNT_MATCH: "Key does not match!",
  MAX_VIEWER_COUNT_REACHED: "Maximum viewer count has been reached!",
};

export default class Webinar {
  constructor(conference) {
    this.WS_URL = window?.XPER_CONFIG?.webinar?.signalingSocketUrl || "wss://webinar-chat.xpermeet.com";
    this.API_URL = window?.XPER_CONFIG?.webinar?.signalingApiUrl || "https://webinar-presence.xpermeet.com";
    this.ARBITER_URL = window?.XPER_CONFIG?.webinar?.arbitterUrl || "https://webinar-presence.xpermeet.com:8443";
    this.ws = null;
    this.webRtcPeer = null;
    this.conference = conference;
    this.eventEmitter = conference.eventEmitter;
    this.keepAliveInterval = null;
    this.socketConnectionStatus = SOCKET_CONNECTION_STATUS.IDLE;
    this.webinarStatus = WEBINAR_STATUS.OFF;
    this.streamUrl = null;
    this.protocol = null;
    this.player = null;
  }

  handleEvents() {
    this.conference.room.on(this.conference.libJitsi.events.conference.RECORDER_STATE_CHANGED, this.onRecorderStateChanged.bind(this));
  }

  removeEvents() {
    this.conference.room.off(this.conference.libJitsi.events.conference.RECORDER_STATE_CHANGED, this.onRecorderStateChanged.bind(this));
  }

  onRecorderStateChanged(response) {
    const mode = response.getMode();
    const status = response.getStatus();

    const isStream = mode === "stream";
    const isOn = status === "on";
    const isOff = status === "off";
    const isPending = status === "pending";

    // Check webinar event
    if (isStream) {
      if (isOn) {
        this.eventEmitter.emit("WebinarStarted", response);
        this.webinarStatus = WEBINAR_STATUS.ON;
      } else if (isOff) {
        this.eventEmitter.emit("WebinarStopped", response);
        this.webinarStatus = WEBINAR_STATUS.OFF;
        this.removeEvents();
      } else if (isPending) {
        this.webinarStatus = WEBINAR_STATUS.PENDING;
      }
    }
  }

  changeRoleAndReconnect() {
    this.closeWS();
    this.connectWS();
    this.changeRole();
  }

  connectWS() {
    if (this.ws || this.socketConnectionStatus === SOCKET_CONNECTION_STATUS.CONNECTING) {
      consoleLog("Already have a WS connection!");
      return Promise.reject();
    }

    // eslint-disable-next-line
    return new Promise((resolve, reject) => {
      this.socketConnectionStatus = SOCKET_CONNECTION_STATUS.CONNECTING;

      setTimeout(() => {
        this.ws = new WebSocket(`${this.WS_URL}/browser`);
        this.ws.onmessage = this.wsOnMessage.bind(this);

        this.ws.onclose = () => {
          this.ws = null;
          if (this.socketConnectionStatus !== SOCKET_CONNECTION_STATUS.CLOSED) {
            this.connectWS();
          }
        };

        this.ws.onerror = () => {
          this.changeRoleAndReconnect();
          consoleLog("WS onerror");
        };

        this.ws.onopen = () => {
          this.socketConnectionStatus = SOCKET_CONNECTION_STATUS.CONNECTED;
          this.startPing();
          resolve();
        };

        window.onbeforeunload = () => {
          this.closeWS("onbeforeunload");
        };
      }, 1000);
    });
  }

  closeWS(caller) {
    consoleLog("WS closing", caller);
    this.stopPing();
    this.socketConnectionStatus = SOCKET_CONNECTION_STATUS.CLOSED;
    if (this.ws) {
      this.ws.close();
      this.ws = null;
    }
  }

  // Start Recording for moderator
  startWebinar(streamId, saveWebinarStream = false, meetingId, goLiveToken, disableLobby) {
    const baseUrl = window.location.hostname === "localhost" ? process.env.VUE_APP_DEV_ENV_URL : window.location.origin;
    const recordingOptions = {
      broadcastId: null,
      mode: "stream",
      streamId,
      appData: JSON.stringify({
        file_recording_metadata: {
          goLiveToken,
          hostName: saveWebinarStream ? localStorage.getItem("keycloak-token") : undefined,
          recordingEnabled: saveWebinarStream,
          meetingId,
          baseUrl,
        },
      }),
    };

    consoleLog(recordingOptions);
    return new Promise((resolve, reject) => {
      this.conference.room
        .startRecording(recordingOptions)
        .then((res) => {
          // Enable lobby
          if (!disableLobby && this.conference.lobby.isSupported() && !this.conference.room.room.getLobby().lobbyRoomJid) {
            this.conference.lobby.enable();
          }
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  // Stop Recording for moderator
  stopWebinar(sessionId) {
    localStorage.removeItem("moderatorSecret");
    return this.conference.room.stopRecording(sessionId);
  }

  // Start connection for viewer
  async start(videoId, roomId, options, displayName, token) {
    await this.connectWS();
    consoleLog("starting", videoId, roomId);
    this.wsSendMessage({
      type: "USER_JOINED",
      payload: {
        token: token,
        user: {
          alias: displayName,
        },
        room: roomId,
      },
    });

    console.log("[start/WebRtcPeerRecvonly/generateOffer] Done!");
  }

  async joinWithModeratorRole(displayName, room, moderatorSecret) {
    consoleLog("joinWithModeratorRole");
    if (!moderatorSecret) {
      consoleError("moderatorSecret is required! You are loggin as participant");
      return this.joinWithParticipantRole(displayName, room);
    }
    this.connectWS()
      .then(() => {
        this.wsSendMessage({
          type: "USER_JOINED",
          payload: {
            user: {
              alias: displayName,
              role: "MODERATOR",
            },
            room: room,
            moderatorSecret: moderatorSecret,
          },
        });
      })
      .catch(() => {});
  }

  async joinWithParticipantRole(displayName, room) {
    this.connectWS()
      .then(() => {
        this.wsSendMessage({
          type: "USER_JOINED",
          payload: {
            user: {
              alias: displayName,
              role: "PARTICIPANT",
            },
            room: room,
          },
        });
      })
      .catch(() => {});
  }

  streamCheck(roomName, protocol = "hls", token) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.get(`${this.API_URL}/streamCheck/${roomName}`);
        if (response?.data?.result) {
          const urlResponse = await axios.get(`${this.ARBITER_URL}/viewer/url/${roomName}/${protocol}`);
          if (urlResponse?.data?.status === "OK") {
            this.streamUrl = urlResponse?.data?.result;
            this.protocol = protocol;
            if (token) {
              this.streamUrl += `?tkn=${token}`;
            }
            return resolve();
          }
          return reject();
        } else {
          return reject();
        }
      } catch (e) {
        return reject();
      }
    });
  }

  checkExistingStream() {
    let sessions = [];
    for (const session in this.conference.room.recordingManager._sessions) {
      const sessionObject = this.conference.room.recordingManager._sessions[session];
      if (sessionObject && sessionObject.getMode() === "stream" && (sessionObject.getStatus() === "on" || sessionObject.getStatus() === "pending")) {
        sessions.push(session);
      }
    }
    return sessions.length ? sessions : false;
  }

  explainUserMediaError(err) {
    const n = err.name;
    if (n === "NotFoundError" || n === "DevicesNotFoundError") {
      return "Missing webcam for required tracks";
    } else if (n === "NotReadableError" || n === "TrackStartError") {
      return "Webcam is already in use";
    } else if (n === "OverconstrainedError" || n === "ConstraintNotSatisfiedError") {
      return "Webcam doesn't provide required tracks";
    } else if (n === "NotAllowedError" || n === "PermissionDeniedError") {
      return "Webcam permission has been denied by the user";
    } else if (n === "TypeError") {
      return "No media tracks have been requested";
    } else {
      return "Unknown error";
    }
  }

  onIceCandidate(candidate) {
    this.wsSendMessage({
      type: "ADD_ICE_CANDIDATE",
      payload: {
        candidate,
      },
    });
  }

  getUserList() {
    this.wsSendMessage({
      type: "USER_LIST",
    });
  }

  wsSendMessage(message) {
    const jsonMessage = JSON.stringify(message);
    this.ws.send(jsonMessage);
  }

  wsOnMessage(message) {
    const jsonMessage = JSON.parse(message.data);

    switch (jsonMessage.type) {
      case "PROCESS_SDP_ANSWER":
        this.handleProcessSdpAnswer(jsonMessage);
        break;
      case "ADD_ICE_CANDIDATE":
        this.handleAddIceCandidate(jsonMessage);
        break;
      case "END_PLAYBACK":
        // handleEndPlayback(jsonMessage);
        break;
      case "ERROR":
        this.handleError(jsonMessage);
        break;
      case "USER_ACCEPTED":
        const alias = jsonMessage?.payload?.user?.alias;
        const role = jsonMessage?.payload?.user?.role;
        this.conference.localUser.setDisplayName(alias);
        // Joined local user
        this.eventEmitter.emit("UserAccepted", jsonMessage);
        if (role === "VIEWER") {
          this.initPlayer();
        }
        this.getUserList();
        break;
      case "USER_JOINED":
        // Joined notification
        this.eventEmitter.emit("UserJoined", jsonMessage);
        break;
      case "USER_LEFT":
        // Left notification
        this.eventEmitter.emit("UserLeft", jsonMessage);
        break;
      case "USER_LIST":
        // Left notification
        this.eventEmitter.emit("UserList", jsonMessage);
        break;
      case "CHANGE_ROLE":
        // Left notification
        this.eventEmitter.emit("UserRoleChanged", jsonMessage);
        break;
      case "CHANGE_ALIAS":
        // alias/display name notification
        this.eventEmitter.emit("ChangeAlias", jsonMessage);
        break;
      case "HANDS":
        // hands up/down notification
        consoleLog(jsonMessage);
        this.eventEmitter.emit("Hands", jsonMessage);
        break;
      case "CHAT_MESSAGE":
        // chat notification
        consoleLog("chat message: " + message.data);
        this.eventEmitter.emit("onMessageReceived", jsonMessage);
        break;
      case "COMMAND":
        // command notification
        consoleLog("command: " + message.data);
        this.eventEmitter.emit("Command", jsonMessage);
        break;
      case "INFORM_JID":
        // command notification
        const jid = jsonMessage.payload.jid;
        const id = jid.split("/").pop();
        const viewerId = jsonMessage.payload.user.id;
        const whiteListObject = this.conference.lobby.getUserFromWhiteList(viewerId);
        if (whiteListObject) {
          whiteListObject.id = id;
          whiteListObject.jid = jid;
        }
        consoleLog("INFORM_JID: " + message.data);
        break;

      case "STREAM_ENDED":
        if (this.protocol === "hls") {
          if (this.player) {
            this.player.dispose();
          }
        }
        setTimeout(() => {
          this.eventEmitter.emit("WebinarEnded", jsonMessage);
        }, 8000);
        break;

      default:
        consoleLog("[onmessage] Received message: " + message.data);
        break;
    }
  }

  handleError(jsonMessage) {
    consoleError(jsonMessage);

    switch (jsonMessage.payload.message) {
      case WS_ERROR.KEY_DOESNT_MATCH:
        localStorage.removeItem("moderatorSecret");
        this.changeRoleAndReconnect();
        break;
      case WS_ERROR.MAX_VIEWER_COUNT_REACHED:
        this.eventEmitter.emit("MaxViewerReached", jsonMessage);
        break;
      default:
        break;
    }
  }

  handleProcessSdpAnswer(jsonMessage) {
    console.log("[handleProcessSdpAnswer] SDP Answer received from Kurento Client; process in Kurento Peer");

    this.webRtcPeer.processAnswer(jsonMessage.payload.sdpAnswer, (err) => {
      if (err) {
        console.error("[handleProcessSdpAnswer] " + err);
        return;
      }

      console.log("[handleProcessSdpAnswer] SDP Answer ready; start remote video");
      //this.startVideo(this.options.remoteVideo);
      consoleLog(this.options);
      this.startVideo(document.getElementById("webinar-video"));
    });
  }

  initPlayer(protocol) {
    if (protocol) {
      this.protocol = protocol;
    } else {
      protocol = this.protocol;
    }
    if (protocol === "hls") {
      const options = {
        controlBar: {
          playToggle: false,
          captionsButton: false,
          chaptersButton: false,
          subtitlesButton: false,
          remainingTimeDisplay: false,
          progressControl: {
            seekBar: true,
          },
          fullscreenToggle: false,
          playbackRateMenuButton: false,
        },
        children: ["controlBar"],
        autoplay: true,
      };
      // videojs player
      if (!document.getElementById("webinar-video")) {
        this.eventEmitter.emit("VideoStarted");
      }
      this.player = videojs("webinar-video", options);
      this.player.ready(() => {
        this.player.addClass("hls-player");
        this.player.src({
          type: "application/x-mpegURL",
          src: this.streamUrl,
        });
        this.startVideo(document.getElementById("webinar-video"), "hls");
      });
    } else {
      const videoWrpElement = document.querySelector("#webinar-element .sldp_player_wrp");
      if (videoWrpElement) {
        videoWrpElement.innerHTML = "";
      }
      SLDP.init({
        container: "webinar-element",
        stream_url: this.streamUrl,
        sync_buffer: 2000,
        buffering: 500,
        autoplay: true,
        initial_resolution: "720p",
        height: "parent",
        width: "parent",
        controls: false,
        splash_screen: "/img/xper_webinar_popart_tr.jpg",
        reconnects: 0,
      });

      let webinarElement = document.querySelector("#webinar-element");
      let videoElement = webinarElement.querySelector("video");
      videoElement.addEventListener(
        "play",
        () => {
          this.eventEmitter.emit("VideoStarted");
        },
        true,
      );
    }
  }

  startVideo(video, protocol = "hls") {
    this.eventEmitter.emit("VideoStarting");
    if (protocol === "hls") {
      this.player = videojs("webinar-video");
      if (!this.player.src()) {
        this.player.src({
          src: this.streamUrl,
        });
      }
      this.player
        .play()
        .then(() => {
          this.eventEmitter.emit("VideoStarted");
        })
        .catch((err) => {
          this.eventEmitter.emit("VideoError", err);
        });
    }
  }
  handleAddIceCandidate(jsonMessage) {
    this.webRtcPeer.addIceCandidate(jsonMessage.payload.candidate, (err) => {
      if (err) {
        console.error("[handleAddIceCandidate] " + err);
        return;
      }
    });
  }

  changeAlias(alias) {
    this.wsSendMessage({
      type: "CHANGE_ALIAS",
      payload: {
        alias: alias,
      },
    });
  }

  handsUp(state) {
    this.wsSendMessage({
      type: "HANDS",
      payload: {
        direction: state ? "up" : "down",
      },
    });
  }

  changeRole(moderatorSecret) {
    localStorage.setItem("moderatorSecret", moderatorSecret);
    const message = {
      type: "CHANGE_ROLE",
      payload: {
        role: moderatorSecret ? "MODERATOR" : "PARTICIPANT",
        moderatorSecret,
      },
    };
    if (this.webinarStatus === WEBINAR_STATUS.ON) {
      this.wsSendMessage(message);
    }
  }

  sendChatMessage(payload) {
    if (!payload) {
      return;
    }

    const { message } = payload;
    if (!message || message.trim() === "") {
      return;
    }

    this.wsSendMessage({
      type: "CHAT_MESSAGE",
      payload: {
        message: message,
      },
    });
  }

  sendShareSurveyCommand() {
    this.wsSendMessage({
      type: "COMMAND",
      payload: {
        message: "WEBINAR_SURVEY_SHARED",
      },
    });
  }

  // For moderator
  getInViewer(viewerId) {
    consoleLog("getInViewer", viewerId);
    this.conference.lobby.addUserToWhiteList({ viewerId: viewerId, id: null, jid: null });
    const message = {
      type: "COMMAND",
      payload: {
        to: viewerId,
        message: "GET_IN_START",
      },
    };
    this.wsSendMessage(message);
  }

  // For Moderator
  /**
   * Redirect user to webinar view page from conference view
   * @param userId
   */
  kickUserToWebinar(userId) {
    if (this.conference.room.getRole() === "moderator") {
      this.conference.room.sendCommandOnce("REDIRECT_TO_WEBINAR", {
        value: JSON.stringify({
          userId,
        }),
      });
    }
    this.conference.kickParticipant(userId, "REDIRECT_TO_WEBINAR");
  }

  // For Viewer
  getInStarted() {
    this.conference.localUser.setLobbyAutoJoinRequest(true);
  }

  // For viewer
  informJid() {
    const jid = this.conference?.room?.room?.lobby?.lobbyRoom?.myroomjid;
    if (!jid) {
      return;
    }
    consoleLog("informJid", jid);
    this.wsSendMessage({
      type: "INFORM_JID",
      payload: {
        jid: jid,
      },
    });
  }

  startPing() {
    if (!this.ws || this.keepAliveInterval) {
      this.stopPing();
    }
    this.keepAliveInterval = setInterval(() => {
      this.wsSendMessage({ type: "KEEP_ALIVE" });
    }, 30000);
  }

  stopPing() {
    clearInterval(this.keepAliveInterval);
  }

  leave() {
    this.closeWS("leave");
  }
}
