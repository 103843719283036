import { Ability, AbilityBuilder } from '@casl/ability';

export const ROLE_ADMIN = 'Admin';
export const ROLE_PARTICIPANT = 'Participant';
export const ability = new Ability([]);

export function changeRoles(roles) {
  ability.update(defineRulesFor(roles));
}

export function revokeRule(action, subject) {
  const { cannot, rules } = new AbilityBuilder(Ability);

  cannot(action, subject);
  ability.update([...ability.rules, ...rules]);
}

export function grantRule(action, subject) {
  const { can, rules } = new AbilityBuilder(Ability);

  can(action, subject);
  ability.update([...ability.rules, ...rules]);
}

export function routerGuard(_can) {
  if (!_can) {
    return false;
  }
  const rule = _can.split(' ') && _can.split(' ').length && _can.split(' ')[0];
  const module = _can.split(' ') && _can.split(' ').length && _can.split(' ')[1];
  return ability.can(rule, module);
}

export function defineRulesFor(roles = []) {
  const { can, rules } = new AbilityBuilder(Ability);

  if (roles.includes(ROLE_ADMIN)) {
    can('addRemoveStream', 'Studio');
    can('muteUser', 'Studio');
    can('changeLayout', 'Studio');
    can('showBanner', 'Studio');
    can('showComment', 'Studio');
    can('record', 'Studio');
    can('kickFromStudio', 'Studio');
    can('useAvatar', 'Studio');
  }

  if (roles.includes(ROLE_PARTICIPANT)) {
    // not implemented
    can('useAvatar', 'Studio');
  }

  return rules;
}

