import { consoleLog, consoleError } from 'xpermeet-lib';

import { getLocalSelectedDevices } from '@/helpers/devices';
import { DEVICES } from '@/constants/devices';
import { SET_DEVICE, SELECT_DEVICE, CLEAR_DEVICES, SELECT_CAMERA, SELECT_MICROPHONE, SELECT_SPEAKER } from '@/constants/mutation-types';
import { getInstance } from '@/xpermeet';

const xperMeetLib = getInstance();

export function fetchDevices({ commit }) {
  return new Promise((resolve) => {
    commit(CLEAR_DEVICES);
    xperMeetLib.mediaDevices.enumerateDevices((devices) => {
      devices.forEach((d) => commit(SET_DEVICE, d));

      const selectedDevices = getLocalSelectedDevices();

      commit(SELECT_CAMERA, selectedDevices.videoInput);
      commit(SELECT_MICROPHONE, selectedDevices.audioInput);
      commit(SELECT_SPEAKER, selectedDevices.audioOutput);
      resolve();
    });
  });
}

export function changeMicrophoneById({ dispatch, getters }, payload) {
  const device = getters.getMicrophones.find((d) => d.deviceId === payload);
  dispatch('changeDevice', device);
}

export function changeCameraById({ dispatch, getters }, payload) {
  if (getters.getSelectedCameraId !== payload) {
    const device = getters.getCameras.find((d) => d.deviceId === payload);
    dispatch('changeDevice', device);
  }
}

export function changeSpeakerById({ dispatch, getters }, payload) {
  const device = getters.getSpeakers.find((d) => d.deviceId === payload);
  dispatch('changeDevice', device);
}

export function changeDevice({ commit }, payload) {
  if (!payload) {
    return;
  }
  const deviceType = DEVICES.types[payload.kind];

  if (deviceType === 'speaker') {
    xperMeetLib.conference.localUser.setAudioOutputDevice(payload.deviceId);
    commit(SELECT_DEVICE, payload);
    xperMeetLib.mediaDevices.setDefaultDevices(getLocalSelectedDevices());
    return;
  }

  xperMeetLib.conference.localUser
    .switchLocalTrack(deviceType, payload.deviceId)
    .then(() => {
      consoleLog('Devices is changed', payload);
      commit(SELECT_DEVICE, payload);
      xperMeetLib.mediaDevices.setDefaultDevices(getLocalSelectedDevices());
    })
    .catch((err) => {
      consoleError(err);
    });
}

export function handleEvents({ commit }) {
  xperMeetLib.on('DeviceListChanged', (devices) => {
    commit(CLEAR_DEVICES);
    devices.forEach((d) => commit(SET_DEVICE, d));
  });
}

