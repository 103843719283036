<template>
  <div class="app-textarea">
    <div class="app-textarea--label mb-2" v-if="label">{{ label }}</div>
    <v-textarea outlined dense v-bind="$attrs" v-on="$listeners" :data-test-id="dataTestId" :value="value" @input="$emit('input', $event)"></v-textarea>
  </div>
</template>

<script>
export default {
  name: 'AppTextarea',
  props: {
    label: {
      type: String,
      default: null,
    },
    value: {
      type: String,
    },
    dataTestId: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.app-textarea {
  &--label {
    font-size: 20px;
  }
}
</style>
