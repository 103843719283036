import Vue from 'vue';
import jquery from 'jquery';
import VueClipboard from 'vue-clipboard2';
import VTooltip from 'v-tooltip';

import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import vuetify from '@/plugins/vuetify';
import Casl from './plugins/casl';
import i18n from '@/plugins/i18n';
import modalPlugin from '@/plugins/modal';
import Filters from '@/mixins/filters';
import Responsivity from '@/mixins/responsivity';

import AppLogo from '@/components/Common/AppLogo.vue';
import AppAvatar from '@/components/Common/AppAvatar.vue';
import AppInput from '@/components/Common/AppInput.vue';
import AppTextField from '@/components/Common/AppTextField.vue';
import AppCopyLink from '@/components/Common/AppCopyLink.vue';
import AppHotkeySelectInput from '@/components/Common/AppHotkeySelectInput.vue';
import AppTextarea from '@/components/Common/AppTextarea.vue';
import AppSelect from '@/components/Common/AppSelect.vue';
import AppIcon from '@/components/Common/AppIcon.vue';
import VideoCard from '@/components/VideoCard.vue';
import AppButton from '@/components/Common/AppButton.vue';
import AppIconButton from '@/components/Common/AppIconButton.vue';
import AppMenuButton from '@/components/Common/AppMenuButton.vue';
import AppPagination from '@/components/Common/AppPagination.vue';
import AppNoData from '@/components/Common/AppNoData.vue';
import AppModal from '@/components/Common/Modal/AppModal';
import ProviderIcon from '@/components/Common/ProviderIcon';

import '@/assets/scss/main.scss';

Vue.config.productionTip = process.NODE_ENV !== 'production';
Vue.config.devtools = process.NODE_ENV !== 'production';
window.$ = jquery;

function createApp() {
  VueClipboard.config.autoSetContainer = true; // add this line
  Vue.use(VueClipboard);
  Vue.use(VTooltip, { defaultClass: 'xper-tooltip tooltip', defaultHtml: false });

  // Global Components
  Vue.component('AppLogo', AppLogo);
  Vue.component('AppAvatar', AppAvatar);
  Vue.component('AppTextField', AppTextField);
  Vue.component('AppInput', AppInput);
  Vue.component('AppCopyLink', AppCopyLink);
  Vue.component('AppHotkeySelectInput', AppHotkeySelectInput);
  Vue.component('AppTextarea', AppTextarea);
  Vue.component('AppSelect', AppSelect);
  Vue.component('AppIcon', AppIcon);
  Vue.component('VideoCard', VideoCard);
  Vue.component('AppButton', AppButton);
  Vue.component('AppIconButton', AppIconButton);
  Vue.component('AppMenuButton', AppMenuButton);
  Vue.component('AppPagination', AppPagination);
  Vue.component('AppNoData', AppNoData);
  Vue.component('AppModal', AppModal);
  Vue.component('ProviderIcon', ProviderIcon);

  Vue.mixin(Filters);
  Vue.mixin(Responsivity);

  Casl.init();

  const app = new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: (h) => h(App),
  }).$mount('#app');
  Vue.use(modalPlugin, { appInstance: app });
  console.log('AppCreated');
}

createApp();

