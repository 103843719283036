<template>
  <img :src="getProviderIconUrl" />
</template>

<script>
export default {
  name: 'ProviderIcon',
  props: {
    provider: {
      type: String,
      required: true,
    },
  },
  computed: {
    getProviderIconUrl() {
      let ex = 'svg';

      if (this.provider === 'Youtube') {
        ex = 'png';
      }

      return `/images/${this.provider}.${ex}`;
    },
  },
};
</script>

<style></style>

