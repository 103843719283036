import { ENUMS } from 'xpermeet-lib';

import store from '@/store';
import { UPDATE_USER } from '@/constants/mutation-types';
import { STUDIO } from '@/constants/modules';

/**
 * Event Name
 */
export const PARTICIPANT_CONNECTION_STATUS_CHANGED = 'ParticipantConnStatusChanged';

/*
 * status if user disconnected status is going to be interrupted
 * set this as a flag in the user object for showing the current
 * connection status of the user
 */
export default function onParticipantConnectionStatusChanged({ userId, status }) {
  if (status === ENUMS.USER_CONNECTION_STATUS.INTERRUPTED) {
    store.commitWithModule(STUDIO, UPDATE_USER, { userId, data: { interrupted: true } });
  } else if (status === ENUMS.USER_CONNECTION_STATUS.ACTIVE) {
    store.commitWithModule(STUDIO, UPDATE_USER, { userId, data: { interrupted: false } });
  }
}

