import { v4 as uuidv4 } from 'uuid';

import { NOTIFICATION_SOUNDS, ROOM_NOTIFICATION_TYPES, NOTIFICATION_TYPES } from '@/constants/notification';
import { NOTIFICATION_SETTINGS } from '@/constants/settings';
import { getInstance } from '@/xpermeet';

const xperMeetLib = getInstance();
const { SOUNDS_ON_OFF } = NOTIFICATION_SETTINGS;

export function onNotificationReceived({ dispatch }, payload) {
  const { type, data } = payload;
  switch (type) {
    case NOTIFICATION_TYPES.ROOM:
      dispatch('showRoomNotification', data);
      break;
    case NOTIFICATION_TYPES.TOAST:
      break;
    default:
      break;
  }
}

export function showRoomNotification({ commit }, payload) {
  const { text, type, date, userId, file } = payload;

  const RoomNotificationObject = {
    id: uuidv4(),
    text: text,
    date: date || Date.now(),
    userId: userId,
    type: type || ROOM_NOTIFICATION_TYPES.TEXT, // ROOM_NOTIFICATION_TYPES,
    file: file || '',
    read: false,
  };

  commit('ADD_ROOM_NOTIFICATION', RoomNotificationObject);
}

export function sendRoomNotification({}, payload) {
  const { text, type, date, userId, file } = payload;
  const RoomNotificationObject = {
    id: uuidv4(),
    text: text,
    date: date || Date.now(),
    userId: userId,
    type: type || ROOM_NOTIFICATION_TYPES.TEXT, // ROOM_NOTIFICATION_TYPES,
    file: file || '',
    read: false,
  };
  xperMeetLib.conference.sendCustomNotification(NOTIFICATION_TYPES.ROOM, type, userId, RoomNotificationObject);
}

export function removeRoomNotificationByType({ commit, getters }, payload) {
  const { type, userId } = payload;
  const notification = getters.getNotificationByType(type).find((notification) => notification.userId === userId);
  if (notification) {
    commit('REMOVE_ROOM_NOTIFICATION', notification.id);
  }
}

export function showToastNotification({ commit }, payload) {
  let body;
  let title;
  let config;
  let translate = true;

  if (typeof payload === 'string') {
    body = payload;
  } else {
    body = payload?.body;
    title = payload?.title;
    translate = 'translate' in payload ? payload.translate : true;
    config = payload?.config;
  }

  const ToastNotificationObject = {
    id: uuidv4(),
    body: body,
    title: title,
    translate: translate,
    config: config || null,
  };
  commit('ADD_TOAST_NOTIFICATION', ToastNotificationObject);

  return ToastNotificationObject;
}

export function playSound({ state, rootState }, payload) {
  if (state.notificationSoundSettings[SOUNDS_ON_OFF] && state.notificationSoundSettings[payload] && !rootState.Studio.speakerMuted) {
    let sound = `./../sounds/${NOTIFICATION_SOUNDS[payload]}`;
    const audio = new Audio(sound);
    audio.volume = 0.5;
    audio.play();
  }
}

export function readRoomNotifications({ commit }) {
  commit('SET_ROOM_NOTIFICATION_HIGHLIGHT', false);
  commit('READ_ROOM_NOTIFICATIONS');
}

