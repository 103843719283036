import store from '@/store';
import { ADD_USER_TRACK } from '@/constants/mutation-types';
import { STUDIO } from '@/constants/modules';

/**
 * Event name
 */
export const REMOTE_TRACK_ADDED = 'RemoteTrackAdded';

/**
 * onRemoteTrackAdded event handler
 * @param {*} participantId
 * @param {*} track
 */
export default function onRemoteTrackAdded({ participantId, track }) {
  const container = document.getElementById(`remote-${track.getType()}-${participantId}`);
  store.commitWithModule(STUDIO, ADD_USER_TRACK, { userId: participantId, track });
  store.dispatchWithModule(STUDIO, 'attachTrackToElement', { container, userType: 'remote', userId: participantId, track });
  store.dispatchWithModule(STUDIO, 'setSpeakerMute', store.state[STUDIO].speakerMuted);
}

