import Vue from 'vue';
import { abilitiesPlugin } from '@casl/vue';
import { ability } from '@/lib/ability';

export function init() {
  Vue.use(abilitiesPlugin, ability, {
    useGlobalProperties: true,
  });
}

export default {
  init,
};
