import { consoleError, consoleWarning } from 'xpermeet-lib';

import i18n from '@/plugins/i18n';
import xssService from '@/services/xss.service';
import { isAuthenticated } from '@/helpers/keycloak';
import { SET_DESTINATIONS, SET_DESTINATIONS_LOADING, DELETE_DESTINATION } from '@/constants/mutation-types';
import { TOAST_TYPE } from '@/constants/toast';

export async function fetchDestinations({ commit, dispatch }) {
  try {
    if (!isAuthenticated()) {
      consoleWarning('Login required to fetch destinations');
      return;
    }

    commit(SET_DESTINATIONS_LOADING, true);

    const res = await xssService.fetchDestinations();

    const destinations = res.data.map((destination) => {
      return {
        id: destination.id,
        user: destination.displayName,
        avatar: destination.avatarUrl,
        channel: destination.providerEntity.name,
        tokenIsValid: destination.refresh_token_valid,
        type: 'destination',
        raw: destination,
      };
    });

    commit(SET_DESTINATIONS, destinations);
    return destinations;
  } catch (err) {
    consoleError('Fetch Destination Error: ', err);
    dispatch(
      'Notification/showToastNotification',
      { body: i18n.t('error.couldNotFetch', { name: i18n.t('broadcasts.destinations') }), translate: false, config: { type: TOAST_TYPE.ERROR } },
      { root: true },
    );
  } finally {
    commit(SET_DESTINATIONS_LOADING, false);
  }
}

export async function deleteDestination({ commit, dispatch }, destinationId) {
  try {
    await xssService.deleteUserDestination(destinationId);
    commit(DELETE_DESTINATION, destinationId);
    dispatch('Notification/showToastNotification', { body: i18n.t('destinations.deleteSuccess'), config: { type: TOAST_TYPE.SUCCESS } }, { root: true });
  } catch (error) {
    consoleError('Delete Destination Error: ', error);
    dispatch(
      'Notification/showToastNotification',
      {
        body: i18n.t('error.couldNotDeleteDestination'),
        config: { type: TOAST_TYPE.ERROR },
      },
      { root: true },
    );
  }
}
