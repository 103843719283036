import { xperMeetBuilder } from 'xpermeet-lib';

let instance;

export function getInstance() {
  if (!instance) {
    instance = xperMeetBuilder.createXssInstance();
  }

  return instance;
}

