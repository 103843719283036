import { XperMeetLib } from "./main";
import RoomNameService from "./services/RoomNameService";
import Survey from "./modules/Survey";
import DialInManager from "./modules/DialInManager";
import ARModule from "./modules/ARModule";
import { XPERMEET_INSTANCE_TYPES } from "./constants/enums";

export default class XperMeetBuilder {
  createBizbizeInstance() {
    const xperMeet = new XperMeetLib();

    xperMeet.addRoomNameService(new RoomNameService());
    xperMeet.addSurvey(new Survey());
    xperMeet.addDialIn(new DialInManager());
    xperMeet.addArModule(new ARModule(xperMeet));
    xperMeet.setInstanceType(XPERMEET_INSTANCE_TYPES.BIZBIZE);

    return xperMeet;
  }

  createXssInstance() {
    const xperMeet = new XperMeetLib();

    xperMeet.setInstanceType(XPERMEET_INSTANCE_TYPES.XSS);

    return xperMeet;
  }

  createScreenShareInstance() {
    const xperMeet = new XperMeetLib();

    xperMeet.setInstanceType(XPERMEET_INSTANCE_TYPES.SCREEN);

    return xperMeet;
  }
}
