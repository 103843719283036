import store from '@/store';
import { UPDATE_USER, SET_VIDEO_MUTE_STATE } from '@/constants/mutation-types';
import { STUDIO } from '@/constants/modules';

export const USER_VIDEO_MUTE_CHANGED_BY_MODERATOR = 'UserVideoMuteChangedByModerator';

export default function onUserVideoMuteChangedByModerator({ userId, muteState, moderatorDisplayName }) {
  const videoMutedByModerator = store.state[STUDIO].roomConfig.allowAdminStartVideo && muteState;

  store.commitWithModule(STUDIO, SET_VIDEO_MUTE_STATE, { userId, muteState });
  store.commitWithModule(STUDIO, UPDATE_USER, {
    userId,
    data: {
      videoMutedByModerator,
      videoMutedBy: moderatorDisplayName,
    },
  });
  // const message = i18n.t(muteState ? 'videoMutedByModerator' : 'videoUnMutedByModerator', { moderatorDisplayName });
  // store.dispatch('Notification/showToastNotification', { body: message, translate: false }, { root: true });
}
