import { OS } from '@/constants/enums';

export const getOsName = () => {
  const lua = (navigator.userAgent + '').toLowerCase();
  if (/android/.test(lua)) return OS.ANDROID;
  if (/ios|iphone|ipod|ipad/.test(lua)) return OS.IOS;
  if (/macintosh/.test(lua)) return OS.MAC;
  if (/windows/.test(lua)) return OS.WINDOWS;
  return OS.UNKNOWN;
};

export const isMobile = () => {
  let osName = getOsName();
  return osName === OS.ANDROID || osName === OS.IOS;
};

export const isMacos = () => getOsName() === OS.MAC || getOsName() === OS.IOS;

export const isIOS = () => getOsName() === OS.IOS;
