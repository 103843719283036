import store from '@/store';
import { SET_DOMINANT_USER_ID } from '@/constants/mutation-types';
import { STUDIO } from '@/constants/modules';

export const DOMINANT_USER_CHANGED = 'onDominantUserChanged';

export default function onDominantUserChanged([userId]) {
  if (store.getters[`${STUDIO}/getLocalUser`]?.id !== userId) {
    store.commitWithModule(STUDIO, SET_DOMINANT_USER_ID, { userId });
  }
}
