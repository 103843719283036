import router from '@/router';
import store from '@/store';
import { NOTIFICATION } from '@/constants/modules';
import { STUDIO } from '@/constants/modules';

export const KICKED = 'Kicked';

export default function onKicked({ reason }) {
  if (reason === 'CLOSE_ROOM') {
    router.push('/ended').then(() => {
      router.go();
    });
  } else if (reason === 'REDIRECT_TO_LOBBY') {
    store.dispatchWithModule(NOTIFICATION, 'showToastNotification', 'movedToLobby');
    window.location.href = `/${store.state[STUDIO].roomName}?redirected=true`;
  } else if (reason !== 'REDIRECT_TO_WEBINAR') {
    store.dispatchWithModule(NOTIFICATION, 'showToastNotification', 'kicked');
    router.push('/kicked').then(() => {
      router.go();
    });
  }
}
