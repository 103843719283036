export default class Stats {
  constructor(conference) {
    this.conference = conference;
    this.eventEmitter = conference.eventEmitter;
  }

  handleEvents() {
    this.conference.room.on(this.conference.libJitsi.events.connectionQuality.LOCAL_STATS_UPDATED, this.onLocalStatsUpdated.bind(this));
    this.conference.room.on(this.conference.libJitsi.events.connectionQuality.REMOTE_STATS_UPDATED, this.onRemoteStatsUpdated.bind(this));
  }

  onLocalStatsUpdated(stats) {
    this.eventEmitter.emit("LocalStatsUpdated", stats);
  }

  onRemoteStatsUpdated(id, stats) {
    this.eventEmitter.emit("RemoteStatsUpdated", { id, stats });
  }
}
