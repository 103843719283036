import store from '@/store';
import i18n from '@/plugins/i18n';
import { REMOVE_USER } from '@/constants/mutation-types';
import { STUDIO } from '@/constants/modules';
import { NOTIFICATION } from '@/constants/modules';

export const USER_KICKED = 'UserKicked';

export default function onRemoteUserKicked({ actorParticipantId, kickedParticipantId, reason }) {
  if (reason !== 'REDIRECT_TO_WEBINAR') {
    const kickedParticipant = store.getters[`${STUDIO}/getUserById`](kickedParticipantId);
    const actorParticipant = store.getters[`${STUDIO}/getUserById`](actorParticipantId);
    const message = i18n.t('userKicked', { who: kickedParticipant?.displayName, by: actorParticipant?.displayName });

    store.dispatchWithModule(NOTIFICATION, 'showToastNotification', { body: message, translate: false });
    store.commitWithModule(STUDIO, REMOVE_USER, { userId: kickedParticipantId });
  }
}

