import KeycloakManager from "./modules/KeycloakManager";
import TicketManager from "./modules/TicketManager";
import Conference from "./modules/Conference";
import ConnectionManager from "./modules/ConnectionManager";
import MediaDevices from "./modules/MediaDevices";
import DialInManager from "./modules/DialInManager";
import ARModule from "./modules/ARModule";
import Survey from "./modules/Survey";
import RoomNameService from "./services/RoomNameService";

import { consoleError, consoleLog, consoleDebug, consoleWarning, consoleRemote, logger } from "./utils/logger";
import { ErrorWithCodes } from "./errors/errors";

import Enums from "./constants/enums";
import DefaultRoomConfig from "./constants/defaultRoomConfig";
import { parseJwt } from "./helpers/jwt";
import { checkIsJibri } from "./helpers/jibri";
import EventEmitter from "./utils/EventEmitter";
import Jitsi from "./lib-jitsi-meet.min";
import XperMeetBuilder from "./XperMeetBuilder";

export class XperMeetLib {
  constructor() {
    this._instanceType = null;

    this.lib = Jitsi();
    this.eventEmitter = new EventEmitter();
    this.mediaDevices = new MediaDevices(this);
    this.connectionManager = new ConnectionManager(this);
    this.conference = new Conference(this);
    this.config = null;
    this.keycloakManager = null;
    this.roomNameService = null;
    this.survey = null;
    this.dialIn = null;
    this.ARModule = null;
  }

  setInstanceType(instanceType) {
    this._instanceType = instanceType;
  }

  addRoomNameService(roomNameService) {
    this.roomNameService = roomNameService;
  }

  addSurvey(survey) {
    this.survey = survey;
  }

  addDialIn(dialIn) {
    this.dialIn = dialIn;
  }

  addARModule(arModule) {
    this.ARModule = arModule;
  }

  resetModules() {
    this.lib = Jitsi();
    this.mediaDevices = new MediaDevices(this);
    this.connectionManager = new ConnectionManager(this);
    this.conference = new Conference(this);

    if (this.RoomNameService) {
      this.roomNameService = new RoomNameService();
    }

    if (this.survey) {
      this.survey = new Survey();
    }

    if (this.dialIn) {
      this.dialIn = new DialInManager();
    }

    if (this.ARModule) {
      this.ARModule = new ARModule(this);
    }
  }

  updateAPPVar() {
    // Set Global variables for Jibri
    if (window.APP) {
      consoleError("window.APP reserved variable, please check this!");
    }
    // Mock variable for Jibri, see CallPage.kt in Jibri Library
    window.APP = {
      isXss: this.getIsXss(),
      conference: {
        _room: this.conference.room,
      },
    };
  }

  initKeycloakManager() {
    return new Promise((resolve, reject) => {
      this.keycloakManager = new KeycloakManager({}, this);
      this.keycloakManager
        .createKeycloakInstance()
        .then((kc) => {
          this.keycloakManager.checkSSO(kc).then(resolve).catch(reject);
          window.kc = kc;
        })
        .catch((err) => {
          consoleLog("Kc Error", err);
          reject(err);
        });
    });
  }

  checkAuth({ config, roomName, ticket, token, arkResponse, arkToken, withoutAuth }) {
    // TODO bu değişken config parametresi olmalı
    const autoRedirectLoginPage = false;

    return new Promise((resolve, reject) => {
      if (this.configIsInvalid(config)) {
        consoleError(ErrorWithCodes.INVALID_CONFIG);
        return;
      }

      // Set config
      this.config = config;

      const activeTicket = ticket || localStorage.getItem("ticket");
      if (activeTicket) {
        return TicketManager.verify({ roomName, token: activeTicket })
          .then(() => {
            consoleLog("Auth successful");
            localStorage.setItem("ticket", activeTicket);
            localStorage.setItem("authenticator-token", activeTicket);
            resolve();
          })
          .catch(() => {
            localStorage.removeItem("ticket");
            localStorage.removeItem("authenticator-token");

            reject(ErrorWithCodes.ROOM_IS_NOT_ACTIVE);
          });
      }

      const activeEventToken = token || localStorage.getItem("event-token");
      if (activeEventToken) {
        // TODO icislerindeki LMS bağlantısında login butonuna tıklandığında keycloak undefined döndüğü için yapıldı.
        // #MEET-394 ile bu kodun güncellenmesi gerekecek.
        this.initKeycloakManager();
        localStorage.setItem("event-token", activeEventToken);
        return resolve();
      }

      // If JWT token exist, passed keycloak manager.
      const authenticatorToken = localStorage.getItem("authenticator-token");
      if (authenticatorToken && !window.customerConfig?.arkTokenRequired) {
        const parsedToken = parseJwt(authenticatorToken);
        const isNotModerator = "moderator" in parsedToken && parsedToken.moderator === false;

        if (isNotModerator) {
          localStorage.removeItem("authenticator-token");
        }

        return resolve();
      }

      if (!config?.isKeycloakActive) {
        return resolve();
      }

      this.keycloakManager.arkResponse = arkResponse;
      this.keycloakManager.arkToken = arkToken;

      this.keycloakManager
        .handleAuthentication(roomName)
        .then(() => {
          consoleLog("Auth successful");
          resolve();
        })
        .catch((e) => {
          if (autoRedirectLoginPage || arkResponse?.isModerator) {
            this.keycloakManager.redirectLoginPage();
          }

          if (withoutAuth && !checkIsJibri()) {
            return resolve("GuestLogin");
          }

          if (e === "WrongUserLogin") {
            resolve(e);
          } else if (e === "GuestLogin") {
            resolve("GuestLogin");
          } else if (e === "GetJwtFailed") {
            resolve("GetJwtFailed");
          } else {
            resolve("GuestLogin");
          }
        });
    });
  }

  getIsScreen() {
    return Enums.XPERMEET_INSTANCE_TYPES.SCREEN === this._instanceType;
  }

  getIsXss() {
    return Enums.XPERMEET_INSTANCE_TYPES.XSS === this._instanceType;
  }

  init(options) {
    if (this.getIsScreen()) {
      return this.initWithTracks(options);
    }

    return this.initWithConnection(options);
  }

  async initWithTracks({ config, roomName }) {
    return new Promise((resolve, reject) => {
      this.config = config;
      this.connectionManager.createConnection(config, roomName);

      this.on("ConnectionSuccess", () => {
        try {
          this.conference.createRoom(config, roomName, null, true);

          this.conference.roomConfig.init(this.conference.room.room.roomjid, this.conference.room.room.connection);

          resolve();
        } catch (e) {
          reject(e);
          consoleError("ConnectionSuccess Event Error:", e);
        }
      });

      this.on("ConferenceFailed", (errorCode) => {
        reject(errorCode);
        consoleLog("ConferenceFailed", errorCode);
      });
    });
  }

  // deviceDeviceIds = {
  //    audioInput: ''
  //    audioOutput: ''
  //    videoInput: ''
  // }
  initWithConnection({
    config,
    roomName,
    password,
    deviceDeviceIds,
    startMutedPolicyConfig,
    ticket,
    token,
    startWithAudioMuted,
    startWithVideoMuted,
    withoutAuth,
    arkResponse,
    arkToken,
  }) {
    this.mediaDevices.setDefaultDevices(deviceDeviceIds);

    return new Promise((resolve, reject) => {
      this.checkAuth({ config, roomName, ticket, token, arkResponse, arkToken, withoutAuth })
        .then((response) => {
          try {
            this.connectionManager.createConnection(config, roomName);

            resolve(response);

            this.on("ConnectionSuccess", () => {
              try {
                this.conference.localUser.setPrejoinConfig({ startWithAudioMuted, startWithVideoMuted });

                this.conference.createRoom(config, roomName, password, true);
                this.updateAPPVar();
                this.conference.roomConfig.init(this.conference.room.room.roomjid, this.conference.room.room.connection, startMutedPolicyConfig);

                // required only for rn mobile app, will be removed in the future
                this.conference.setStartMutedPolicy(startMutedPolicyConfig);
              } catch (e) {
                consoleError("ConnectionSuccess Event Error:", e);
              }
            });

            this.on("ConferenceFailed", (errorCode) => {
              consoleLog("ConferenceFailed", errorCode);
              if (errorCode === ErrorWithCodes.AUTHENTICATION_REQUIRED) {
                setTimeout(() => {
                  this.emit("RemoteUserLeft", this.conference.localUser.getUser.id);
                  this.connectionManager.disconnect();
                  this.connectionManager.connection.disconnect();
                  this.connectionManager.createConnection(config, roomName);
                }, 5000);
              }
            });
          } catch (e) {
            consoleError(e);
            reject(e);
          }
        })
        .catch(reject);
    });
  }

  configIsInvalid(config) {
    return !config;
  }

  leaveCall(config) {
    this.clearEvents();

    localStorage.removeItem("moderatorSecret");

    this.conference.localUser.tracks.forEach((track) => {
      track.dispose();
    });

    this.conference.room
      ?.leave()
      .then(() => {
        // Connection disconnect
        this.connectionManager.disconnect();
        this.connectionManager.connection
          .disconnect()
          .then(() => {
            this.resetModules();
            // Redirect page
            if (config?.callback) {
              config.callback();
            } else {
              const redirectUri = config?.redirectUri || window.location.origin;
              location.href = redirectUri;
            }
          })
          .catch((e) => {
            consoleLog("lib main.js leave call -> connectionManager.disconnect error...", e);
          });
      })
      .catch((e) => {
        consoleLog("lib main.js leave call -> room.leave error...", e);
      });
  }

  logout(config) {
    this.connectionManager.disconnect();

    this.connectionManager.connection.disconnect().then(() => {
      if (window.localStorage.getItem("ticket")) {
        window.localStorage.removeItem("ticket");
        location.href = "/";
      } else if (config?.forceLogout || this.conference?.room?.isLoggedIn()) {
        this.keycloakManager.logout(config);
      }
    });
  }

  destroyRoomAndLeave(config) {
    this.conference.kickAllParticipants();
    this.leaveCall(config);
  }

  emit(eventType, callbackFn) {
    this.eventEmitter.emit(eventType, callbackFn);
  }

  on(eventType, callbackFn) {
    this.eventEmitter.on(eventType, callbackFn);
  }

  off(eventType, callbackFn) {
    this.eventEmitter.off(eventType, callbackFn);
  }

  clearEvents() {
    this.eventEmitter.clear();
  }
}

export const xperMeetBuilder = new XperMeetBuilder();
export const ERROR_WITH_CODES = ErrorWithCodes;
export const ENUMS = Enums;
export const DEFAULT_ROOM_CONFIG = DefaultRoomConfig;
export { consoleLog, consoleError, consoleWarning, consoleDebug, consoleRemote, logger };

/**
 * TODO: merge to develop notes
 *
 * Conference dependent modules are  moving into Conference class. This modules shown below.
 * - LocalRecordinmanager
 * - RemoteRecordingManager
 * - Webinar
 * - RoomConfig
 * - Lobby
 * - Passwers
 * - Stats
 *
 * Added new parameter to xper_config
 * keycloakTokenGeneratorUrl, keycloakTokenGeneratorPath
 */
