import { logger } from 'xpermeet-lib';

import { getInstance } from '@/xpermeet';
import onConnectionFailed, { CONNECTION_FAILED } from './studio/connection-failed';
import onRoomConfigChanged, { ROOM_CONFIG_CHANGED } from './studio/room-config-changed';
import onParticipantConnectionStatusChanged, { PARTICIPANT_CONNECTION_STATUS_CHANGED } from './studio/participant-connection-status-changed';
import onLocalUserJoined, { LOCAL_USER_JOINED } from './studio/local-user-joined';
import onLocalTrackAdded, { LOCAL_TRACK_ADDED } from './studio/local-track-added';
import onLocalTrackRemoved, { LOCAL_TRACK_REMOVED } from './studio/local-track-removed';
import onRemoteTrackAdded, { REMOTE_TRACK_ADDED } from './studio/remote-track-added';
import onRemoteTrackRemoved, { REMOTE_TRACK_REMOVED } from './studio/remote-track-removed';
import onUserPropertyChange, { USER_PROPERTY_CHANGE } from './studio/user-property-change';
import onRemoteRecordStarted, { REMOTE_RECORD_STARTED } from './studio/remote-record-started';
import onRemoteRecordStopped, { REMOTE_RECORD_STOPPED } from './studio/remote-record-stopped';
import onRemoteUserJoined, { REMOTE_USER_JONIED } from './studio/remote-user-joined';
import onRemoteUserLeft, { REMOTE_USER_LEFT } from './studio/remote-user-left';
import onUserDisplayNameChanged, { USER_DISPLAY_NAME_CHANGED } from './studio/user-display-name-changed';
import onUserRoleChanged, { USER_ROLE_CHANGED } from './studio/user-role-changed';
import onUserAudioMuteChanged, { USER_AUDIO_MUTE_CHANGED } from './studio/user-audio-mute-changed';
import onUserVideoMuteChanged, { USER_VIDEO_MUTE_CHANGED } from './studio/user-video-mute-changed';
import onUserAudioMutedByModeratorChanged, { USER_AUDIO_MUTED_BY_MODERATOR_CHANGED } from './studio/user-audio-muted-by-moderator-changed';
import onUserVideoMutedByModeratorChanged, { USER_VIDEO_MUTED_BY_MODERATOR_CHANGED } from './studio/user-video-muted-by-moderator-changed';
import onUserAudioMuteChangedByModerator, { USER_AUDIO_MUTE_CHANGED_BY_MODERATOR } from './studio/user-audio-mute-changed-by-moderator';
import onUserVideoMuteChangedByModerator, { USER_VIDEO_MUTE_CHANGED_BY_MODERATOR } from './studio/user-video-mute-changed-by-moderator';
import onMessageReceived, { ON_MESSAGE_RECEIVED, ON_PRIVATE_MESSAGE_RECEIVED } from './studio/message-recieved';
import onDominantUserChanged, { DOMINANT_USER_CHANGED } from './studio/dominant-user-changed';
import onConferenceFailed, { CONFERENCE_FAILED } from './studio/conference-failed';
import onConferenceTimestampChanged, { CONFERENCE_TIMESTAMP_CHANGED } from './studio/conference-timestamp-changed';
import onConferenceJoined, { CONFERENCE_JOINED } from './studio/conference-joined';
import onScreenShare, { SCREEN_SHARE_STARTED, SCREEN_SHARE_STOPPED } from './studio/screen-share-started';
import onLocalTrackError, { LOCAL_TRACK_ERROR } from './studio/local-track-error';
import onRemoteUserKicked, { USER_KICKED } from './studio/remote-user-kicked';
import onKicked from './studio/kicked';
import onMeetingIdSet, { MEETING_ID_SET } from './studio/meeting-id-set';
import onSessionUpdated, { SESSION_UPDATED } from './studio/session-updated';
import onViewerCountUpdated, { VIEWER_COUNT_UPDATED } from './studio/viewer-count-updated';
import { KICKED } from 'xpermeet-lib/src/constants/XMPPEvents';
import onWebinarEnded, { WEBINAR_ENDED } from './webinar/webinar-ended';
import onWebinarStopped, { WEBINAR_STOPPED } from './webinar/webinar-stopped';
import onWebinarStarted, { WEBINAR_STARTED } from './webinar/webinar-started';
import onBannerAdded, { BANNER_ADDED } from './broadcast/banner-added';
import onBannerRemoved, { BANNER_REMOVED } from './broadcast/banner-removed';
import onUserKicked, { ON_KICKED } from './studio/user-kicked';

const eventLogger = logger('log', 'EVENT # ');

function logMiddleware(fun) {
  return (...args) => {
    let params;
    try {
      params = JSON.stringify(args);
    } catch (e) {
      params = args;
    }

    eventLogger(`${fun.name} # `, params);
    fun(...args);
  };
}

export function setupEventListeners() {
  const xperMeetLib = getInstance();

  xperMeetLib.on(CONNECTION_FAILED, logMiddleware(onConnectionFailed));
  xperMeetLib.on(PARTICIPANT_CONNECTION_STATUS_CHANGED, logMiddleware(onParticipantConnectionStatusChanged));
  xperMeetLib.on(LOCAL_USER_JOINED, logMiddleware(onLocalUserJoined));
  xperMeetLib.on(LOCAL_TRACK_REMOVED, logMiddleware(onLocalTrackRemoved));
  xperMeetLib.on(REMOTE_TRACK_ADDED, logMiddleware(onRemoteTrackAdded));
  xperMeetLib.on(REMOTE_TRACK_REMOVED, logMiddleware(onRemoteTrackRemoved));
  xperMeetLib.on(USER_PROPERTY_CHANGE, logMiddleware(onUserPropertyChange));
  xperMeetLib.on(REMOTE_USER_JONIED, logMiddleware(onRemoteUserJoined));
  xperMeetLib.on(REMOTE_USER_LEFT, logMiddleware(onRemoteUserLeft));
  xperMeetLib.on(USER_DISPLAY_NAME_CHANGED, logMiddleware(onUserDisplayNameChanged));
  xperMeetLib.on(USER_ROLE_CHANGED, logMiddleware(onUserRoleChanged));
  xperMeetLib.on(USER_AUDIO_MUTE_CHANGED, logMiddleware(onUserAudioMuteChanged));
  xperMeetLib.on(USER_VIDEO_MUTE_CHANGED, logMiddleware(onUserVideoMuteChanged));
  xperMeetLib.on(USER_AUDIO_MUTED_BY_MODERATOR_CHANGED, logMiddleware(onUserAudioMutedByModeratorChanged));
  xperMeetLib.on(USER_VIDEO_MUTED_BY_MODERATOR_CHANGED, logMiddleware(onUserVideoMutedByModeratorChanged));
  xperMeetLib.on(USER_AUDIO_MUTE_CHANGED_BY_MODERATOR, logMiddleware(onUserAudioMuteChangedByModerator));
  xperMeetLib.on(USER_VIDEO_MUTE_CHANGED_BY_MODERATOR, logMiddleware(onUserVideoMuteChangedByModerator));
  xperMeetLib.on(ON_MESSAGE_RECEIVED, onMessageReceived({ isPrivate: false }));
  xperMeetLib.on(ON_PRIVATE_MESSAGE_RECEIVED, onMessageReceived({ isPrivate: true }));
  xperMeetLib.on(CONFERENCE_FAILED, logMiddleware(onConferenceFailed));
  xperMeetLib.on(CONFERENCE_TIMESTAMP_CHANGED, logMiddleware(onConferenceTimestampChanged));
  xperMeetLib.on(CONFERENCE_JOINED, logMiddleware(onConferenceJoined));
  xperMeetLib.on(SCREEN_SHARE_STARTED, onScreenShare({ screenSharing: true }));
  xperMeetLib.on(SCREEN_SHARE_STOPPED, onScreenShare({ screenSharing: false }));
  xperMeetLib.on(DOMINANT_USER_CHANGED, logMiddleware(onDominantUserChanged));
  xperMeetLib.on(USER_KICKED, logMiddleware(onRemoteUserKicked));
  xperMeetLib.on(ON_KICKED, logMiddleware(onUserKicked));
  xperMeetLib.on(MEETING_ID_SET, logMiddleware(onMeetingIdSet));
  xperMeetLib.on(KICKED, logMiddleware(onKicked));
  xperMeetLib.on(BANNER_ADDED, logMiddleware(onBannerAdded));
  xperMeetLib.on(SESSION_UPDATED, logMiddleware(onSessionUpdated));
  xperMeetLib.on(BANNER_REMOVED, logMiddleware(onBannerRemoved));
  xperMeetLib.on(VIEWER_COUNT_UPDATED, logMiddleware(onViewerCountUpdated));

  xperMeetLib.on(LOCAL_TRACK_ADDED, logMiddleware(onLocalTrackAdded));
  xperMeetLib.on(LOCAL_TRACK_ERROR, logMiddleware(onLocalTrackError));

  xperMeetLib.on(ROOM_CONFIG_CHANGED, logMiddleware(onRoomConfigChanged));

  xperMeetLib.on(REMOTE_RECORD_STOPPED, logMiddleware(onRemoteRecordStopped));
  xperMeetLib.on(REMOTE_RECORD_STARTED, logMiddleware(onRemoteRecordStarted));

  xperMeetLib.on(WEBINAR_ENDED, logMiddleware(onWebinarEnded));
  xperMeetLib.on(WEBINAR_STOPPED, logMiddleware(onWebinarStopped));
  xperMeetLib.on(WEBINAR_STARTED, logMiddleware(onWebinarStarted));
}

